import { ContextGestionale } from '../App';
import { useContext } from 'react';

function DettagliPrenotazione(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
    return (
        <>
            {Object.keys(props.prenotazioneText).length > 0 && <>
                <p><b>Dove: </b>{props.prenotazioneText.dove}</p>
                <p><b>Periodo: </b>{props.prenotazioneText.periodo}</p>
                <p><b>Motivo: </b>{props.prenotazioneText.motivo}</p>
            </>
            }
            {Object.keys(props.prenotazioneText).length === 0 && <p><b>Nessuna prenotazione selezionata</b></p>}
        </>
    );
}

export default DettagliPrenotazione;
import { useEffect, useState } from "react";
import { useContext } from "react";
import { ContextGestionale } from "../App";
import SearchAndSelect from "./SearchAndSelect";
import Loader from "./Loader";

function PaeseEstero(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    const [loadingCountries, setLoadingCountries] = useState(false);
    const [selectedPaese, setSelectedPaese] = useState([]);
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        console.log("Mi sono caricato. Il paese è " + props.paese);
        setLoading(true);
        setLoadingCountries(true);
        nodeReq.get(process.env.REACT_APP_API_URL + "/paesiFIC")
            .then(response => {
                if (response.status == 200) {
                    let countriesTemp = response.data;
                    let found = false;
                    for (let i = 0; i < countriesTemp.length; i++) {
                        let country = countriesTemp[i];
                        countriesTemp[i] = {};
                        countriesTemp[i].id = i;
                        countriesTemp[i].viewed = true;
                        countriesTemp[i].country = country;
                        if (countriesTemp[i].country == props.paese) {
                            setSelectedPaese([countriesTemp[i].id]);
                            found = true;
                        }
                    }
                    if (!found) {
                        props.setPaese("");
                    }
                    setCountries(countriesTemp);
                } else {
                    genericAlert("Impossibile ottenere l'elenco dei paesi da FattureInCloud al momento. Riprova più tardi.");
                }
            })
            .catch(error => {
                console.log(error);
                handleAPIError(error, "ottenere l'elenco dei paesi da FattureInCloud");
            })
            .finally(() => {
                setLoading(false);
                setLoadingCountries(false);
            });
    }, []);

    //quando viene cambiato il paese da questo componente, bisogna riflettere la modifica fuori
    useEffect(() => {
        if (Array.isArray(selectedPaese) && selectedPaese.length == 1) {
            for (let i = 0; i < countries.length; i++) {
                if (countries[i].id == selectedPaese[0]) {
                    props.setPaese(countries[i].country);
                    break;
                }
            }
        }
    }, [selectedPaese]);

    return (
        <>
            {loadingCountries && <p><span><Loader small={true} /></span> Caricamento della lista dei paesi in corso...</p>}
            {!loadingCountries && <p>&nbsp;</p>}
            <SearchAndSelect title="Seleziona un paese" elements={countries} oneElementName={"un paese"} allowMultiple={false} showDeselectButton={false} validSelection={true} setValidSelection={(val) => { }} required={true} selectedOptions={selectedPaese} setSelectedOptions={setSelectedPaese} hideBottomText={false} hideSearchText={false} disabled={false} getElementText={(element) => { return element.country; }} />
        </>
    );
}

export default PaeseEstero;
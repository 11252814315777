import { useState } from "react";
import BackendDataTable from "../BackendDataTable";
import { useEffect } from "react";
import SelettoreProfessionistaPaz from "../SelettoreProfessionistaPaz";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import Heading from "../Heading";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

function Sedute(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    const [reqUrl, setReqUrl] = useState("");
    const columnsToExcludeSedutePrenotate = ["idSeduta", "idPaziente", "idServizio", "idProfessionista", "idCertificazione", "idPrenotazione", "idArea", "motivo", "nomePaziente", "nomeProfessionista", "cognomePaziente", "cognomeProfessionista"];

    const monthsDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    //professionista selezionato. Se 0, tutti i professionisti sono selezionati
    const [selectedProfessionista, setSelectedProfessionista] = useState(0);
    //dice se la lista dei professionisti è stata già caricata
    const [professionistiUpdated, setProfessionistiUpdated] = useState(true);
    //paziente selezionato. Se 0, tutti i pazienti sono selezionati
    const [selectedPaziente, setSelectedPaziente] = useState(0);
    //dice se la lista dei pazienti è stata già caricata
    const [pazientiUpdated, setPazientiUpdated] = useState(true);

    const [nomeCognomePazienteSelezionato, setNomeCognomePazienteSelezionato] = useState("tutti i pazienti");
    const [nomeCognomeProfessionistaSelezionato, setNomeCognomeProfessionistaSelezionato] = useState("tutti i professionisti");
    //data di inizio nella ricerca delle sedute: Oggi
    const today = new Date();

    const [dateOk, setDateOk] = useState(true);
    let currentYear = today.getUTCFullYear();
    let currentMonth = today.getUTCMonth() + 1;
    let currentDay = today.getUTCDate();
    const formattedStartDate = currentYear + "-" + currentMonth.toString().padStart(2, '0') + "-01";
    const [dataInizio, setDataInizio] = useState(formattedStartDate);
    //data di fine nella ricerca delle sedute: Domani
    currentDay++;
    if (currentDay > getMonthsDays(today.getMonth(), currentYear)) {
        currentMonth++;
        currentDay = 1;
        if (currentMonth == 13) {
            currentMonth = 1;
            currentYear++;
        }
    }
    const formattedEndDate = currentYear + "-" + currentMonth.toString().padStart(2, '0') + "-" + monthsDays[today.getUTCMonth()].toString().padStart(2, '0');
    const [dataFine, setDataFine] = useState(formattedEndDate);
    const columnsDataTypes = {
        "prezzo": "price",
        "costoProfessionista": "price",
        "data": "date"
    }

    const functionsOnColumns = [];
    functionsOnColumns["modalita"] = (val) => {
        if (val == 0) {
            return "In presenza";
        } else if (val == 1) {
            return "Ibrida";
        } else if (val == 2) {
            return "Online";
        }
    };
    function modalitaStringToNum(val) {
        if (val == "In presenza") {
            return 0;
        } else if (val == "Ibrida") {
            return 1;
        } else if (val == "Online") {
            return 2;
        }
    }
    functionsOnColumns["prezzo"] = (val) => {
        if (val != null && val != undefined) {
            return importoConPuntoEVirgola(val);
        } else {
            return "---";
        }
    };
    functionsOnColumns["costoProfessionista"] = (val) => {
        if (val != null && val != undefined) {
            return importoConPuntoEVirgola(val);
        } else {
            return "---";
        }
    };
    functionsOnColumns["effettuata"] = (val) => {
        if (val == null) {
            return "Non segnata";
        } else if (val == 1) {
            return "Sì";
        } else if (val == 0) {
            return "No";
        }
    };
    functionsOnColumns["certificazione"] = (val) => {
        if (val == null) {
            return "Nessuna";
        } else {
            return val;
        }
    }
    functionsOnColumns["data"] = (val) => {
        return val.substring(8, 10) + "/" + val.substring(5, 7) + "/" + val.substring(0, 4);
    };

    functionsOnColumns["nota"] = (val) => {
        if (val == "" || val == null || val == undefined) {
            return "---";
        } else {
            return val;
        }
    }

    function getMonthsDays(month, year) {
        if (year % 4 == 0 && month == 1) { //1 è per febbraio
            return 29;
        } else {
            return monthsDays[month];
        }
    }

    function dataOraformatoAmericano(data) {
        return data.substring(6, 10) + "-" + data.substring(3, 5) + "-" + data.substring(0, 2) + data.substring(10);
    }

    function sedutaClicked(seduta) {
        if (loggedUser.tipoUtente == "segretario" || (loggedUser.tipoUtente == "professionista" && loggedUser.user == seduta.idProfessionista)) {
            console.log("Riga 129 Sedute.js");
            let newSeduteFormValues = {
                apriNuovaModificaSeduta: true,
                openedFrom: "tabellaSedute",
                selectedProfessionisti: [seduta.idProfessionista + ""],
                selectedPazienti: [seduta.idPaziente + ""],
                selectedServizi: [seduta.idServizio + ""],
                modalita: modalitaStringToNum(seduta.modalita),
                data: dataOraformatoAmericano(seduta.data),
                oraInizio: seduta.oraInizio,
                oraFine: seduta.oraFine,
                selectedCertificazioni: seduta.idCertificazione != null ? [seduta.idCertificazione + ""] : [],
                selectedAree: seduta.idArea != null ? [seduta.idArea + ""] : [],
                idSedutaToUpdate: seduta.idSeduta,
                idPrenotazioneToUpdate: seduta.idPrenotazione
            }
            setSeduteFormValues(JSON.parse(JSON.stringify(newSeduteFormValues)));
            setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(newSeduteFormValues)));
        }
    }

    useEffect(() => {
        if (pageViewed === pages.Sedute) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: tabella delle sedute aperta");
            }
            setDataInizio(formattedStartDate);
            setDataFine(formattedEndDate);
            setDateOk(true);
            if (loggedUser.tipoUtente == "professionista") {
                setSelectedProfessionista(loggedUser.user);
            } else {
                setSelectedProfessionista(0);
            }
            setSelectedPaziente(0);
            setPazientiUpdated(false);
            setProfessionistiUpdated(false);

        }
    }, [pageViewed]);

    //quando vengono aggiornati i filtri, aggiorna anche la query
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: filtri tabella sedute aggiornati");
        }
        if (distanza(dataInizio, dataFine) <= 31) {
            setDateOk(true);
            //imposta la reqUrl per le sedute
            let url = process.env.REACT_APP_API_URL + "/sedute?statoFatturazione=true&unisciCognomeNome=true&dataInizio=" + dataInizio + "&dataFine=" + dataFine;
            if (selectedProfessionista != 0) {
                url += "&idProfessionista=" + selectedProfessionista;
            }
            if (selectedPaziente != 0) {
                url += "&idPaziente=" + selectedPaziente;
            }
            setReqUrl(url);

        } else {
            setDateOk(false);
        }

    }, [selectedProfessionista, selectedPaziente, dataInizio, dataFine]);

    function distanza(dataInizio, dataFine) {
        const dataInizioDate = new Date(dataInizio);
        const dataFineDate = new Date(dataFine);
        const differenzaMillisecondi = dataFineDate - dataInizioDate;
        return differenzaMillisecondi / (1000 * 60 * 60 * 24);
    }
    function dataFormatoAmericano(data) {
        return data.getFullYear() + "-" + (data.getMonth() + 1).toString().padStart(2, '0') + "-" + data.getDate().toString().padStart(2, '0');
    }

    //converte la data dal formato americano al formato italiano per i file: "2024-12-31" -> "31-12-2024"
    function dataFormattataPerFile(data) {
        return data.substring(8, 10) + "-" + data.substring(5, 7) + "-" + data.substring(0, 4);
    }

    function handleDatesChange(event) {
        if (Array.isArray(event) && event.length == 2) {
            setDataInizio(dataFormatoAmericano(event[0]).split('T')[0]);
            setDataFine(dataFormatoAmericano(event[1]).split('T')[0]);
            console.log(event);
        }
    }

    return (
        <div style={{ display: pageViewed === pages.Sedute ? 'block' : 'none' }}>
            <Heading title="Tabella sedute" iconName="clipboard" />
            <div className="row">
                {loggedUser.tipoUtente == "segretario" &&
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Filtra per professionista</h5>
                            </div>
                            <div className="card-body">
                                <SelettoreProfessionistaPaz paziente={false} show={true} allowAll={true} selected={selectedProfessionista} setSelected={setSelectedProfessionista} updated={professionistiUpdated} setUpdated={setProfessionistiUpdated} setNomeCognomeSelezionato={setNomeCognomeProfessionistaSelezionato} />
                            </div>
                        </div>
                    </div>
                }
                <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Filtra per paziente</h5>
                        </div>
                        <div className="card-body">
                            <SelettoreProfessionistaPaz paziente={true} show={true} allowAll={true} selected={selectedPaziente} setSelected={setSelectedPaziente} updated={pazientiUpdated} setUpdated={setPazientiUpdated} setNomeCognomeSelezionato={setNomeCognomePazienteSelezionato} />
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Filtra per data</h5>
                        </div>
                        <div className="card-body">
                            {!dateOk && <p className="text-danger">Selezionare due date distanti non più di 31 giorni</p>}
                            {pageViewed === pages.Sedute &&
                                <Flatpickr options={{ mode: "range", inline: true, locale: Italian, defaultDate: [formattedStartDate, formattedEndDate] }} onChange={handleDatesChange} />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mainElementHeightContainer">
                <div className="col-md mh-100">
                    <div className="backendDataTableContainer">
                        {dateOk && <p className="mb-0">Stai visualizzando le sedute {loggedUser.tipoUtente == "segretario" && <>di <b>{nomeCognomeProfessionistaSelezionato}</b></>} con <b>{nomeCognomePazienteSelezionato}</b> tra il <b>{dataFormatoItaliano(dataInizio)}</b> e il <b>{dataFormatoItaliano(dataFine)}</b></p>}
                        {!dateOk && <p className="mb-0 text-danger">Selezionare due date distanti non più di 31 giorni</p>}
                        <BackendDataTable fileName={"Sedute di " + nomeCognomeProfessionistaSelezionato + " con " + nomeCognomePazienteSelezionato + " prenotate tra " + dataFormattataPerFile(dataInizio) + " e " + dataFormattataPerFile(dataFine)} rowClickFunction={sedutaClicked} loadInsidePage={pages.Sedute} tableId={"sedutePrenotateTable"} reqUrl={reqUrl} show={true} columnsToExclude={columnsToExcludeSedutePrenotate} functionsOnColumns={functionsOnColumns} columnsDataTypes={columnsDataTypes} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sedute;
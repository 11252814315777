import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ContextGestionale } from "../App";
import Loader from "./Loader";
function APICallButton(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
    //è come un bottone normale, ma diventa disabled se c'è almeno una chiamata API in esecuzione
    {/*props richieste: buttonOnClick, buttonClassName, buttonDisabled, buttonText*/ }
    return (
        <>
            {!isProcessing && <button onClick={() => {props.buttonOnClick();setNomeProceduraInCorso(props.nomeProcedura);}} className={props.buttonClassName} disabled={props.buttonDisabled == true}><span style={{ marginLeft: "11px", marginRight: "11px" }}>{props.buttonText}</span></button>}
            {isProcessing &&
                <div class="tooltip">
                    <button className={props.buttonClassName} disabled={true} type={props.isSubmit == true ? "submit" : ""}><Loader insideButton={true} /><span style={{ marginLeft: "4px", marginRight: "0" }}>{props.buttonText}</span></button>
                    <span class="tooltiptext">È in corso {nomeProceduraInCorso}. Attendere.</span>
                </div>}
        </>
    );
}

export default APICallButton;
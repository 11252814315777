import { useState, useEffect } from 'react';
import { ContextGestionale } from '../App';
import { useContext } from 'react';
import FeatherIcon from './FeatherIcon';

function SearchAndSelect(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    //componente comodo per avere una selezione multipla con casella di ricerca
    /*props:
    title, 
    viewedVariable
    elements ({id, viewed}) (viewed può anche essere una variabile diversa, se viewedVariable è definita), 
    allowMultiple (boolean), 
    showDeselectButton (boolean), 
    validSelection, 
    setValidSelection (boolean), 
    required, 
    selectedOptions,
    setSelectedOptions (array),
    checkUnsavedBeforeSelecting
    hideBottomText
    hideSearchText
    disabled
    filterFunction (restituisce true se l'elemento è da mostrare, false altrimenti)
    */
    const [searchText, setSearchText] = useState("");
    const [searched, setSearched] = useState([]);
    const [errorText, setErrorText] = useState(null);
    const [selectBottomText, setSelectBottomText] = useState("");
    const [noViewedElements, setNoViewedElements] = useState(false);
    const maxSelectSize = 6;
    const [selectSize, setSelectSize] = useState(maxSelectSize);
    const optionHeight = 25;
    const selectHeight = (optionHeight * selectSize + (selectSize - 1) + 16);
    let updateSearchedTimeout;

    //quando viene impostata la lista di elementi, deseleziona tutto (se selfEmpty), cancella il testo di ricerca e resetta l'array searched
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: elementi cambiati SearchAndSelect");
        }
        setSearchText("");
        setSearched(new Array(props.elements.length).fill(true));
        if (props.selfEmptyOnElementsUpdate === true) {
            props.setSelectedOptions([]);
        }
        setNoViewedElements(checkIfNoElementIsViewed());
    }, [props.elements]);

    //funzioni di handle degli onChange
    const handleSearchTextChange = (event) => {
        if (props.disabled != true) {
            if (props.checkUnsavedBeforeSelecting === undefined) {
                handleSearchTextChangeFunction(event);
            } else {
                props.checkUnsavedBeforeSelecting(() => { handleSearchTextChangeFunction(event); });
            }
        }
    }
    function handleSearchTextChangeFunction(event) {
        props.setSelectedOptions([]);
        let value = event.target.value;
        setSearchText(value);
        // clearTimeout(updateSearchedTimeout);
        updateSearched(value);
    }

    function updateSearched(value) {
        let searchedTemp = new Array(searched.length).fill(false);
        for (let i = 0; i < props.elements.length; i++) {
            if (props.getElementText(props.elements[i]).toLowerCase().includes(value.toLowerCase())) {
                searchedTemp[i] = true;
            }
        }
        setSearched(searchedTemp);
    }
    //restituisce TRUE se tutti gli elementi hanno viewed = false, FALSE altrimenti
    function checkIfNoElementIsViewed() {
        for (let i = 0; i < props.elements.length; i++) {
            if (elementIsViewed(i)) {
                return false;
            }
        }
        return true;
    }
    function elementIsViewed(i) {
        return (props.viewedVariable === undefined && props.elements[i].viewed) || (props.viewedVariable !== undefined && props.elements[i][props.viewedVariable] === true);
    }
    //espandi o comprimi la select
    function expandCollapse() {
        if (selectSize === maxSelectSize) {
            setSelectSize(1);
        } else {
            setSelectSize(maxSelectSize);
        }
    }

    //controlla se la selezione è valida. imposta correttamente il messaggio di errore e la variabile validSelection
    useEffect(() => {
        if (props.selectedOptions !== undefined) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: controllo se la selezione è valida SearchAndSelect");
            }
            if (!props.allowMultiple && props.selectedOptions.length > 1) {
                props.setValidSelection(false);
                setErrorText("Selezionare al massimo " + props.oneElementName);
            } else if (props.required && props.selectedOptions.length === 0) {
                props.setValidSelection(false);
                if (noViewedElements) {
                    setErrorText(props.noViewedElementsText !== undefined ? props.noViewedElementsText : "");
                } else if (props.allowMultiple) {
                    setErrorText("Selezionare almeno " + props.oneElementName);
                } else {
                    setErrorText("Selezionare " + props.oneElementName);
                }
            } else {
                props.setValidSelection(true);
                setErrorText(null);
            }
        }
    }, [props.selectedOptions, props.required, props.allowMultiple, noViewedElements]);

    //deseleziona tutte le opzioni
    function deselectAll() {
        if (props.checkUnsavedBeforeSelecting === undefined) {
            deselectAllFunction();
        } else {
            props.checkUnsavedBeforeSelecting(deselectAllFunction);
        }
    }

    function selectAll() {
        if (props.checkUnsavedBeforeSelecting === undefined) {
            selectAllFunction();
        } else {
            props.checkUnsavedBeforeSelecting(selectAllFunction);
        }
    }

    function selectAllFunction() {
        let selectedOptionsTemp = props.selectedOptions.slice();
        for (let i = 0; i < props.elements.length; i++) {
            let element = props.elements[i];
            if (((props.viewedVariable === undefined && element.viewed) || (props.viewedVariable !== undefined && element[props.viewedVariable] === true)) && !(selectedOptionsTemp.includes(element.id.toString()))) {
                selectedOptionsTemp.push(element.id.toString());
            }
        }
        props.setSelectedOptions(selectedOptionsTemp);
    }

    function deselectAllFunction() {
        let selectedOptionsTemp = [];
        for (let i = 0; i < props.elements.length; i++) {
            let element = props.elements[i];
            //se l'elemento è visualizzato ed è selezionato, lo devi deselezionare
            if (((props.viewedVariable === undefined && element.viewed) || (props.viewedVariable !== undefined && element[props.viewedVariable] === true)) && props.selectedOptions.includes(element.id.toString())) {
            } else if (props.selectedOptions.includes(element.id.toString())) {
                selectedOptionsTemp.push(element.id.toString());
            }
        }
        props.setSelectedOptions(selectedOptionsTemp);
    }

    //
    useEffect(() => {
        if (props.selectedOptions !== undefined) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: ti dico cosa hai selezionato SearchAndSelect");
            }
            if (props.selectedOptions.length === 0) {
                setSelectBottomText("Nessun elemento selezionato");
            } else if (props.selectedOptions.length === 1) {
                //cerca l'elemento selezionato
                let selectedText = null;
                for (let i = 0; i < props.elements.length; i++) {
                    if (props.elements[i].id == props.selectedOptions[0]) {
                        selectedText = props.getElementText(props.elements[i]);
                        break;
                    }
                }
                if (selectedText !== null) {
                    setSelectBottomText("Selezionato: " + selectedText);
                } else {
                    setSelectBottomText("L'elemento selezionato non è tra gli elementi mostrati");
                }
            } else {
                setSelectBottomText(props.selectedOptions.length + " elementi selezionati");
            }
        }
    }, [props.selectedOptions, props.elements]);

    function toggleSelection(element) {
        let id = element.id;
        if (props.notSelectableFunction == undefined || props.notSelectableFunction(element) != true) {
            if (props.disabled != true) {
                if (props.checkUnsavedBeforeSelecting === undefined) {
                    toggleSelectionFunction(id);
                } else {
                    props.checkUnsavedBeforeSelecting(() => { toggleSelectionFunction(id); });
                }

            }
        }
    }

    function toggleSelectionFunction(id) {
        if (props.allowMultiple) {
            if (props.selectedOptions.includes(id.toString())) {
                //rimuovi dalla selezione
                let selectedOptionsTemp = props.selectedOptions.slice();
                selectedOptionsTemp = selectedOptionsTemp.filter(item => item !== id.toString());
                props.setSelectedOptions(selectedOptionsTemp);
            } else {
                //Seleziona
                let selectedOptionsTemp = props.selectedOptions.slice();
                selectedOptionsTemp.push(id.toString());
                props.setSelectedOptions(selectedOptionsTemp);
            }
        } else {
            props.setSelectedOptions([id.toString()]);
        }
        if (props.onManualChange != undefined) {
            props.onManualChange(id);
        }
    }
    return (
        <div>
            <h5 className="card-title">{props.title}</h5>
            <div className="d-flex flex-row">
                {props.hideSearchText !== true &&
                    <div className="flex-grow-1 pb-1 pt-1">
                        <input type="text" className="form-control" placeholder="Cerca" value={searchText} onChange={handleSearchTextChange} />
                    </div>
                }
                {props.showDeselectButton && props.allowMultiple == false &&
                    <div className="ps-1 pb-1 pt-1">
                        <button className="btn btn-secondary" onClick={deselectAll}>Deseleziona</button>
                    </div>
                }
                {props.allowMultiple == true &&
                    <div className="ps-1 pb-1 pt-1 d-flex flex-row">
                        <button className="btn btn-secondary me-1" onClick={selectAll}><FeatherIcon iconName="check-square" addedClassName="" /></button>
                        <button className="btn btn-secondary" onClick={deselectAll}><FeatherIcon iconName="square" addedClassName="" /></button>
                    </div>
                }
            </div>
            {/*
            <select size={selectSize} multiple className="form-control m-1" value={props.selectedOptions} onChange={handleSelectedOptionsChange}>
                {props.elements.map((element, index) => {
                    if (searched[index] && ((props.viewedVariable === undefined && element.viewed) || (props.viewedVariable !== undefined && element[props.viewedVariable] === true))) {
                        return (<option key={index} value={element.id}>{element.text}</option>);
                    } else {
                        return null;
                    }
                })}
            </select>
            */}
            <div className="selectOptionsContainer" style={{ height: selectHeight + "px" }}>
                <div>
                    {props.elements.map((element, index) => {
                        if (element.id !== undefined && (props.filterFunction == undefined || props.filterFunction(element)) && searched[index] && ((props.viewedVariable === undefined && element.viewed) || (props.viewedVariable !== undefined && element[props.viewedVariable] === true))) {
                            if (props.selectedOptions !== undefined && props.selectedOptions.includes(element.id.toString())) {
                                return (
                                    <div className="customSelectOption" key={index} onClick={() => { toggleSelection(element); }} style={{ height: optionHeight + "px", backgroundColor: (props.disabled != true && (props.notSelectableFunction == undefined || props.notSelectableFunction(element) != true) ? process.env.REACT_APP_SEARCH_AND_SELECT_SELECTION_COLOR : "#888888"), color: "white" }} datatoggle="tooltip" dataplacement="top" title={props.getElementText(element)}>
                                        <div className="flex-grow-1">{props.allowMultiple && <FeatherIcon iconName="check-square" addedClassName="" />} {props.getElementText(element)}</div>
                                        <div>{props.starElement != undefined && props.starElement == element.id && <FeatherIcon addedClassName="align-middle" iconName="star" />}</div>
                                        <div>{props.notSelectableFunction != undefined && props.notSelectableFunction(element) == true && <FeatherIcon addedClassName="align-middle" iconName="lock" />}</div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="customSelectOption" key={index} onClick={() => { toggleSelection(element); }} style={{ height: optionHeight + "px", backgroundColor: "rgba(0,0,0,0)", color: (props.notSelectableFunction != undefined && props.notSelectableFunction(element) == true ? "#999999" : "#444444") }} datatoggle="tooltip" dataplacement="top" title={props.getElementText(element)}>
                                        <div className="flex-grow-1">{props.allowMultiple && <FeatherIcon iconName="square" addedClassName="" />} {props.getElementText(element)}</div>
                                        <div>{props.starElement != undefined && props.starElement == element.id && <FeatherIcon addedClassName="align-middle" iconName="star" />}</div>
                                        <div>{props.notSelectableFunction != undefined && props.notSelectableFunction(element) == true && <FeatherIcon addedClassName="align-middle" iconName="lock" />}</div>
                                    </div>
                                );
                            }

                        } else {
                            return null;
                        }
                    })}
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                {!props.showDeselectButton && <div></div>}
                {/*<button className="btn btn-light m-1" onClick={expandCollapse}>{selectSize === maxSelectSize ? "Comprimi" : "Espandi"}</button>*/}
            </div>
            {errorText !== null && <p className="text-danger">{errorText}</p>}
            {!props.hideBottomText && errorText === null && <p className="text-primary">{selectBottomText}</p>}
        </div>
    );
}

export default SearchAndSelect;
import PrezzoServizioModalDialog from '../modals/PrezzoServizioModalDialog';
import { useState } from 'react';
import { useEffect } from 'react';
import ModalSedutaProgrammata from '../modals/ModalSedutaProgrammata';
import axios from 'axios';
import { useContext } from 'react';
import { ContextGestionale } from '../../App';
import FeatherIcon from '../FeatherIcon';
import SearchAndSelect from '../SearchAndSelect';
import AggiornaMiaPassword from '../AggiornaMiaPassword';
import EmailComunicazioni from '../EmailComunicazioni';
import AltriNumeriTelefono from '../AltriNumeriTelefono';
import APICallButton from "../APICallButton";
import Loader from '../Loader';
import Heading from '../Heading';
import ModalPrompt from '../modals/ModalPrompt';
import PaeseEstero from '../PaeseEstero';

function ProfessionistaPazCreateUpdate(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    const [isShowPrezzoServizioModal, setShowPrezzoServizioModal] = useState(false);
    const [isShowSedutaProgrammataModal, setShowSedutaProgrammataModal] = useState(false);
    const [isShowInvioEmailModal, setShowInvioEmailModal] = useState(false);
    const [isShowStatoAutomaticoModal, setShowStatoAutomaticoModal] = useState(false);
    const [isShowSbloccaPazienteModal, setShowSbloccaPazienteModal] = useState(false);
    const [isShowBloccaPazienteModal, setShowBloccaPazienteModal] = useState(false);
    //liste di tutti i pazienti, professionisti e servizi nel db. Ogni elemento della lista può o meno contenere la stringa di ricerca (viewed) e può essere o meno assegnato al paziente/professionista che si sta modificando (assigned)
    //questi due useState servono per rilevare cambiamenti nelle assegnazioni dei pazienti/professionisti
    //questo useState serve per rilevare cambiamenti nelle mie informazioni
    //contiene tutti gli indirizzi email per le comunicazioni inseriti per l'utente e i tipi di notifiche che vuole ricevere come array boolean
    const [emailComunicazioniResetted, setEmailComunicazioniResetted] = useState(true);
    const [ultimoIndirizzoEmailValido, setUltimoIndirizzoEmailValido] = useState("");

    const giorni = [null, "Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];

    const campiInformazioni = ["nome", "cognome", "email", "cf"];
    const campiResidenza = ["comune", "cap", "provincia", "indirizzo"];

    const [selectedPazienti, setSelectedPazienti] = useState([]);
    const [selectedProfessionisti, setSelectedProfessionisti] = useState([]);
    const [selectedServizi, setSelectedServizi] = useState([]);
    //stringhe attualmente presenti nelle caselle di ricerca
    //è true se questa è la pagina visualizzata attualmente, false altrimenti
    const professionistaPazCreateUpdateViewed = pageViewed === pages.NuovoPaziente || pageViewed === pages.NuovoProfessionista || pageViewed === pages.Paziente || pageViewed === pages.Professionista || pageViewed === pages.MieInfo;
    //è true se siamo in modalità nuovo utente, false se siamo in modalità dettagli/modifica utente
    const newmode = pageViewed === pages.NuovoPaziente || pageViewed === pages.NuovoProfessionista;
    //è true se stiamo creando/modificando/visualizzando un paziente
    const paziente = pageViewed === pages.NuovoPaziente || pageViewed === pages.Paziente;

    const [assegnaProfessionistaPazDisabled, setAssegnaProfessionistaPazDisabled] = useState(true);
    const [rimuoviProfessionistaPazDisabled, setRimuoviProfessionistaPazDisabled] = useState(true);
    const [programmaSedutaDisabled, setProgrammaSedutaDisabled] = useState(true);
    const [assegnaServizioDisabled, setAssegnaServizioDisabled] = useState(true);
    const [rimuoviServizioDisabled, setRimuoviServizioDisabled] = useState(true);
    const [impostaTariffaDisabled, setImpostaTariffaDisabled] = useState(true);
    const [altriNumeriValidi, setAltriNumeriValidi] = useState(false);
    const [soloItalia, setSoloItalia] = useState(true);
    const [loadingCities, setLoadingCities] = useState(false);
    const [cities, setCities] = useState([]);
    const [comuneValido, setComuneValido] = useState(false);
    const [selectedComune, setSelectedComune] = useState([]);
    const [ricercaComune, setRicercaComune] = useState("");
    const [inviaAncheEmailOnboarding, setInviaAncheEmailOnboarding] = useState(false);
    const [statoContabile, setStatoContabile] = useState(null);
    const [ultimoControllo, setUltimoControllo] = useState(undefined);
    const [tickIndexScansioneFatture, setTickIndexScansioneFatture] = useState(null);
    const [percentualeCompletamentoScansioneFatture, setPercentualeCompletamentoScansioneFatture] = useState(0.0);

    //useState per espandere o comprimere le card
    const [informazioniCardExpanded, setInformazioniCardExpanded] = useState(false);
    const [residenzaCardExpanded, setResidenzaCardExpanded] = useState(false);
    const [stsCardExpanded, setStsCardExpanded] = useState(false);
    const [emailCardExpanded, setEmailCardExpanded] = useState(false);
    const [numeriTelefonoCardExpanded, setNumeriTelefonoCardExpanded] = useState(false);
    //funzione che restituisce il numero di caratteri massimo per un campo dell'utente
    function getMaxCaratteriCampo(campo) {
        return process.env["REACT_APP_" + campo.toUpperCase() + "_UTENTE_MAX_CARATTERI"];
    }

    //funzioni di show e close dei modal
    function showPrezzoServizioModal() {
        setShowPrezzoServizioModal(true);
    }
    function handleClosePrezzoServizioModal() {
        setShowPrezzoServizioModal(false);
    }
    function showSedutaProgrammataModal() {
        setShowSedutaProgrammataModal(true);
    }
    function handleCloseSedutaProgrammataModal() {
        setShowSedutaProgrammataModal(false);
    }
    //FUNZIONI DI GESTIONE DEGLI AGGIORNAMENTI DEI CAMPI SUL FORM
    //testi e checkbox
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        //blocca la modifica di provincia, comune e cap direttamente dalla casella di testo se il paese selezionato è l'Italia
        if (!(soloItalia && (name == "provincia" || name == "cap" || name == "comune"))) {
            if (pageViewed != pages.MieInfo) {
                setProfessionistaPazFormValues(prevState => ({
                    ...prevState,
                    [name]: type === 'checkbox' ? checked : value
                }));

                props.setProfessionistaPazFormOk(prevState => ({
                    ...prevState,
                    [name]: true
                }));

            }
        }
    };
    //modalita della seduta per professionisti
    const handleModalitaSedutaChange = (event) => {
        if (pageViewed != pages.MieInfo) {
            setProfessionistaPazFormValues(prevState => ({
                ...prevState,
                modalita: parseInt(event.target.value)
            }));
        }
    }
    //FINE DELLE FUNZIONI DI GESTIONE DEGLI AGGIORNAMENTI DEI CAMPI SUL FORM

    //tutto cio che devi fare all'apertura della pagina
    function initialize() {
        let keys = Object.keys(props.professionistaPazFormOk);
        for (let i = 0; i < keys.length; i++) {
            props.setProfessionistaPazFormOk(prevState => ({
                ...prevState,
                [keys[i]]: true
            }));
        }
        if (newmode) {
            setSelectedPazienti([]);
            setSelectedProfessionisti([]);
            setSelectedServizi([]);
            setSoloItalia(true);
        }
        if (!newmode) {
            setSoloItalia(professionistaPazFormValues.paese == "Italia");
            setCities([]);
            setRicercaComune("");
            setSelectedComune([]);
        }
        if (pageViewed !== pages.MieInfo && !newmode) {
            setInformazioniCardExpanded(false);
            setResidenzaCardExpanded(false);
            setStsCardExpanded(false);
            setEmailCardExpanded(false);
            setNumeriTelefonoCardExpanded(false);
        } else {
            setInformazioniCardExpanded(true);
            setResidenzaCardExpanded(true);
            setStsCardExpanded(true);
            setEmailCardExpanded(true);
            setNumeriTelefonoCardExpanded(true);
        }
        setInviaAncheEmailOnboarding(false);
    }

    useEffect(() => {
        if (tickIndexScansioneFatture != null && tickIndexScansioneFatture != undefined) {
            nodeReq.get(process.env.REACT_APP_API_URL + "/percentualeCompletamentoScansione")
                .then(response => {
                    setPercentualeCompletamentoScansioneFatture(response.status == 200 ? response.data.completamento : undefined);
                    if (!(response.status == 200 && response.data.completamento == 100)) {
                        setTimeout(() => { setTickIndexScansioneFatture(tickIndexScansioneFatture + 1); }, 1000);
                    }
                })
                .catch(error => {
                    setPercentualeCompletamentoScansioneFatture(undefined);
                });
        }
    }, [tickIndexScansioneFatture]);

    //questa funzione deve fare queste cose una dopo l'altra:
    //chiamare l'API /passaAStatoAutomatico passando l'id del paziente
    //dopo risposta 200, chiamare l'API /aggiornaStatiPagamentoFatture
    //dopo risposta 200, chiamare le due funzioni ottieniStatoContabilePaziente(); loadDataOraUltimoControlloPagamenti();
    function passaAStatoAutomatico() {
        setLoading(true);
        nodeReq.post(process.env.REACT_APP_API_URL + "/passaAStatoAutomatico", { id: professionistaPazFormValues.idOld })
            .then(response => {
                if (response.status == 200) {
                    setLoading(true);
                    setTickIndexScansioneFatture(0);
                    nodeReq.get(process.env.REACT_APP_API_URL + "/aggiornaStatiPagamentiFatture")
                        .then(response => {
                            if (response.status == 200) {
                                genericAlert("Il passaggio a uno stato automatico per il paziente è stato completato");
                                ottieniStatoContabilePaziente(); loadDataOraUltimoControlloPagamenti();
                            } else {
                                genericAlert("Impossibile controllare i pagamenti delle fatture al momento a causa di un errore. Riprova più tardi.");
                            }
                        })
                        .catch(error => {
                            if (error.response != undefined && error.response.status == 503) {
                                genericAlert("La scansione delle fatture è già in corso su un altro dispositivo");
                            } else {
                                handleAPIError(error, "controllare i pagamenti delle fatture");
                            }
                        })
                        .finally(() => {
                            setLoading(false);
                            setShowStatoAutomaticoModal(false);
                            setTickIndexScansioneFatture(null);
                        });
                } else {
                    genericAlert("Impossibile impostare uno stato automatico al paziente al momento a causa di un errore. Riprova più tardi.");
                }
            })
            .catch(error => {
                //handleAPIError(error, "impostare uno stato automatico al paziente");
                genericAlert("Impossibile impostare uno stato automatico al paziente al momento. La causa potrebbe essere un errore imprevisto o il raggiungimento del limite di richieste di FattureInCloud. Riprovare più tardi. Se il problema persiste, contattare il supporto.");
            })
            .finally(() => {
                setLoading(false);
            });
    }
    //all'apertura di questa pagina, carica le liste dei servizi e dei pazienti/professionisti e segna quelli già assegnati all'utente che si sta modificando. Se invece si sta creando un nuovo utente, segnali tutti come non assegnati.
    //su tutti gli elementi delle 3 liste, metti viewed a true visto che le stringhe di ricerca saranno tutte vuote.
    useEffect(() => {
        if (professionistaPazCreateUpdateViewed) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: pagina crea/modifica prof/paz aperta");
            }
            initialize();
            setProfessionistaPazFormValuesBeforeEdits(JSON.parse(JSON.stringify(professionistaPazFormValues)));
            if (paziente) {
                //carica la lista dei professionisti
                let param = (professionistaPazFormValues.idOld != undefined ? "&idPaziente=" + professionistaPazFormValues.idOld : "");
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=professionista" + param)
                    .then(response => {
                        let data = response.data.dbdata;
                        if (response.status == 200) {
                            //popolamento della select
                            for (let i = 0; i < data.length; i++) {
                                data[i].assigned = data[i].idPaziente != null && data[i].idPaziente != undefined;
                                data[i].notAssigned = !data[i].assigned;
                                data[i].id = data[i].idUtente;
                            }
                            setListaProfessionisti(data);
                            setListaProfessionistiBeforeEdits(copiaLista(data));
                        } else {
                            genericAlert("Impossibile recuperare la lista dei professionisti. Riprova più tardi");
                        }
                    })
                    .catch(error => {
                        handleAPIError(error, "visualizzare la lista dei professionisti");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                //carica la lista dei pazienti
                let param1 = (professionistaPazFormValues.idOld != undefined ? "&idProfessionista=" + professionistaPazFormValues.idOld : "");
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=paziente" + param1)
                    .then(response => {
                        let data = response.data.dbdata;
                        if (response.status == 200) {
                            //popolamento della select
                            for (let i = 0; i < data.length; i++) {
                                data[i].assigned = data[i].idProfessionista != null && data[i].idProfessionista != undefined;
                                data[i].notAssigned = !data[i].assigned;
                                data[i].id = data[i].idUtente;
                            }
                            setListaPazienti(data);
                            setListaPazientiBeforeEdits(copiaLista(data));
                        } else {
                            genericAlert("Impossibile recuperare la lista dei pazienti. Riprova più tardi");
                        }
                    })
                    .catch(error => {
                        handleAPIError(error, "visualizzare la lista dei pazienti");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                //carica la lista dei servizi
                let param2 = (professionistaPazFormValues.idOld != undefined ? "?ordineAlfabetico=true&idProfessionista=" + professionistaPazFormValues.idOld : "?ordineAlfabetico=true");
                //TODO: gestisci anche la descrizione del servizio
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/servizi" + param2)
                    .then(response => {
                        let data = response.data.dbdata;
                        if (response.status == 200) {
                            for (let i = 0; i < data.length; i++) {
                                data[i].assigned = data[i].idProfessionista != null && data[i].idProfessionista != undefined;
                                data[i].notAssigned = !data[i].assigned;
                                data[i].id = data[i].idServizio;
                            }
                            setListaServizi(data);
                            setListaServiziBeforeEdits(copiaLista(data));
                        } else {
                            genericAlert("Impossibile recuperare la lista dei servizi. Riprova più tardi");
                        }
                    })
                    .catch(error => {
                        handleAPIError(error, "recuperare la lista dei servizi");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
            //carica le email di comunicazione
            if (professionistaPazFormValues.idOld != undefined) {
                //siamo in modalità modifica
                setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/abilitazioniNotifiche?idUtente=" + professionistaPazFormValues.idOld)
                    .then(response => {
                        if (response.status == 200) {
                            props.setEmailComunicazioni(response.data);
                            props.setEmailComunicazioniBeforeEdits(JSON.parse(JSON.stringify(response.data)));
                        } else {
                            genericAlert("Impossibile recuperare le abilitazioni delle notifiche. Riprova più tardi");
                        }
                    })
                    .catch(error => {
                        handleAPIError(error, "recuperare le abilitazioni delle notifiche");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                //siamo in modalità nuova
                props.setEmailComunicazioni({});
                props.setEmailComunicazioniBeforeEdits({});
            }
            if (pageViewed === pages.Paziente) {
                ottieniStatoContabilePaziente();
                loadDataOraUltimoControlloPagamenti();
            }
        }
    }, [pageViewed]);

    function ottieniStatoContabilePaziente() {
        setLoading(true);
        nodeReq.get(process.env.REACT_APP_API_URL + "/statoContabilePaziente?id=" + professionistaPazFormValues.idOld)
            .then(response => {
                if (response.status == 200) {
                    setStatoContabile(response.data.stato);
                } else {
                    setStatoContabile(undefined);
                }
            })
            .catch(error => {
                setStatoContabile(undefined);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    //quando cambia il comune selezionato nella select, riportalo nei campi comune, provincia e cap
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: hai selezionato un comune. lo riporto nei tre campi comune, cap, provincia");
        }
        if (cities[selectedComune] != undefined) {
            setProfessionistaPazFormValues(prevState => ({
                ...prevState,
                comune: cities[selectedComune].city,
                provincia: cities[selectedComune].province,
                cap: cities[selectedComune].postal_code
            }));
        } else if (isNaN(selectedComune)) {
            setProfessionistaPazFormValues(prevState => ({
                ...prevState,
                comune: "",
                provincia: "",
                cap: ""
            }));
        }
    }, [selectedComune]);

    //se l'utente decide di mostrare altri paesi o sceglie l'Italia, devi fare la chiamata API a fattureInCloud

    useEffect(() => {
        if (ricercaComune.length >= 3) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: 3 caratteri nella casella di ricerca del comune. chiamata API a FattureInCloud");
            }
            setLoading(true);
            setLoadingCities(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/comuniFIC?city=" + ricercaComune)
                .then(response => {
                    if (response.status == 200) {
                        let citiesTemp = response.data;
                        console.log("Città arrivate dal backend");
                        console.log(citiesTemp);
                        let found = false;
                        for (let i = 0; i < citiesTemp.length; i++) {
                            citiesTemp[i].id = i;
                            citiesTemp[i].viewed = true;
                            if (citiesTemp[i].postal_code == professionistaPazFormValues.cap && citiesTemp[i].city.toLowerCase() == professionistaPazFormValues.comune.toLowerCase() && citiesTemp[i].province.toLowerCase() == professionistaPazFormValues.provincia.toLowerCase()) {
                                found = true;
                                setSelectedComune([i]);
                            }
                        }
                        if (!found) {
                            setSelectedComune([]);
                        }
                        setCities(citiesTemp);
                    } else {
                        genericAlert("Impossibile ottenere l'elenco dei comuni da FattureInCloud al momento. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    console.log("ERRORE");
                    console.log(error);
                    handleAPIError(error, "ottenere l'elenco dei comuni da FattureInCloud");
                })
                .finally(() => {
                    setLoading(false);
                    setLoadingCities(false);
                });
        }
    }, [ricercaComune]);

    //se ora il paese è stato impostato su "Italia" e non sei ancora nella scheda "Italia" del box residenza, entraci.
    //se ora il paese impostato non è "Italia" e non sei ancora nella scheda "Altro paese", entraci, fai la chiamataAPI a FattureInCloud che restituisce tutti i paesi e seleziona il paese giusto.


    //se l'email appena inserita nel box informazioni è formattata correttamente, riportala nel box delle email di notifiche
    useEffect(() => {
        if (emailFormatOk(professionistaPazFormValues.email)) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: metto email del box informazioni nel box email notifiche");
            }
            //è arrivato un nuovo indirizzo email valido. Se quello precedente lo trovi all'interno della select, aggiornalo. Altrimenti, inserisci quello nuovo
            if (Object.keys(props.emailComunicazioni).includes(ultimoIndirizzoEmailValido)) {
                let emailComunicazioniTemp = JSON.parse(JSON.stringify(props.emailComunicazioni));
                let v = emailComunicazioniTemp[ultimoIndirizzoEmailValido];
                emailComunicazioniTemp[professionistaPazFormValues.email] = [v[0], v[1], v[2], v[3], v[4], v[5], v[6], v[7]];
                delete emailComunicazioniTemp[ultimoIndirizzoEmailValido];
                props.setEmailComunicazioni(emailComunicazioniTemp);
            } else {
                let emailComunicazioniTemp = JSON.parse(JSON.stringify(props.emailComunicazioni));
                if (!props.paziente) {
                    emailComunicazioniTemp[professionistaPazFormValues.email] = [true, true, true, false, false, true, true, true];
                } else {
                    emailComunicazioniTemp[professionistaPazFormValues.email] = [true, true, true, false, false, true, false, true];
                }
                props.setEmailComunicazioni(emailComunicazioniTemp);
            }
            setUltimoIndirizzoEmailValido(professionistaPazFormValues.email);
        }
    }, [professionistaPazFormValues.email]);

    //in base ai pazienti o professionisti selezionati, abilita e disabilita i pulsanti "Assegna", "Disassegna" e "Programma seduta settimanale"
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: abilito e disabilito i pulsanti assegna rimuovi e programma seduta sett");
        }
        let lista = paziente ? listaProfessionisti : listaPazienti;
        let selected = paziente ? selectedProfessionisti : selectedPazienti;
        let atLeastOneAssignedSelected = false;
        let onlyOneAssignedSelected = false;
        let atLeastOneNotAssignedSelected = false;
        for (let i = 0; i < lista.length; i++) {
            //se il professionista è selezionato e assegnato...
            if (lista[i].assigned && selected.includes(lista[i].id.toString())) {
                if (!atLeastOneAssignedSelected) {
                    atLeastOneAssignedSelected = true;
                    onlyOneAssignedSelected = true;
                } else {
                    onlyOneAssignedSelected = false;
                }
            }
            //se il professionista è selezionato e non assegnato...
            if (!lista[i].assigned && selected.includes(lista[i].id.toString())) {
                atLeastOneNotAssignedSelected = true;
            }
            if (atLeastOneAssignedSelected && !onlyOneAssignedSelected && atLeastOneNotAssignedSelected) {
                break;
            }
        }
        setAssegnaProfessionistaPazDisabled(!atLeastOneNotAssignedSelected);
        setRimuoviProfessionistaPazDisabled(!atLeastOneAssignedSelected);
        setProgrammaSedutaDisabled(!onlyOneAssignedSelected);
    }, [selectedPazienti, selectedProfessionisti]);

    //in base ai servizi selezionati, abilita e disabilita i pulsanti "Assegna", "Disassegna" e "Imposta tariffa"
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: abilito e disabilito i pulsanti assegna rimuovi e imposta tariffa");
        }
        let atLeastOneAssignedSelected = false;
        let onlyOneAssignedSelected = false;
        let atLeastOneNotAssignedSelected = false;
        for (let i = 0; i < listaServizi.length; i++) {
            //se il professionista è selezionato e assegnato...
            if (listaServizi[i].id != undefined && listaServizi[i].assigned && selectedServizi.includes(listaServizi[i].id.toString())) {
                if (!atLeastOneAssignedSelected) {
                    atLeastOneAssignedSelected = true;
                    onlyOneAssignedSelected = true;
                } else {
                    onlyOneAssignedSelected = false;
                }
            }
            //se il professionista è selezionato e non assegnato...
            if (listaServizi[i].id != undefined && !listaServizi[i].assigned && selectedServizi.includes(listaServizi[i].id.toString())) {
                atLeastOneNotAssignedSelected = true;
            }
            if (atLeastOneAssignedSelected && !onlyOneAssignedSelected && atLeastOneNotAssignedSelected) {
                break;
            }
        }
        setAssegnaServizioDisabled(!atLeastOneNotAssignedSelected);
        setRimuoviServizioDisabled(!atLeastOneAssignedSelected);
        setImpostaTariffaDisabled(!onlyOneAssignedSelected);
    }, [selectedServizi]);

    function emailFormatOk(email) {
        if (email == undefined) {
            return false;
        }
        return email.toLowerCase().match(props.professionistaPazFormRegexs.email);
    }

    //controlla che siano compilati correttamente tutti i campi del form
    function checkInformazioni() {
        let allOk = true;
        //Controlla ogni campo tra quelli di testo
        let campi = Object.keys(props.professionistaPazFormRegexs);
        for (let i = 0; i < campi.length; i++) {
            let valore = professionistaPazFormValues[campi[i]];
            if ((props.professionistaPazFormObbligatori[campi[i]] == false || valore.length > 1) && valore.length <= getMaxCaratteriCampo(campi[i]) && valore.toLowerCase().match(props.professionistaPazFormRegexs[campi[i]])) {
                props.setProfessionistaPazFormOk(prevState => ({
                    ...prevState,
                    [campi[i]]: true
                }));
            } else {
                props.setProfessionistaPazFormOk(prevState => ({
                    ...prevState,
                    [campi[i]]: false
                }));
                allOk = false;
            }
        }
        return allOk;
    }
    //FUNZIONI PER I BOTTONI ASSEGNA E DISASSEGNA
    //se assign = true, imposta i pazienti selezionati nella select come assegnati (assigned = true)
    //se assign = false, imposta i pazienti selezionati nella select come non assegnati (assigned = false)
    function assegnazionePaziente(assign) {
        if (pageViewed != pages.MieInfo) {
            let listaPazientiTemp = listaPazienti.slice();
            for (let i = 0; i < listaPazientiTemp.length; i++) {
                //Se stai assegnando, il paziente è non assegnato ed è selezionato
                if (assign && listaPazientiTemp[i].assigned == false && selectedPazienti.includes(listaPazientiTemp[i].id.toString())) {
                    listaPazientiTemp[i].assigned = true;
                    listaPazientiTemp[i].notAssigned = false;
                    listaPazientiTemp[i].giorno = null;
                    listaPazientiTemp[i].oraInizio = null;
                    listaPazientiTemp[i].oraFine = null;
                }
                //Se stai disassegnando, il paziente è assegnato ed è selezionato
                if (!assign && listaPazientiTemp[i].assigned == true && selectedPazienti.includes(listaPazientiTemp[i].id.toString())) {
                    listaPazientiTemp[i].assigned = false;
                    listaPazientiTemp[i].notAssigned = true;
                    listaPazientiTemp[i].giorno = null;
                    listaPazientiTemp[i].oraInizio = null;
                    listaPazientiTemp[i].oraFine = null;
                }
            }
            setListaPazienti(listaPazientiTemp);
            setSelectedPazienti([]);

        }
    }

    //se assign = true, imposta i professionisti selezionati nella select come assegnati (assigned = true)
    //se assign = false, imposta i professionisti selezionati nella select come non assegnati (assigned = false)
    function assegnazioneProfessionista(assign) {
        if (pageViewed != pages.MieInfo) {
            let listaProfessionistiTemp = listaProfessionisti.slice();
            for (let i = 0; i < listaProfessionistiTemp.length; i++) {
                //Se stai assegnando, il paziente è non assegnato ed è selezionato
                if (assign && listaProfessionistiTemp[i].assigned == false && selectedProfessionisti.includes(listaProfessionistiTemp[i].id.toString())) {
                    listaProfessionistiTemp[i].assigned = true;
                    listaProfessionistiTemp[i].notAssigned = false;
                    listaProfessionistiTemp[i].giorno = null;
                    listaProfessionistiTemp[i].oraInizio = null;
                    listaProfessionistiTemp[i].oraFine = null;
                }
                //Se stai disassegnando, il paziente è assegnato ed è selezionato
                if (!assign && listaProfessionistiTemp[i].assigned == true && selectedProfessionisti.includes(listaProfessionistiTemp[i].id.toString())) {
                    listaProfessionistiTemp[i].assigned = false;
                    listaProfessionistiTemp[i].notAssigned = true;
                    listaProfessionistiTemp[i].giorno = null;
                    listaProfessionistiTemp[i].oraInizio = null;
                    listaProfessionistiTemp[i].oraFine = null;
                }
            }
            setListaProfessionisti(listaProfessionistiTemp);
            setSelectedProfessionisti([]);

        }
    }

    //se assign = true, imposta i servizi selezionati nella select come assegnati (assigned = true)
    //se assign = false, imposta i servizi selezionati nella select come non assegnati (assigned = false)
    function assegnazioneServizio(assign) {
        if (pageViewed != pages.MieInfo) {
            let listaServiziTemp = listaServizi.slice();
            for (let i = 0; i < listaServiziTemp.length; i++) {
                //Se stai assegnando, il servizio è non assegnato ed è selezionato...
                if (assign && listaServiziTemp[i].assigned == false && selectedServizi.includes(listaServiziTemp[i].id.toString())) {
                    listaServiziTemp[i].assigned = true;
                    listaServiziTemp[i].notAssigned = false;
                    listaServiziTemp[i].tariffa = listaServiziTemp[i].tariffaDefault;
                    listaServiziTemp[i].costoProfessionistaAssegnato = listaServiziTemp[i].costoProfessionista;
                }
                //Se stai disassegnando, il servizio è assegnato ed è selezionato...
                if (!assign && listaServiziTemp[i].assigned == true && selectedServizi.includes(listaServiziTemp[i].id.toString())) {
                    listaServiziTemp[i].assigned = false;
                    listaServiziTemp[i].notAssigned = true;
                    listaServiziTemp[i].tariffa = listaServiziTemp[i].tariffaDefault;
                    listaServiziTemp[i].costoProfessionistaAssegnato = listaServiziTemp[i].costoProfessionista;
                }
            }
            setListaServizi(listaServiziTemp);
            setSelectedServizi([]);

        }
    }

    //FUNZIONI PER DISASSEGNARE TUTTO, CHIAMATE ESCLUSIVAMENTE DOPO LA CREAZIONE DI UN PAZIENTE O DI UN PROFESSIONISTA

    function disassegnaPazienti() {
        if (pageViewed != pages.MieInfo) {
            let listaPazientiTemp = listaPazienti.slice();
            for (let i = 0; i < listaPazientiTemp.length; i++) {
                listaPazientiTemp[i].assigned = false;
                listaPazientiTemp[i].notAssigned = true;
                listaPazientiTemp[i].giorno = null;
                listaPazientiTemp[i].oraInizio = null;
                listaPazientiTemp[i].oraFine = null;
            }
            setListaPazienti(listaPazientiTemp);
            setListaPazientiBeforeEdits(copiaLista(listaPazientiTemp));
            setSelectedPazienti([]);

        }
    }

    function disassegnaProfessionisti() {
        if (pageViewed != pages.MieInfo) {
            let listaProfessionistiTemp = listaProfessionisti.slice();
            for (let i = 0; i < listaProfessionistiTemp.length; i++) {
                listaProfessionistiTemp[i].assigned = false;
                listaProfessionistiTemp[i].notAssigned = true;
                listaProfessionistiTemp[i].giorno = null;
                listaProfessionistiTemp[i].oraInizio = null;
                listaProfessionistiTemp[i].oraFine = null;
            }
            setListaProfessionisti(listaProfessionistiTemp);
            setListaProfessionistiBeforeEdits(copiaLista(listaProfessionistiTemp));
            setSelectedProfessionisti([]);
        }
    }

    function disassegnaServizi() {
        if (pageViewed != pages.MieInfo) {
            let listaServiziTemp = listaServizi.slice();
            for (let i = 0; i < listaServiziTemp.length; i++) {
                listaServiziTemp[i].assigned = false;
                listaServiziTemp[i].tariffa = listaServiziTemp[i].tariffaDefault;
                listaServiziTemp[i].costoProfessionistaAssegnato = listaServiziTemp[i].costoProfessionista;
            }
            setListaServizi(listaServiziTemp);
            setListaServiziBeforeEdits(copiaLista(listaServiziTemp));
            setSelectedServizi([]);

        }
    }

    //Restituisce true se il contenuto di listaPazienti è diverso da quello di listaPazientiBeforeEdits, oppure se il contenuto di listaProfessionisti è diverso da quello di listaProfessionistiBeforeEdits.
    //Restituisce false altrimenti
    function checkAssegnazioniChanged() {
        let lista = paziente ? listaProfessionisti : listaPazienti;
        let listaBeforeEdits = paziente ? listaProfessionistiBeforeEdits : listaPazientiBeforeEdits;
        if (lista.length != listaBeforeEdits.length) {
            return true;
        }
        for (let i = 0; i < lista.length; i++) {
            if (JSON.stringify(lista[i]) !== JSON.stringify(listaBeforeEdits[i])) {
                //almeno un professionista/paziente è cambiato
                return true;
            }
        }
        //stessa lunghezza e tutti gli elementi uguali. Non è cambiato niente!
        return false;
    }

    //Restituisce true se il contenuto di professionistaPazFormValues è diverso da quello di professionistaPazFormValuesBeforeEdits
    //Restituisce false altrimenti
    function checkFormValuesChanged() {
        return JSON.stringify(professionistaPazFormValues) !== JSON.stringify(professionistaPazFormValuesBeforeEdits);
    }
    //crea il nuovo paziente/professionista nel db, oppure salva le modifiche
    function saveProfessionistaPaz() {
        if (Object.keys(props.emailComunicazioni).length == 0) {
            genericAlert("Inserisci almeno un indirizzo email per le notifiche prima di salvare");
            setEmailCardExpanded(true);
        } else if (!altriNumeriValidi) {
            genericAlert("Per ogni contatto inserito, specifica il numero di telefono prima di salvare");
            setNumeriTelefonoCardExpanded(true);
        } else if (!Array.isArray(professionistaPazFormValues.altriNumeriTelefono) || professionistaPazFormValues.altriNumeriTelefono.length == 0) {
            genericAlert("Inserisci almeno un numero di telefono prima di salvare");
            setNumeriTelefonoCardExpanded(true);
        }
        if (checkInformazioni()) {
            console.log(professionistaPazFormValues.altriNumeriTelefono);
            let assegnazioniChanged = checkAssegnazioniChanged();

            let formValuesChanged = checkFormValuesChanged();

            //se professionistaPazFormValues.idOld è undefined, allora la modalità è nuovo, altrimenti la modalità è modifica
            if (paziente) {
                //Crea/aggiorna il paziente
                //crea l'array degli id dei professionisti
                let idProfessionisti = [];
                listaProfessionisti.forEach(professionista => { if (professionista.assigned) { idProfessionisti.push({ id: professionista.idUtente, giorno: professionista.giorno, oraInizio: professionista.oraInizio, oraFine: professionista.oraFine, nome: professionista.nome, cognome: professionista.cognome }); } });
                professionistaPazFormValues.modalita = null;
                //passa all'endpoint i dati del form + gli id dei professionisti
                let reqBody = { idOld: professionistaPazFormValues.idOld, cf: professionistaPazFormValues.cf, nome: professionistaPazFormValues.nome, cognome: professionistaPazFormValues.cognome, email: professionistaPazFormValues.email, attivo: professionistaPazFormValues.attivo, modalita: professionistaPazFormValues.modalita, professionisti: idProfessionisti, emailComunicazioni: props.emailComunicazioni, formValuesChanged: formValuesChanged, assegnazioniChanged: assegnazioniChanged, indirizzo: professionistaPazFormValues.indirizzo, comune: professionistaPazFormValues.comune, cap: professionistaPazFormValues.cap, provincia: professionistaPazFormValues.provincia, paese: professionistaPazFormValues.paese, numeriTelefono: professionistaPazFormValues.altriNumeriTelefono, consensoSts: professionistaPazFormValues.consensoSts, genere: professionistaPazFormValues.genere, inviaAncheEmailOnboarding: inviaAncheEmailOnboarding };
                setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + '/paziente', reqBody)
                    .then(response => {
                        //Mostra l'alert corretto in base agli invii delle email
                        if (response.data.other.notifica1 != undefined && response.data.other.notifica2 != undefined) {
                            if (response.data.other.notifica1[0] == true && response.data.other.notifica2[0] == true) {
                                genericAlert("Paziente inserito o modificato con successo");
                            } else {
                                if (response.data.other.notifica1[0] != true && response.data.other.notifica2[0] == true) {
                                    genericAlert("Paziente inserito o modificato con successo, ma si è verificato un errore nell'invio di una delle due email di notifica");
                                } else if (response.data.other.notifica1[0] == true && response.data.other.notifica2[0] != true) {
                                    genericAlert("Paziente inserito o modificato con successo, ma si è verificato un errore nell'invio di una delle due email di notifica");
                                } else if (response.data.other.notifica1[0] != true && response.data.other.notifica2[0] != true) {
                                    genericAlert("Paziente inserito o modificato con successo, ma si è verificato un errore nell'invio delle due email di notifica");
                                }
                            }
                        } else if (Array.isArray(response.data.other) && response.data.other.length == 1) {
                            if (response.data.other[0] != true) {
                                genericAlert("Paziente inserito o modificato con successo, ma si è verificato un errore nell'invio dell'email di notifica");
                            } else {
                                genericAlert("Paziente inserito o modificato con successo");
                            }
                        } else {
                            genericAlert("Paziente inserito o modificato con successo");
                        }
                        //se sei in modalità nuovo paziente, devi resettare tutto
                        if (newmode) {
                            setCities([]);
                            setRicercaComune("");
                            setSelectedComune([]);
                            setSoloItalia(true);
                            setProfessionistaPazFormValues(initialProfessionistaPazFormValues);
                            setProfessionistaPazFormValuesBeforeEdits(JSON.parse(JSON.stringify(initialProfessionistaPazFormValues)));
                            //resetta anche le select
                            disassegnaProfessionisti();
                            setEmailComunicazioniResetted(false);
                            openModifiedPaziente.current = response.data.insertId;
                            console.log("Ho impostato questo id da aprire: " + response.data.insertId);
                            setPageViewed(pages.Pazienti); cambiaUrl(pages.Pazienti);
                        } else {
                            setListaProfessionistiBeforeEdits(copiaLista(listaProfessionisti));
                            setProfessionistaPazFormValuesBeforeEdits(JSON.parse(JSON.stringify(professionistaPazFormValues)));
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response && error.response.status == 400) {
                            if (error.response.data == "cfAlreadyInUse") {
                                genericAlert("Codice fiscale non valido: è già utilizzato per un altro utente.");
                                setInformazioniCardExpanded(true);
                            }
                        } else {
                            handleAPIError(error, "inserire un paziente");
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                //Crea/aggiorna il professionista
                //crea l'array degli dei pazienti
                let idPazienti = [];
                listaPazienti.forEach(paziente => { if (paziente.assigned) { idPazienti.push({ id: paziente.idUtente, giorno: paziente.giorno, oraInizio: paziente.oraInizio, oraFine: paziente.oraFine, nome: paziente.nome, cognome: paziente.cognome }); } });
                //crea l'array degli id dei servizi con le tariffe
                let servizi = [];
                listaServizi.forEach(servizio => { if (servizio.assigned) { servizi.push({ id: servizio.idServizio, tariffa: servizio.tariffa, costoProfessionista: servizio.costoProfessionistaAssegnato }); } });
                //passa all'endpoint i dati del form + gli id dei pazienti + gli id dei servizi con le tariffe
                let reqBody = { idOld: professionistaPazFormValues.idOld, cf: professionistaPazFormValues.cf, nome: professionistaPazFormValues.nome, cognome: professionistaPazFormValues.cognome, email: professionistaPazFormValues.email, attivo: professionistaPazFormValues.attivo, modalita: professionistaPazFormValues.modalita, pazienti: idPazienti, servizi: servizi, emailComunicazioni: props.emailComunicazioni, formValuesChanged: formValuesChanged, assegnazioniChanged: assegnazioniChanged, indirizzo: professionistaPazFormValues.indirizzo, comune: professionistaPazFormValues.comune, cap: professionistaPazFormValues.cap, provincia: professionistaPazFormValues.provincia, paese: professionistaPazFormValues.paese, numeriTelefono: professionistaPazFormValues.altriNumeriTelefono, genere: professionistaPazFormValues.genere, coordinatore: professionistaPazFormValues.coordinatore };
                setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + '/professionista', reqBody)
                    .then(response => {
                        //Mostra l'alert corretto in base agli invii delle email
                        if (response.data.other.notifica1 != undefined && response.data.other.notifica2 != undefined) {
                            if (response.data.other.notifica1[0] == true && response.data.other.notifica2[0] == true) {
                                genericAlert("Professionista inserito o modificato con successo");
                            } else {
                                if (response.data.other.notifica1[0] != true && response.data.other.notifica2[0] == true) {
                                    genericAlert("Professionista inserito o modificato con successo, ma si è verificato un errore nell'invio di una delle due email di notifica");
                                } else if (response.data.other.notifica1[0] == true && response.data.other.notifica2[0] != true) {
                                    genericAlert("Professionista inserito o modificato con successo, ma si è verificato un errore nell'invio di una delle due email di notifica");
                                } else if (response.data.other.notifica1[0] != true && response.data.other.notifica2[0] != true) {
                                    genericAlert("Professionista inserito o modificato con successo, ma si è verificato un errore nell'invio delle due email di notifica");
                                }
                            }
                        } else if (Array.isArray(response.data.other) && response.data.other.length == 1) {
                            if (response.data.other[0] != true) {
                                genericAlert("Professionista inserito o modificato con successo, ma si è verificato un errore nell'invio dell'email di notifica");
                            } else {
                                genericAlert("Professionista inserito o modificato con successo");
                            }
                        } else {
                            genericAlert("Professionista inserito o modificato con successo");
                        }
                        //se siamo in modalità nuovo professionista, resetta tutto
                        if (newmode) {
                            setCities([]);
                            setRicercaComune("");
                            setSelectedComune([]);
                            setSoloItalia(true);
                            setProfessionistaPazFormValues(initialProfessionistaPazFormValues);
                            setProfessionistaPazFormValuesBeforeEdits(JSON.parse(JSON.stringify(initialProfessionistaPazFormValues)));
                            //resetta anche le select
                            disassegnaPazienti();
                            disassegnaServizi();
                            setEmailComunicazioniResetted(false);
                            openModifiedProfessionista.current = response.data.insertId;
                            console.log("Ho impostato questo id da aprire: " + response.data.insertId);
                            setPageViewed(pages.Professionisti); cambiaUrl(pages.Professionisti);
                        } else {
                            setListaPazientiBeforeEdits(copiaLista(listaPazienti));
                            setListaServiziBeforeEdits(copiaLista(listaServizi));
                            setProfessionistaPazFormValuesBeforeEdits(JSON.parse(JSON.stringify(professionistaPazFormValues)));
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status == 400) {
                            if (error.response.data == "cfAlreadyInUse") {
                                genericAlert("Codice fiscale non valido: è già utilizzato per un altro utente.");
                            } else if (error.response.data == "emailAlreadyInUse") {
                                genericAlert("Indirizzo email non valido: è già utilizzato per un altro professionista.");
                            }
                            setInformazioniCardExpanded(true);
                        } else {
                            handleAPIError(error, "inserire un professionista");
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }

        } else {
            genericAlert("Ci sono alcuni campi non compilati correttamente");
            setInformazioniCardExpanded(true);
            setResidenzaCardExpanded(true);
        }
        /*
    }
    */
    }

    function nomeGiorno(n) {
        return giorni[n];
    }

    function copiaLista(array) {
        return JSON.parse(JSON.stringify(array));
    }

    function getProfessionistaPazText(element) {
        return element.cognome + " " + element.nome + (element.assigned ? (element.giorno == null ? "(Senza seduta programmata)" : "(" + nomeGiorno(element.giorno) + " dalle " + element.oraInizio + " alle " + element.oraFine + ")") : "");
    }

    function getServizioText(element) {
        return element.nomeServizio + (element.descrizioneServizio.length > 0 ? " (" + element.descrizioneServizio + ")" : "") + (element.assigned ? (" (Tariffa: " + importoConPuntoEVirgola(element.tariffa) + (element.tipoServizio != 0 ? "" : "/ora") + ", Costo professionista: " + importoConPuntoEVirgola(element.costoProfessionistaAssegnato) + (element.tipoServizio != 0 ? ")" : "/ora)")) : "");
    }

    function sendPasswordSetEmail() {
        let reqbody = { idProfessionista: professionistaPazFormValues.idOld, email: professionistaPazFormValuesBeforeEdits.email };
        setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/sendPasswordSetEmail", reqbody)
            .then(response => {
                if (response.status == 200) {
                    if (response.data[0] == true) {
                        genericAlert("L'email è stata inviata correttamente.");
                    } else {
                        genericAlert("Purtroppo non è stato possibile inviare l'email");
                    }
                } else {
                    genericAlert("Impossibile inviare l'email per impostare la password a causa di un errore. Riprova più tardi.");
                }
            }).catch(error => {
                if (error.response != undefined && error.response.status == 400) {
                    genericAlert("L'utente non è attivo oppure è stato eliminato");
                } else {
                    handleAPIError(error, "inviare l'email per impostare la password");
                }

            })
            .finally(() => {
                setLoading(false);
                setShowInvioEmailModal(false);
            });
    }

    function associazioneFattureInCloud() {
        setLoading(true);
        nodeReq.post(process.env.REACT_APP_API_URL + "/associaPazienteAFIC", { idUtente: professionistaPazFormValues.idOld })
            .then(response => {
                if (response.status == 200) {
                    genericAlert("Associazione eseguita correttamente");
                } else {
                    genericAlert("Associazione fallita");
                }
            })
            .catch(error => {
                if (error.response != undefined && error.response.status == 400) {
                    if (error.response.data.tipo == "datiNonInseriti") {
                        genericAlert("Prima di associare il paziente a FattureInCloud, assicurati di aver inserito il suo codice fiscale e la sua residenza (Comune, CAP, provincia e indirizzo) e salva le modifiche.");
                    } else {
                        genericAlert("Impossibile associare il paziente a FattureInCloud: I dati relativi alla residenza oppure il codice fiscale sono sbagliati, oppure FattureInCloud non funziona al momento.");
                    }
                } else {
                    handleAPIError(error, "associare il paziente a FattureInCloud");
                }
            })
            .finally(() => {
                setLoading(false);
            }
            );
    }

    function primaLetteraMaiuscola(str) {
        return str[0].toUpperCase() + str.substring(1);
    }

    function inviaEmailOnboarding() {
        setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/emailOnboarding", { idUtente: professionistaPazFormValues.idOld })
            .then(response => {
                if (response.status == 200) {
                    if (response.data[0] == true) {
                        genericAlert("L'email è stata inviata correttamente.");
                    } else {
                        genericAlert("Purtroppo non è stato possibile inviare l'email");
                    }
                } else {
                    genericAlert("Impossibile inviare l'email di onboarding al momento. Riprova più tardi.");
                }
            })
            .catch(error => {
                handleAPIError(error, "inviare l'email di onboarding");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleConsensoSTSChange(event) {
        setProfessionistaPazFormValues(prevState => ({
            ...prevState,
            consensoSts: !event.target.checked
        }));
    }

    function getIconNameStatoContabile() {
        switch (statoContabile) {
            case "In regola":
                return "check";
            case "Sbloccato":
                return "unlock";
            case "Bloccato":
                return "lock";
            case "Insolvente":
                return "lock";
            case null:
                return "loader";
            case undefined:
                return "help-circle";
            default:
                return "help-circle";
        }
    }

    function loadDataOraUltimoControlloPagamenti() {
        if (loggedUser.tipoUtente == "segretario") {
            setLoading(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/ultimoControlloPagamenti")
                .then(response => {
                    if (response.status == 200) {
                        setUltimoControllo(response.data.dataOra);
                    } else {
                        genericAlert("Impossibile conoscere l'ultimo controllo dei pagamenti al momento a causa di un errore. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "conoscere l'ultimo controllo dei pagamenti");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    function apriBloccaSbloccaPazienteModal(blocca) {
        if (blocca == true) {
            setShowBloccaPazienteModal(true);
        } else {
            setShowSbloccaPazienteModal(true);
        }
    }

    function bloccaSbloccaPaziente(blocca) {
        setLoading(true);
        nodeReq.post(process.env.REACT_APP_API_URL + "/bloccaSbloccaPaziente", { id: professionistaPazFormValues.idOld, blocca: blocca })
            .then(response => {
                if (response.status == 200) {
                    genericAlert("Paziente " + (blocca == true ? "" : "s") + "bloccato con successo.");
                } else {
                    genericAlert("Impossibile " + (blocca == true ? "" : "s") + "bloccare manualmente il paziente al momento a causa di un errore. Riprova più tardi.");
                }
            })
            .catch(error => {
                handleAPIError(error, (blocca == true ? "" : "s") + "bloccare manualmente il paziente");
            })
            .finally(() => {
                setLoading(false);
                setShowBloccaPazienteModal(false);
                setShowSbloccaPazienteModal(false);
                ottieniStatoContabilePaziente();
            });
    }
    return (
        <div id="professionistaPazCreateUpdateDiv" style={{ display: professionistaPazCreateUpdateViewed ? 'block' : 'none' }}>
            {/*Heading*/}
            {pageViewed != pages.MieInfo && newmode == false &&
                <Heading title={paziente ? ("Lista pazienti") : ("Lista professionisti")} iconName="users" />
            }
            {/*Heading */}
            {pageViewed != pages.MieInfo && <>
                {(newmode == false && paziente == false) && (<h1 className="h3 mb-3"><b>Dettagli professionista</b>  {professionistaPazFormValues.cognomeOld} {professionistaPazFormValues.nomeOld}</h1>)}
                {(newmode == false && paziente == true) && (<h1 className="h3 mb-3"><b>Dettagli paziente</b>  {professionistaPazFormValues.cognomeOld} {professionistaPazFormValues.nomeOld}</h1>)}
                {(newmode == true && paziente == false) && (<Heading title="Nuovo professionista" iconName="user-plus" />)}
                {(newmode == true && paziente == true) && (<Heading title="Nuovo paziente" iconName="user-plus" />)}
            </>
            }
            {pageViewed == pages.MieInfo && <Heading title="Le mie informazioni" iconName="user" />}
            {/*Serie di pulsanti */}
            {(newmode == false && pageViewed != pages.MieInfo) &&
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {(newmode == false && paziente == true) && (<button className="btn btn-primary btn-lg me-sm-2 m-1" onClick={() => { checkUnsavedBeforeDoing(() => { checkUserLoggedBeforeDoing(() => { setPageViewed(pages.Pazienti); }); }); }}>Indietro</button>)}
                                {(newmode == false && paziente == false) && (<button className="btn btn-primary btn-lg me-sm-2 m-1" onClick={() => { checkUnsavedBeforeDoing(() => { checkUserLoggedBeforeDoing(() => { setPageViewed(pages.Professionisti); }); }); }}>Indietro</button>)}
                                {pageViewed === pages.Professionista && <button className="btn btn-primary btn-lg me-sm-2 m-1" onClick={() => { setShowInvioEmailModal(true); }}>Richiedi una nuova password</button>}
                                {paziente == true && <APICallButton buttonClassName="btn btn-primary btn-lg me-sm-2 m-1" buttonOnClick={associazioneFattureInCloud} buttonText="Esegui associazione a FattureInCloud" nomeProcedura={"l'associazione del paziente a FattureInCloud"} />}
                                {paziente == true && professionistaPazFormValues.idOld != undefined && <APICallButton buttonClassName="btn btn-primary btn-lg me-sm-2 m-1" buttonOnClick={inviaEmailOnboarding} buttonText="Invia email di onboarding" nomeProcedura={"l'invio dell'email di onboarding"} />}
                            </div>
                        </div>
                    </div>
                </div>
            }
            {pageViewed === pages.Paziente &&
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex flex-row justify-content-between">
                                    <div>
                                        <h5 className="card-title">Stato contabile</h5>
                                    </div>
                                    <div>
                                        <p className="mb-0">Ultimo controllo pagamenti fatture: {ultimoControllo != undefined ? ultimoControllo : "--"}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="d-inline-block p-1">
                                    <div className={"badge " + (statoContabile == undefined || statoContabile == "Bloccato" || statoContabile == "Insolvente" ? "bg-danger" : (statoContabile == "In regola" || statoContabile == "Sbloccato" ? "bg-success" : "bg-light"))}>
                                        <FeatherIcon iconName={getIconNameStatoContabile()} />
                                        &nbsp;
                                        {statoContabile == undefined ? "Errore" : (statoContabile == null ? "Caricamento..." : statoContabile)}
                                    </div>
                                </div>
                                <div className="d-inline-block p-1">
                                    {(statoContabile == "In regola" || statoContabile == "Insolvente") &&
                                        <button className="btn btn-light" onClick={statoContabile == "In regola" ? () => { apriBloccaSbloccaPazienteModal(true) } : (statoContabile == "Insolvente" ? () => { apriBloccaSbloccaPazienteModal(false) } : () => { })}>
                                            <FeatherIcon addedClassName="align-middle" iconName={statoContabile == "In regola" ? "lock" : (statoContabile == "Insolvente" ? "unlock" : "")} />
                                            &nbsp;
                                            {statoContabile == "In regola" ? "Blocca manualmente" : (statoContabile == "Insolvente" ? "Sblocca manualmente" : "")}
                                        </button>
                                    }
                                    {(statoContabile == "Bloccato" || statoContabile == "Sbloccato") &&
                                        <button className="btn btn-light" onClick={statoContabile == "Bloccato" ? () => { apriBloccaSbloccaPazienteModal(false) } : (statoContabile == "Sbloccato" ? () => { apriBloccaSbloccaPazienteModal(true) } : () => { })}>
                                            <FeatherIcon addedClassName="align-middle" iconName={statoContabile == "Sbloccato" ? "lock" : (statoContabile == "Bloccato" ? "unlock" : "")} />
                                            &nbsp;
                                            {statoContabile == "Bloccato" ? "Sblocca manualmente" : (statoContabile == "Sbloccato" ? "Blocca manualmente" : "")}
                                        </button>
                                    }
                                </div>
                                {(statoContabile == "Bloccato" || statoContabile == "Sbloccato") &&
                                    <div className="d-inline-block p-1">
                                        <button className="btn btn-light" onClick={() => { setShowStatoAutomaticoModal(true); }}>
                                            <FeatherIcon addedClassName="align-middle" iconName="monitor" />
                                            &nbsp;
                                            Torna a uno stato automatico
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="row">
                {/*Informazioni*/}
                <div className="col-sm">
                    <div className="card" disabled={pageViewed == pages.MieInfo} style={{ pointerEvents: (pageViewed == pages.MieInfo) ? "none" : "auto", opacity: (pageViewed == pages.MieInfo) ? 0.7 : 1 }}>
                        <div className="card-header" onClick={() => { setInformazioniCardExpanded(!informazioniCardExpanded); }}>
                            <div className="d-flex flex-row">
                                <div className="flex-grow-1">
                                    <h5 className="card-title">Informazioni</h5>
                                </div>
                                <div>
                                    <FeatherIcon iconName={informazioniCardExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
                                </div>
                            </div>
                        </div>
                        {informazioniCardExpanded &&
                            <div className="card-body">
                                {campiInformazioni.map((campo, index) => {
                                    return (
                                        <div key={index}>
                                            <label htmlFor={campo} className="card-subtitle text-muted">{primaLetteraMaiuscola(campo)}{props.professionistaPazFormObbligatori[campo] == true ? " *" : ""}</label>
                                            <input type="text" id={campo} name={campo} className={"form-control mb-1 " + (props.professionistaPazFormOk[campo] ? "" : "is-invalid")} value={professionistaPazFormValues[campo]} onChange={handleChange} maxLength={getMaxCaratteriCampo(campo)} />
                                            <div className="invalid-feedback">
                                                {props.professionistaPazFormObbligatori[campo] == true && "Questo campo deve avere una lunghezza tra 2 e " + getMaxCaratteriCampo(campo) + " caratteri e avere il formato richiesto"}
                                                {props.professionistaPazFormObbligatori[campo] == false && "Questo campo deve avere una lunghezza non superiore a " + getMaxCaratteriCampo(campo) + " caratteri e avere il formato richiesto"}
                                            </div>
                                        </div>);
                                })}
                                {paziente == false && !(pageViewed === pages.MieInfo && loggedUser.tipoUtente != "professionista") && (
                                    <div>
                                        <label htmlFor="modalitaSedute" className="card-subtitle text-muted">Modalità sedute</label>
                                        <select className="form-control" id="modalitaSedute" value={professionistaPazFormValues.modalita} onChange={handleModalitaSedutaChange}>
                                            <option value="0">Solo in presenza</option>
                                            <option value="1">Solo online</option>
                                            <option value="2">Online e in presenza</option>
                                        </select>
                                    </div>
                                )}
                                <div>
                                    <label htmlFor="genere" className="card-subtitle text-muted">Genere *</label>
                                    <select className="form-control" id="genere" name="genere" value={professionistaPazFormValues.genere} onChange={handleChange}>
                                        <option value="M">Maschio</option>
                                        <option value="F">Femmina</option>
                                    </select>
                                </div>
                                {!newmode &&
                                    <>
                                        <div className="mt-2">
                                            <label htmlFor="activeUserCheck">Utente attivo</label>
                                            <input type="checkbox" id="activeUserCheck" className="form-check-input m-1" name="attivo" checked={professionistaPazFormValues.attivo} onChange={handleChange} />
                                        </div>
                                    </>
                                }
                                {!paziente &&
                                    <>
                                        <div className="mt-2">
                                            <label htmlFor="coordinatoreCheck">Coordinatore (può vedere le disponibilità di tutti i professionisti)</label>
                                            <input type="checkbox" id="coordinatoreCheck" className="form-check-input m-1" name="coordinatore" checked={professionistaPazFormValues.coordinatore} onChange={handleChange} />
                                        </div>
                                    </>
                                }
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                    <div>
                                        <p className="text-danger mb-0"></p>
                                    </div>
                                    <div>
                                        <p className="mb-0">* Campo obbligatorio</p>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </div>
                {/* Residenza*/}
                <div className="col-sm">
                    <div className="card" disabled={pageViewed == pages.MieInfo} style={{ pointerEvents: (pageViewed == pages.MieInfo) ? "none" : "auto", opacity: (pageViewed == pages.MieInfo) ? 0.7 : 1 }}>
                        <div className="card-header" onClick={() => { setResidenzaCardExpanded(!residenzaCardExpanded); }}>
                            <div className="d-flex flex-row">
                                <div className="flex-grow-1">
                                    <h5 className="card-title">Residenza</h5>
                                </div>
                                <div>
                                    <FeatherIcon iconName={residenzaCardExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
                                </div>
                            </div>
                        </div>
                        {residenzaCardExpanded &&
                            <div className="card-body">
                                <label>Paese</label>
                                <div className="w-full mb-1"></div>
                                <div className="w-full">
                                    <button className={"btn " + (soloItalia ? "btn-primary" : "btn-light")} style={{ borderRadius: "5px 0px 0px 5px" }} onClick={() => { setProfessionistaPazFormValues(prevState => ({ ...prevState, paese: "Italia", comune: "", cap: "", provincia: "", indirizzo: "" })); setSelectedComune([]); setCities([]); setRicercaComune(""); setSoloItalia(true); }}>Italia</button>
                                    <button className={"btn " + (!soloItalia ? "btn-primary" : "btn-light")} style={{ borderRadius: "0px 5px 5px 0px" }} onClick={() => { setProfessionistaPazFormValues(prevState => ({ ...prevState, paese: "", comune: "", cap: "", provincia: "", indirizzo: "" })); setSoloItalia(false); }}>Altro paese</button>
                                </div>
                                {soloItalia &&
                                    <>
                                        {loadingCities && <p><span><Loader small={true} /></span> Caricamento della lista dei comuni italiani in corso...</p>}
                                        {!loadingCities && <p>&nbsp;</p>}
                                        <input type="text" className="form-control" placeholder="Cerca" value={ricercaComune} onChange={(event) => { setRicercaComune(event.target.value); }} />
                                        <SearchAndSelect elements={cities} oneElementName={"un comune"} allowMultiple={false} showDeselectButton={false} validSelection={comuneValido} setValidSelection={setComuneValido} required={true} selectedOptions={selectedComune} setSelectedOptions={setSelectedComune} hideBottomText={false} hideSearchText={true} disabled={false} getElementText={(element) => { return element.city + " (" + element.postal_code + ", " + element.province + ")"; }} filterFunction={(element) => { return element.city.toLowerCase().includes(ricercaComune.toLowerCase()); }} />

                                    </>
                                }
                                {!soloItalia &&
                                    <>
                                        <PaeseEstero paese={professionistaPazFormValues.paese} setPaese={(paese) => { setProfessionistaPazFormValues(prevState => ({ ...prevState, paese: paese })); }} />
                                    </>
                                }
                                {campiResidenza.map((campo, index) => {
                                    return (
                                        <div key={index}>
                                            <label htmlFor={campo} className="card-subtitle text-muted">{primaLetteraMaiuscola(campo)}{props.professionistaPazFormObbligatori[campo] == true ? " *" : ""}</label>
                                            <input type="text" id={campo} name={campo} className={"form-control mb-1 " + (props.professionistaPazFormOk[campo] ? "" : "is-invalid")} value={professionistaPazFormValues[campo]} onChange={handleChange} maxLength={getMaxCaratteriCampo(campo)} />
                                            <div className="invalid-feedback">
                                                {props.professionistaPazFormObbligatori[campo] == true && "Questo campo deve avere una lunghezza tra 2 e " + getMaxCaratteriCampo(campo) + " caratteri e avere il formato richiesto"}
                                                {props.professionistaPazFormObbligatori[campo] == false && "Questo campo deve avere una lunghezza non superiore a " + getMaxCaratteriCampo(campo) + " caratteri e avere il formato richiesto"}
                                            </div>
                                        </div>);
                                })}
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                    <div>
                                        <p className="text-danger mb-0"></p>
                                    </div>
                                    <div>
                                        <p className="mb-0">* Campo obbligatorio</p>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {/*Sezione password*/}
                {pageViewed === pages.MieInfo &&
                    <AggiornaMiaPassword passwordFormatIsOk={props.passwordFormatIsOk} passwordSpecialChars={props.passwordSpecialChars} />
                }
            </div>
            <div className="row">
                {/*Sezione per le email di comunicazione*/}
                {professionistaPazCreateUpdateViewed && !(pageViewed == pages.MieInfo && loggedUser.tipoUtente == "segretario") &&
                    <EmailComunicazioni paginaMieInfo={pageViewed === pages.MieInfo} emailComunicazioni={props.emailComunicazioni} setEmailComunicazioni={props.setEmailComunicazioni} emailFormatOk={emailFormatOk} paziente={paziente} emailComunicazioniResetted={emailComunicazioniResetted} setEmailComunicazioniResetted={setEmailComunicazioniResetted} emailInformazioni={professionistaPazFormValues.email} setEmailComunicazioniBeforeEdits={props.setEmailComunicazioniBeforeEdits} cardExpanded={emailCardExpanded} setCardExpanded={setEmailCardExpanded} />
                }
                {/*Sezione per mettere altri numeri di telefono*/}
                {professionistaPazCreateUpdateViewed &&
                    <AltriNumeriTelefono setAltriNumeriValidi={setAltriNumeriValidi} paginaMieInfo={pageViewed === pages.MieInfo} cardExpanded={numeriTelefonoCardExpanded} setCardExpanded={setNumeriTelefonoCardExpanded} />
                }
                {paziente &&
                    <div className="col-sm">
                        <div className="card">
                            <div className="card-header" onClick={() => { setStsCardExpanded(!stsCardExpanded); }}>
                                <div className="d-flex flex-row">
                                    <div className="flex-grow-1">
                                        <h5 className="card-title">Consenso invio dati all'STS</h5>
                                    </div>
                                    <div>
                                        <FeatherIcon iconName={stsCardExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
                                    </div>
                                </div>
                            </div>
                            {stsCardExpanded &&
                                <div className="card-body">
                                    <input type="checkbox" id="consensoSts" className="form-check-input m-1" name="consensoSts" checked={!professionistaPazFormValues.consensoSts} onChange={handleConsensoSTSChange} />
                                    <label htmlFor="consensoSts">Il paziente si oppone all'invio dei propri dati al Sistema Tessera Sanitaria</label>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="row">
                {/*Assegnazione dei pazienti/professionisti */}
                {((loggedUser.tipoUtente == "segretario" && pageViewed != pages.MieInfo) || (loggedUser.tipoUtente == "professionista" && pageViewed == pages.MieInfo)) &&
                    <div className="col-lg">
                        <div className="card">
                            <div className="card-header">
                                {paziente == true ? (
                                    <h5 className="card-title">Professionisti</h5>
                                ) : (
                                    <h5 className="card-title">Pazienti</h5>
                                )}
                            </div>
                            <div className="card-body">
                                {/*Pazienti/professionisti assegnati */}
                                {paziente == false &&
                                    <SearchAndSelect title={"Assegnati"} viewedVariable={"assigned"} elements={listaPazienti} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedPazienti} setSelectedOptions={setSelectedPazienti} hideBottomText={true} getElementText={getProfessionistaPazText} />
                                }
                                {paziente == true &&
                                    <SearchAndSelect title={"Assegnati"} viewedVariable={"assigned"} elements={listaProfessionisti} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} hideBottomText={true} getElementText={getProfessionistaPazText} />
                                }
                                {/*Pulsanti Assegna/Rimuovi*/}
                                {pageViewed != pages.MieInfo &&
                                    <section>
                                        <button className="btn btn-secondary m-1" disabled={assegnaProfessionistaPazDisabled} onClick={paziente ? () => { assegnazioneProfessionista(true); } : () => { assegnazionePaziente(true); }} >Assegna</button>
                                        <button className="btn btn-secondary m-1" disabled={rimuoviProfessionistaPazDisabled} onClick={paziente ? () => { assegnazioneProfessionista(false); } : () => { assegnazionePaziente(false); }}>Rimuovi</button>
                                        <button className="btn btn-secondary m-1" disabled={programmaSedutaDisabled} onClick={showSedutaProgrammataModal} style={{ display: "inline" }}>Programma seduta settimanale</button>
                                    </section>
                                }
                                {/*Pazienti/professionisti non assegnati assegnati */}
                                {paziente == false &&
                                    <SearchAndSelect title={"Non assegnati"} viewedVariable={"notAssigned"} elements={listaPazienti} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedPazienti} setSelectedOptions={setSelectedPazienti} hideBottomText={true} getElementText={getProfessionistaPazText} />
                                }
                                {paziente == true &&
                                    <SearchAndSelect title={"Non assegnati"} viewedVariable={"notAssigned"} elements={listaProfessionisti} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} hideBottomText={true} getElementText={getProfessionistaPazText} />
                                }
                            </div>
                        </div>
                    </div>
                }
                {((paziente == false && loggedUser.tipoUtente == "segretario" && pageViewed != pages.MieInfo) || (loggedUser.tipoUtente == "professionista" && pageViewed == pages.MieInfo)) &&
                    /*Assegnazione dei servizi */
                    <div className="col-lg">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Servizi offerti</h5>
                            </div>
                            <div className="card-body">
                                {/* Servizi assegnati*/}
                                <SearchAndSelect title={"Assegnati"} viewedVariable={"assigned"} elements={listaServizi} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedServizi} setSelectedOptions={setSelectedServizi} hideBottomText={true} getElementText={getServizioText} />
                                {/*Pulsanti Assegna/Rimuovi e imposta tariffa*/}
                                {pageViewed != pages.MieInfo &&
                                    <section>
                                        <button className="btn btn-secondary m-1" disabled={assegnaServizioDisabled} onClick={() => { assegnazioneServizio(true); }} >Assegna</button>
                                        <button className="btn btn-secondary m-1" disabled={rimuoviServizioDisabled} onClick={() => { assegnazioneServizio(false); }} >Rimuovi</button>
                                        <button className="btn btn-secondary m-1" disabled={impostaTariffaDisabled} onClick={showPrezzoServizioModal} style={{ display: "inline" }}>Imposta tariffa</button>
                                    </section>
                                }
                                {/* Servizi non assegnati*/}
                                <SearchAndSelect title={"Non assegnati"} viewedVariable={"notAssigned"} elements={listaServizi} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedServizi} setSelectedOptions={setSelectedServizi} hideBottomText={true} getElementText={getServizioText} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/*Pulsanti Salva e Elimina*/}
            {pageViewed != pages.MieInfo &&
                <div className="row">
                    <div className="card">
                        <div className="card-body d-flex flex-row justify-content-between" style={{ flexWrap: "wrap" }}>

                            <div>
                                <APICallButton buttonClassName="btn btn-primary m-1" buttonOnClick={saveProfessionistaPaz} buttonText={newmode == false ? "Salva modifiche" : (paziente == true ? "Crea nuovo paziente" : "Crea nuovo professionista")} nomeProcedura={paziente == true ? "il salvataggio del paziente" : "il salvataggio del professionista"} />
                            </div>
                            {/*{newmode == false && <button className="btn btn-danger m-1" onClick={showConfirmModal}>Disattiva {paziente ? "paziente" : "professionista"}</button>}*/}
                            {newmode && paziente &&
                                <div className="m-1">
                                    <input id="inviaAncheEmailOnboarding" type="checkbox" className="form-check-input" checked={inviaAncheEmailOnboarding} onChange={(event) => { setInviaAncheEmailOnboarding(event.target.checked); }} />
                                    <label htmlFor="inviaAncheEmailOnboarding" className="ms-1">Invia anche email di onboarding</label>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            }
            {/*Modal*/}
            <PrezzoServizioModalDialog showmodal={isShowPrezzoServizioModal} closemodal={handleClosePrezzoServizioModal} listaServizi={listaServizi} setListaServizi={setListaServizi} selectedServizi={selectedServizi} nome={professionistaPazFormValues.nomeOld} cognome={professionistaPazFormValues.cognomeOld} />
            <ModalSedutaProgrammata showmodal={isShowSedutaProgrammataModal} closemodal={handleCloseSedutaProgrammataModal} users={paziente ? listaProfessionisti : listaPazienti} selectedId={paziente ? selectedProfessionisti : selectedPazienti} setUsers={paziente ? setListaProfessionisti : setListaPazienti} nomeCognomePaziente={paziente ? professionistaPazFormValues.nome + " " + professionistaPazFormValues.cognome : undefined} nomeCognomeProfessionista={!paziente ? professionistaPazFormValues.nome + " " + professionistaPazFormValues.cognome : undefined} />
            <ModalPrompt showmodal={isShowInvioEmailModal} closemodal={() => { setShowInvioEmailModal(false); }} okButtonFunction={sendPasswordSetEmail} okButtonColor={"primary"} okButtonText={"Sì"} title={"Conferma richiesta nuova password"} nomeProcedura={"l'invio dell'email per impostare una nuova password"}>
                <p>Vuoi richiedere una nuova password per il professionista {professionistaPazFormValues.nomeOld} {professionistaPazFormValues.cognomeOld}?</p>
            </ModalPrompt>
            <ModalPrompt showmodal={isShowStatoAutomaticoModal} closemodal={() => { setShowStatoAutomaticoModal(false); }} okButtonFunction={passaAStatoAutomatico} okButtonColor={"primary"} okButtonText={"Sì" + (tickIndexScansioneFatture != null && percentualeCompletamentoScansioneFatture != null && percentualeCompletamentoScansioneFatture != undefined && percentualeCompletamentoScansioneFatture != 100 ? " (" + percentualeCompletamentoScansioneFatture.toFixed(2) + "%)" : "")} title={"Torna a uno stato automatico"} nomeProcedura={"l'impostazione di uno stato automatico per il paziente e il controllo dei pagamenti delle fatture"}>
                <p>Da questo momento, il gestionale imposterà automaticamente lo stato di {professionistaPazFormValues.nomeOld} {professionistaPazFormValues.cognomeOld} a <b className="text-success">In regola</b> o <b className="text-danger">Insolvente</b>. Continuare?</p>
            </ModalPrompt>
            <ModalPrompt showmodal={isShowBloccaPazienteModal} closemodal={() => { setShowBloccaPazienteModal(false); }} okButtonFunction={() => { bloccaSbloccaPaziente(true); }} okButtonColor={"primary"} okButtonText={"Sì"} title={"Blocca " + professionistaPazFormValues.nomeOld + " " + professionistaPazFormValues.cognomeOld} nomeProcedura={"l'impostazione dello stato 'Bloccato' per il paziente"}>
                <p>Da questo momento, lo stato di {professionistaPazFormValues.nomeOld} {professionistaPazFormValues.cognomeOld} sarà impostato come <b className="text-danger">Bloccato</b> e il gestionale non lo modificherà più automaticamente. Sarà sempre possibile tornare a uno stato automatico. Continuare?</p>
            </ModalPrompt>
            <ModalPrompt showmodal={isShowSbloccaPazienteModal} closemodal={() => { setShowSbloccaPazienteModal(false); }} okButtonFunction={() => { bloccaSbloccaPaziente(false); }} okButtonColor={"primary"} okButtonText={"Sì"} title={"Sblocca " + professionistaPazFormValues.nomeOld + " " + professionistaPazFormValues.cognomeOld} nomeProcedura={"l'impostazione dello stato 'Bloccato' per il paziente"}>
                <p>Da questo momento, lo stato di {professionistaPazFormValues.nomeOld} {professionistaPazFormValues.cognomeOld} sarà impostato come <b className="text-success">Sbloccato</b> e il gestionale non lo modificherà più automaticamente. Sarà sempre possibile tornare a uno stato automatico. Continuare?</p>
            </ModalPrompt>
        </div>
    );
}

export default ProfessionistaPazCreateUpdate;
import { useState, useEffect } from "react";
import FiltraModalDialog from "../modals/FiltraModalDialog";
import BackendDataTable from "../BackendDataTable";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import Heading from "../Heading";
function ListaProfessionistaPaz(props) {
	const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
	//dice se mostrare o meno il modal dei filtri
	const [isShowModal, setShowModal] = useState(false);
	//dice se la pagina visualizzata è la lista dei professionisti/pazienti
	const listaProfessionistaPazViewed = pageViewed === pages.Pazienti || pageViewed === pages.Professionisti;
	//dice se la pagina visualizzata è la lista dei pazienti
	const pazienti = pageViewed === pages.Pazienti;
	const [filtersActive, setFiltersActive] = useState(false);
	const initialFilters = {
		nome: "",
		cognome: "",
		email: "",
		cf: "",
		id: "",
		attivi: true,
		nonAttivi: false,
		pazienteAssegnato: undefined,
		professionistaAssegnato: undefined,
		servizioOfferto: undefined
	}
	//l'url della chiamata API usata nella tabella dei pazienti
	const [pazientiReqUrl, setPazientiReqUrl] = useState(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=paziente");
	const [pazientiProfessionistaReqUrl, setPazientiProfessionistaReqUrl] = useState(process.env.REACT_APP_API_URL + "/mieiPazienti?soloAttivi=true");
	//l'url della chiamata API usata nella tabella dei professionisti
	const [professionistiReqUrl, setProfessionistiReqUrl] = useState(process.env.REACT_APP_API_URL + "/professionistapaz/tutti?ordineAlfabetico=true&tipoUtente=professionista");
	//i filtri scelti dall'utente per decidere quali dati mostrare nella tabella dei pazienti/professionisti
	const [filters, setFilters] = useState(initialFilters);
	//i tipi di dati nelle colonne delle due datatable
	const columnsDataTypes = {}
	const columnsColorsFunctionsPazienti = [];
	//funzioni di trasformazione dei valori nelle due datatable
	let functionsOnColumns = [];
	functionsOnColumns["modalita"] = (val) => {
		if (val == 0) {
			return "Solo in presenza";
		} else if (val == 1) {
			return "Solo online";
		} else if (val == 2) {
			return "Online e in presenza";
		}
	};
	functionsOnColumns["attivo"] = (val) => {
		if (val == 1) {
			return "Sì";
		} else {
			return "No";
		}
	}
	functionsOnColumns["consensoSts"] = (val) => {
		if (val == 1) {
			return "Sì";
		} else {
			return "No";
		}
	}
	functionsOnColumns["coordinatore"] = (val) => {
		if (val == 1) {
			return "Sì";
		} else {
			return "No";
		}
	}
	functionsOnColumns["genere"] = (val) => {
		if (val == "M") {
			return "Maschio";
		} else if (val == "F") {
			return "Femmina";
		} else {
			return "Non specificato";
		}
	}
	functionsOnColumns["associatoAFattureInCloud"] = (val) => {
		if (val != null) {
			return "Sì";
		} else {
			return "No";
		}
	}
	columnsColorsFunctionsPazienti["stato"] = (val) => {
		if(val == "Insolvente" || val == "Bloccato"){
			return "#ff0000";
		}else{
			return undefined;
		}
	}
	//reimposta i filtri ogni volta che si entra in questa pagina
	useEffect(() => {
		if (listaProfessionistaPazViewed) {
			if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
				console.log("useEffect: reset filtri");
			}
			setFilters(initialFilters);
		}
	}, [pageViewed]);

	function showModal() {
		checkUserLoggedBeforeDoing(() => {
			setShowModal(true);
		});
	}
	function handleCloseModal() {
		setShowModal(false);
	}

	//quando vengono cambiati i filtri, aggiorna anche l'url della chiamata API per la tabella 
	useEffect(updateTable, [filters]);
	useEffect(() => {
		if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
			console.log("useEffect: filtri modificati. cambio url chiamataAPI BackendDataTable");
		}
		setFiltersActive(false);
		let keys = Object.keys(filters);
		for (let i = 0; i < keys.length; i++) {
			if (filters[keys[i]] != initialFilters[keys[i]]) {
				setFiltersActive(true);
				break;
			}
		}
	}, [filters]);

	function updateTable() {
		//funzione chiamata per aggiornare la tabella quando cambiano i filtri
		if (pazienti) {
			//aggiorna la tabella dei pazienti
			let newUrl = process.env.REACT_APP_API_URL + "/pazienti/filtra?nome=" + filters.nome + "&cognome=" + filters.cognome + "&email=" + filters.email + "&cf=" + filters.cf + "&attivi=" + filters.attivi + "&nonAttivi=" + filters.nonAttivi;
			if (filters.professionistaAssegnato != undefined) {
				newUrl += "&selectedProfessionista=" + filters.professionistaAssegnato;
			}
			setPazientiReqUrl(newUrl);
		} else {
			//aggiorna la tabella dei professionisti
			let newUrl = process.env.REACT_APP_API_URL + "/professionisti/filtra?nome=" + filters.nome + "&cognome=" + filters.cognome + "&email=" + filters.email + "&cf=" + filters.cf + "&attivi=" + filters.attivi + "&nonAttivi=" + filters.nonAttivi;
			if (filters.pazienteAssegnato != undefined) {
				newUrl += "&selectedPaziente=" + filters.pazienteAssegnato;
			}
			if (filters.servizioOfferto != undefined) {
				newUrl += "&selectedServizio=" + filters.servizioOfferto;
			}
			setProfessionistiReqUrl(newUrl);
		}
	}

	//vai alla pagina di creazione di un nuovo paziente/professionista
	function goToNewPage() {
		checkUserLoggedBeforeDoing(() => {
			if (pazienti == true) {
				setProfessionistaPazFormValues(initialProfessionistaPazFormValues); setPageViewed(pages.NuovoPaziente); cambiaUrl(pages.NuovoPaziente);
			} else {
				setProfessionistaPazFormValues(initialProfessionistaPazFormValues); setPageViewed(pages.NuovoProfessionista); cambiaUrl(pages.NuovoProfessionista);
			}
		});
	}

	//funzione di click della riga passata alla dataTable dei pazienti
	function listaPazientiRowClick(paziente) {
		checkUserLoggedBeforeDoing(() => {
			setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/numeriTelefono?idUtente=" + paziente.idUtente)
				.then(response => {
					if (response.status == 200) {
						let altriNumeriTelefono = response.data.dbdata;
						setProfessionistaPazFormValues(prevState => ({
							...prevState,
							nome: paziente.nome,
							nomeOld: paziente.nome,
							cognome: paziente.cognome,
							cognomeOld: paziente.cognome,
							email: paziente.email,
							cf: paziente.cf,
							idOld: paziente.idUtente,
							id: paziente.idUtente,
							attivo: paziente.attivo == "Sì",
							modalita: onlinePresenzaNum(paziente.modalita),
							indirizzo: paziente.indirizzo,
							comune: paziente.comune,
							provincia: paziente.provincia,
							cap: paziente.cap,
							paese: paziente.paese,
							altriNumeriTelefono: altriNumeriTelefono,
							consensoSts: paziente.consensoSts == "Sì",
							genere: daGenereASigla(paziente.genere),
							coordinatore: false
						}));
						setPageViewed(pages.Paziente);
					} else {
						genericAlert("Impossibile recuperare i numeri di telefono del paziente. Riprova più tardi.");
					}
				})
				.catch(error => {
					handleAPIError(error, "recuperare i numeri di telefono del paziente");
				})
				.finally(() => {
					setLoading(false);
				});
		});

	}

	function daGenereASigla(genere) {
		if (genere == "Maschio") {
			return "M";
		} else if (genere == "Femmina") {
			return "F";
		} else {
			return null;
		}
	}

	//Converte la stringa dell'attributo modalita in numero
	function onlinePresenzaNum(str) {
		switch (str) {
			case "Solo in presenza":
				return 0;
			case "Solo online":
				return 1;
			case "Online e in presenza":
				return 2;
		}
	}

	//funzione di click della riga passata alla dataTable dei professionisti
	function listaProfessionistiRowClick(professionista) {
		checkUserLoggedBeforeDoing(() => {
			setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/numeriTelefono?idUtente=" + professionista.idUtente)
				.then(response => {
					if (response.status == 200) {
						let altriNumeriTelefono = response.data.dbdata;
						setProfessionistaPazFormValues(prevState => ({
							...prevState,
							nome: professionista.nome,
							nomeOld: professionista.nome,
							cognome: professionista.cognome,
							cognomeOld: professionista.cognome,
							email: professionista.email,
							cf: professionista.cf,
							idOld: professionista.idUtente,
							id: professionista.idUtente,
							attivo: professionista.attivo == "Sì",
							modalita: onlinePresenzaNum(professionista.modalita),
							indirizzo: professionista.indirizzo,
							comune: professionista.comune,
							provincia: professionista.provincia,
							cap: professionista.cap,
							paese: professionista.paese,
							altriNumeriTelefono: altriNumeriTelefono,
							genere: daGenereASigla(professionista.genere),
							coordinatore: professionista.coordinatore == "Sì"
						}));
						setPageViewed(pages.Professionista);
					} else {
						genericAlert("Impossibile recuperare i numeri di telefono del professionista. Riprova più tardi.");
					}
				})
				.catch(error => {
					handleAPIError(error, "recuperare i numeri di telefono del professionista");
				})
				.finally(() => {
					setLoading(false);
				});
		});
	}

	function handlePazientiAttiviCheckChange(event) {
		if (event.target.checked == true) {
			setPazientiProfessionistaReqUrl(process.env.REACT_APP_API_URL + "/mieiPazienti?soloAttivi=true");
		} else {
			setPazientiProfessionistaReqUrl(process.env.REACT_APP_API_URL + "/mieiPazienti?soloAttivi=false");
		}
	}

	return (
		<div style={{ display: listaProfessionistaPazViewed ? 'block' : 'none' }}>
			<Heading title={pazienti ? (loggedUser.tipoUtente == "professionista" ? "I miei pazienti" : "Lista pazienti") : ("Lista professionisti")} iconName="users" />
			{/*Div contenente i due pulsanti NUOVO e FILTRI */}
			{loggedUser.tipoUtente == "segretario" &&
				<div className="row">
					<div className="col-12">
						<div className="card">
							<div className="card-body">
								<button onClick={goToNewPage} className="btn btn-primary btn-lg me-sm-2 m-1" >
									{pazienti ? ("Nuovo Paziente") : ("Nuovo professionista")}
								</button>
								<button className="btn btn-primary btn-lg m-1" onClick={showModal}>Filtri</button>
								<button className={"btn " + (filtersActive ? "btn-warning" : "btn-secondary") + " btn-lg m-1"} disabled={!filtersActive} onClick={() => { setFilters(initialFilters); }}>Reimposta filtri</button>
							</div>
						</div>
					</div>
				</div>
			}
			{/*Div centrale contenente la tabella*/}
			<div className="row mainElementHeightContainer" >
				<div className="col-md mh-100">
					<div className="backendDataTableContainer">
						{loggedUser.tipoUtente == "professionista" &&
							<div>
								<input type="checkbox" className="form-check-input m-1" checked={pazientiProfessionistaReqUrl.slice(-4) == "true"} onChange={handlePazientiAttiviCheckChange} />
								<label htmlFor="soloPazientiProfessionistaAttiviCheck">Mostra solo pazienti attivi</label>
							</div>
						}
						{loggedUser.tipoUtente == "segretario" &&
							<>
								<BackendDataTable fileName={"Pazienti"} rowClickFunction={listaPazientiRowClick} loadInsidePage={pages.Pazienti} tableId={"pazientiTable"} reqUrl={pazientiReqUrl} show={pazienti} columnsToExclude={["idUtente", "password", "segretarioAdmin", "segretarioPsico", "segretarioDisOrch", "modalita", "tipoUtente", "nome", "cognome", "coordinatore"]} functionsOnColumns={functionsOnColumns} columnsDataTypes={columnsDataTypes} columnsColorsFunctions={columnsColorsFunctionsPazienti} openThisIdSuddenly={openModifiedPaziente} idColumn={"idUtente"}/>
								<BackendDataTable fileName={"Professionisti"} rowClickFunction={listaProfessionistiRowClick} loadInsidePage={pages.Professionisti} tableId={"professionistiTable"} reqUrl={professionistiReqUrl} show={!pazienti} columnsToExclude={["idUtente", "password", "segretarioAdmin", "segretarioPsico", "segretarioDisOrch", "tipoUtente", "nome", "cognome", "consensoSts", "associatoAFattureInCloud","stato"]} functionsOnColumns={functionsOnColumns} columnsDataTypes={columnsDataTypes} openThisIdSuddenly={openModifiedProfessionista} idColumn={"idUtente"}/>
							</>
						}
						{loggedUser.tipoUtente == "professionista" &&
							<BackendDataTable fileName={"I miei pazienti"} rowClickFunction={() => { }} loadInsidePage={pages.Pazienti} tableId={"pazientiTable"} reqUrl={pazientiProfessionistaReqUrl} show={pazienti} columnsToExclude={["idUtente"]} functionsOnColumns={[]} columnsDataTypes={columnsDataTypes} columnsColorsFunctions={columnsColorsFunctionsPazienti}/>
						}
					</div>
				</div>
			</div>
			{/* Modal dei filtri*/}
			{loggedUser.tipoUtente == "segretario" &&
				<FiltraModalDialog showmodal={isShowModal} closemodal={handleCloseModal} pazienti={pazienti} filters={filters} setFilters={setFilters} />
			}
		</div>
	);
}

export default ListaProfessionistaPaz;
import React from 'react';
import { createPlugin } from '@fullcalendar/core';
import { v4 as uuidv4 } from 'uuid';
function CustomView(props) {
  console.log("CustomView ricaricata!");
  //VARIABILI CONFIGURAZIONE
  const businessHoursStart = 8; //deve essere intero
  const businessHoursEnd = 23; //deve essere intero
  //Misure
  const minCellWidth = 128;
  const minCellHeight = 24;
  const roomsRowHeight = 24;
  const roomsRowHeightPx = roomsRowHeight + "px";
  const hoursColumnWidth = 36;
  const borderWidth = 1;
  const disponibilitaNoAreaWidth = 128;
  const tooltipMargin = 12;
  const resizeScrollWait = 500;
  const eventLeftMargin = 4;
  const eventRightMargin = 8;
  //FINE VARIABILI CONFIGURAZIONE
  //ottieni i nomi delle aree
  let rooms = document.getElementById("areePerCustomView").innerHTML.slice(0, -1).split(";");
  let tableWidth = null;
  let tableHeight = null;
  let cellsWidth = null;
  let cellsHeight = null;
  //prendi tutte le istanze temporali degli eventi
  let eventsInstances = Object.values(props.eventStore.instances);
  //prendo il resto delle informazioni degli eventi
  let eventsDefs = Object.values(props.eventStore.defs);
  //aggiungi a ogni eventInstance tutte le informazioni dell'evento corrispondente, situate negli eventDefs
  function findEventDef(eventsDefs, eventInstance) {
    for (let i = 0; i < eventsDefs.length; i++) {
      if (eventsDefs[i].defId == eventInstance.defId) {
        return eventsDefs[i];
      }
    }
  }
  for (let i = 0; i < eventsInstances.length; i++) {
    eventsInstances[i].eventDef = JSON.parse(JSON.stringify(findEventDef(eventsDefs, eventsInstances[i])));
  }
  //-------------------------------------
  let tableCells = new Array((businessHoursEnd - businessHoursStart) * 2);
  for (let i = 0; i < (businessHoursEnd - businessHoursStart) * 2; i++) {
    tableCells[i] = new Array(rooms.length).fill();
  }
  //------------------------------------------
  const businessHoursStartString = businessHoursStart.toString().padStart(2, "0") + ":00";
  const businessHoursEndString = businessHoursEnd.toString().padStart(2, "0") + ":00";
  //z index
  const dispEventZIndex = 0;
  const internalCellsZIndex = 1;
  const normalEventZIndex = 2; //era 1
  const tableTopLeftZIndex = 3;
  let lMax = 0;
  let disponibilitaNoArea = [];
  let uniqueId = uuidv4();
  let deleted = false;
  //scrivi qui le ore da mostrare a sinistra
  let orariStrings = [];
  let ora = businessHoursStart; //businessHoursStart
  while (ora < businessHoursEnd) { //businessHoursEnd
    let oraStr = ora + "";
    if (ora < 10) {
      oraStr = "0" + oraStr;
    }
    orariStrings.push(oraStr);
    ora++;
  }
  //variabili necessarie per il tooltip
  let mouseX = 0;
  let mouseY = 0;
  let tooltipIsOnEventBlockNoArea = null;
  let tooltipIsOnEventIndex = null;
  //INIZIA IL CODICE CHE GESTISCE GLI EVENTI DI RIDIMENSIONAMENTO E SCROLL
  let resizeTimeout;
  window.addEventListener('resize', () => { clearTimeout(resizeTimeout); resizeTimeout = setTimeout(handleResize, resizeScrollWait); });
  function firstResize() {
    if (document.getElementById(uniqueId + "-events") != null) {
      setTimeout(handleResize, 1);
      setTimeout(handleResize, resizeScrollWait);
      setTimeout(addMouseMoveListener, resizeScrollWait);
      document.getElementById(uniqueId + "-events").scrollLeft = parseInt(document.getElementById("customViewScrollX").innerHTML);
      document.getElementById(uniqueId + "-events").scrollTop = parseInt(document.getElementById("customViewScrollY").innerHTML);
      return;
    }
    setTimeout(firstResize, 1);
  }
  firstResize();
  function saveTableScrollPosition() {
    let scrollX = document.getElementById(uniqueId + "-events").scrollLeft;
    let scrollY = document.getElementById(uniqueId + "-events").scrollTop;
    document.getElementById("customViewScrollX").innerHTML = scrollX;
    document.getElementById("customViewScrollY").innerHTML = scrollY;
  }
  //imposta la dimensione corretta delle colonne e delle righe della tabella in base alla larghezza e all'altezza del div
  function handleResize() {
    //console.log("Handle resize!");
    if (!deleted && document.getElementById(uniqueId + "-events") != null && document.getElementById(uniqueId + "-events").clientWidth != 0 && document.getElementById(uniqueId + "-events").clientHeight != 0) {
      let eventsDiv = document.getElementById(uniqueId + "-events");
      const divWidth = eventsDiv.clientWidth;
      const divHeight = eventsDiv.clientHeight;
      cellsWidth = Math.max(minCellWidth, (divWidth - hoursColumnWidth - 1) / (rooms.length + 0.0));
      tableWidth = Math.max(minCellWidth, (divWidth - hoursColumnWidth - 1) / (rooms.length + 0.0)) * (rooms.length + 0.0) + "px";
      cellsHeight = Math.max(minCellHeight, (divHeight - roomsRowHeight - 1) / ((businessHoursEnd - businessHoursStart) * 2.0));
      tableHeight = Math.max(minCellHeight, (divHeight - roomsRowHeight - 1) / ((businessHoursEnd - businessHoursStart) * 2.0)) * ((businessHoursEnd - businessHoursStart) * 2.0);
      let topMin = parseFloat(eventsDiv.scrollTop);
      let divTop = topMin + divHeight;
      //imposta la cellsWidth a tutti gli elementi di classe dynamicWidthCell
      let dynamicWidthCells = eventsDiv.getElementsByClassName("dynamicWidthCell");
      for (let i = 0; i < dynamicWidthCells.length; i++) {
        if (Array.from(dynamicWidthCells[i].classList).includes("eventBlock")) {
          dynamicWidthCells[i].style.width = (cellsWidth - borderWidth - eventLeftMargin - eventRightMargin) + "px";
        } else {
          dynamicWidthCells[i].style.width = cellsWidth + "px";
        }
      }
      //imposta la cellsHeight a tutti gli elementi di classe dynamicHeightCell (cioè solo le celle della tabella)
      let dynamicHeightCells = eventsDiv.getElementsByClassName("dynamicHeightCell");
      for (let i = 0; i < dynamicHeightCells.length; i++) {
        dynamicHeightCells[i].style.height = cellsHeight + "px";
      }
      eventsDiv.getElementsByClassName("scroll-table")[0].style.width = tableWidth;
      eventsDiv.style.height = tableHeight;
      //dai l'altezza giusta, il top giusto e il left giusto a tutti gli eventBlock
      let eventsBlocks = eventsDiv.getElementsByClassName("eventBlock");
      for (let i = 0; i < eventsBlocks.length; i++) {
        let idParts = eventsBlocks[i].id.split("-");
        let eventInstanceId = idParts[idParts.length - 1];
        let eventInstance = eventsInstances[eventInstanceId];
        if (eventInstance != undefined) {
          let oraInizioMostrata = (eventInstance.range.start.getUTCHours() < businessHoursStart || confrontaDate(eventInstance.range.start, props.dateProfile.currentRange.start) == -1 ? businessHoursStart : eventInstance.range.start.getUTCHours());
          let minutiInizioMostrati = (eventInstance.range.start.getUTCHours() < businessHoursStart || confrontaDate(eventInstance.range.start, props.dateProfile.currentRange.start) == -1 ? 0 : eventInstance.range.start.getUTCMinutes());
          let oraFineMostrata = (eventInstance.range.end.getUTCHours() >= businessHoursEnd || confrontaDate(eventInstance.range.end, props.dateProfile.currentRange.start) == 1 ? businessHoursEnd : eventInstance.range.end.getUTCHours());
          let minutiFineMostrati = (eventInstance.range.end.getUTCHours() >= businessHoursEnd || confrontaDate(eventInstance.range.end, props.dateProfile.currentRange.start) == 1 ? 0 : eventInstance.range.end.getUTCMinutes());
          let eventBlockLeft = (hoursColumnWidth + rooms.indexOf(eventInstance.eventDef.extendedProps.roomName) * cellsWidth + borderWidth);
          let eventBlockTop = Math.max(0, (roomsRowHeight + ((oraInizioMostrata - businessHoursStart) * cellsHeight * 2) + ((minutiInizioMostrati / 60.0) * cellsHeight * 2) + borderWidth));
          let eventBlockHeight = Math.max(0, (((oraFineMostrata - oraInizioMostrata + (minutiFineMostrati / 60.0) - (minutiInizioMostrati / 60.0)) * cellsHeight * 2) - borderWidth));
          eventsBlocks[i].style.left = eventBlockLeft + eventLeftMargin + "px";
          eventsBlocks[i].style.top = eventBlockTop + "px";
          eventsBlocks[i].style.height = eventBlockHeight + "px";
        }
      }
      //dai l'altezza giusta, il top giusto e il left giusto a tutti gli eventBlockNoArea
      let eventsBlocksNoArea = eventsDiv.getElementsByClassName("eventBlockNoArea");
      for (let i = 0; i < eventsBlocksNoArea.length; i++) {
        let idParts = eventsBlocksNoArea[i].id.split("-");
        let dispNoAreaId = idParts[idParts.length - 1];
        let dispNoArea = disponibilitaNoArea[dispNoAreaId];
        if (dispNoArea != undefined) {
          let oraInizio = parseInt(dispNoArea.oraInizio.substring(0, 2));
          let minutiInizio = parseInt(dispNoArea.oraInizio.substring(3, 5));
          let oraFine = parseInt(dispNoArea.oraFine.substring(0, 2));
          let minutiFine = parseInt(dispNoArea.oraFine.substring(3, 5));
          eventsBlocksNoArea[i].style.left = (hoursColumnWidth + rooms.length * cellsWidth) + borderWidth + (dispNoArea.livello * disponibilitaNoAreaWidth) + "px";
          eventsBlocksNoArea[i].style.top = (roomsRowHeight + ((oraInizio - businessHoursStart) * cellsHeight * 2) + ((minutiInizio / 60.0) * cellsHeight * 2) + borderWidth) + "px";
          eventsBlocksNoArea[i].style.height = (((oraFine - oraInizio + (minutiFine / 60.0) - (minutiInizio / 60.0)) * cellsHeight * 2) - borderWidth) + "px";
        }
      }
    } else {
      deleted = true;
    }
  }
  function addMouseMoveListener() {
    if (document.getElementById(uniqueId + "-events") != null) {
      document.getElementById(uniqueId + "-events").addEventListener('mousemove', (event) => { updateMousePosition(event); moveTooltip(); });
      document.getElementById(uniqueId + "-events").addEventListener('scroll', moveTooltip);
    } else {
      setTimeout(addMouseMoveListener, 10);
    }
  }
  function updateMousePosition(event) {
    mouseX = event.clientX;
    mouseY = event.clientY;
  }
  function moveTooltip() {
    let element = document.getElementById(uniqueId + "-events");
    let tooltip = document.getElementById(uniqueId + "-mouseTooltip");
    //imposta la posizione giusta al tooltip
    var x = mouseX - element.getBoundingClientRect().left + (document.getElementById(uniqueId + "-events").scrollLeft);
    var y = mouseY - element.getBoundingClientRect().top + (document.getElementById(uniqueId + "-events").scrollTop);
    document.getElementById(uniqueId + "-mouseTooltip").style.top = (y + tooltipMargin) + "px";
    document.getElementById(uniqueId + "-mouseTooltip").style.left = (x + tooltipMargin) + "px";
    let tooltipIsOnEventBlockNoArea_new = null;
    let tooltipIsOnEventIndex_new = null;
    //TODO:
    //determina se mostrarlo o meno e con quale testo
    //className deve essere eventBlock o eventBlockNoArea
    //quando il mouse è sopra di un eventBlock o un eventBlockNoArea, l'innerHTML del tooltip deve diventare lo stesso dell'eventBlock o dell'eventBlockNoArea (tranne per il colore del testo)
    //se non è sopra nessuno di questi, il tooltip deve passare a display: none
    let eventsDiv = document.getElementById(uniqueId + "-events");
    let eventBlocks = eventsDiv.getElementsByClassName("eventBlock");
    let eventBlocksNoArea = eventsDiv.getElementsByClassName("eventBlockNoArea");
    let found = false;
    for (let i = 0; i < eventBlocks.length; i++) {
      //Se il mouse è sopra un evento di disponibilità
      if (eventBlocks[i].style.zIndex == dispEventZIndex && parseInt(eventBlocks[i].style.left) <= x && parseInt(eventBlocks[i].style.left) + parseInt(eventBlocks[i].style.width) >= x && parseInt(eventBlocks[i].style.top) <= y && parseInt(eventBlocks[i].style.top) + parseInt(eventBlocks[i].style.height) >= y) {
        found = true;
        tooltipIsOnEventBlockNoArea_new = false;
        tooltipIsOnEventIndex_new = i;
        break;
      }
    }
    if (!found) {
      for (let i = 0; i < eventBlocksNoArea.length; i++) {
        //Se il mouse è sopra un evento di disponibilità
        if (eventBlocksNoArea[i].style.zIndex == dispEventZIndex && parseInt(eventBlocksNoArea[i].style.left) <= x && parseInt(eventBlocksNoArea[i].style.left) + parseInt(eventBlocksNoArea[i].style.width) >= x && parseInt(eventBlocksNoArea[i].style.top) <= y && parseInt(eventBlocksNoArea[i].style.top) + parseInt(eventBlocksNoArea[i].style.height) >= y) {
          found = true;
          tooltipIsOnEventBlockNoArea_new = true;
          tooltipIsOnEventIndex_new = i;
          break;
        }
      }
    }
    if (!found) {
      tooltip.style.display = "none";
    } else {
      if (tooltipIsOnEventBlockNoArea_new != tooltipIsOnEventBlockNoArea || tooltipIsOnEventIndex_new != tooltipIsOnEventIndex) {
        tooltip.style.display = "block";
        if (tooltipIsOnEventBlockNoArea_new == true) {
          tooltip.innerHTML = eventBlocksNoArea[tooltipIsOnEventIndex_new].innerHTML;
        } else {
          tooltip.innerHTML = eventBlocks[tooltipIsOnEventIndex_new].innerHTML;
        }
      }
    }
    tooltipIsOnEventBlockNoArea = tooltipIsOnEventBlockNoArea_new;
    tooltipIsOnEventIndex = tooltipIsOnEventIndex_new;
  }
  //FINISCE IL CODICE CHE GESTISCE GLI EVENTI DI RIDIMENSIONAMENTO E SCROLL
  //funzioni per confronto e conversioni delle date
  function dataFormatoAmericano(data) {
    return data.getUTCFullYear() + "-" + (data.getUTCMonth() + 1).toString().padStart(2, '0') + "-" + data.getUTCDate().toString().padStart(2, '0');
  }
  function dataFormatoAmericanoCompleta(data) {
    return data.getUTCFullYear() + "-" + (data.getUTCMonth() + 1).toString().padStart(2, '0') + "-" + data.getUTCDate().toString().padStart(2, '0') + " " + data.getUTCHours().toString().padStart(2, '0') + ":" + data.getUTCMinutes().toString().padStart(2, '0');
  }
  function dataFormatoItaliano(data) {
    return data.getUTCDate().toString() + "/" + (data.getUTCMonth() + 1).toString().padStart(2, '0') + "/" + data.getUTCFullYear();
  }
  //restituisce 1 se la prima data viene dopo la seconda
  //restituisce 0 se le due date sono uguali
  //restituisce -1 se la prima data viene prima della seconda
  function confrontaDate(data1, data2) {
    return dataFormatoAmericano(data1).localeCompare(dataFormatoAmericano(data2));
  }

  function oraMinutiString(data) {
    return data.getUTCHours().toString().padStart(2, '0') + ":" + data.getUTCMinutes().toString().padStart(2, '0');
  }

  //In questo FOR devi capire come inserire le disponibilità che non hanno l'area specificata
  for (let i = 0; i < eventsInstances.length; i++) {
    //considera l'evento solo se è di oggi, è una disponibilità e l'area non è specificata
    if (dataFormatoAmericanoCompleta(eventsInstances[i].range.end).localeCompare(dataFormatoAmericano(props.dateProfile.currentRange.start) + " " + businessHoursStartString) == 1 && dataFormatoAmericanoCompleta(eventsInstances[i].range.start).localeCompare(dataFormatoAmericano(props.dateProfile.currentRange.start) + " " + businessHoursEndString) == -1 && eventsInstances[i].eventDef.extendedProps.disponibilita == true && (eventsInstances[i].eventDef.extendedProps.room == undefined || eventsInstances[i].eventDef.extendedProps.room == null)) {
      let l = 0;
      let placed = false;
      while (!placed) {
        let oraInizio = oraMinutiString(eventsInstances[i].range.start);
        let oraFine = oraMinutiString(eventsInstances[i].range.end);
        //scorri tutto disponibilitaNoArea. 
        let brokenFor = false;
        for (let j = 0; j < disponibilitaNoArea.length; j++) {
          if (disponibilitaNoArea[j].oraInizio == oraInizio && disponibilitaNoArea[j].oraFine == oraFine) {
            //Se trovi un altro oggetto agli stessi orari, semplicemente "inglobi" l'evento aggiungendo il professionista
            if (!(disponibilitaNoArea[j].professionisti.includes(eventsInstances[i].eventDef.title) || (disponibilitaNoArea[j].idProfessionisti.includes(eventsInstances[i].eventDef.extendedProps.idProfessionista)))) {
              disponibilitaNoArea[j].professionisti.push(eventsInstances[i].eventDef.title);
              disponibilitaNoArea[j].idProfessionisti.push(eventsInstances[i].eventDef.extendedProps.idProfessionista);
            }
            placed = true;
            brokenFor = true;
            break;
          }
          //Se invece trovi un altro oggetto allo stesso livello ma con orari diversi e che si sovrappone, allora ...
          if (disponibilitaNoArea[j].livello == l && !(disponibilitaNoArea[j].oraInizio.localeCompare(oraFine) != -1 || disponibilitaNoArea[j].oraFine.localeCompare(oraInizio) != 1)) {
            l++;
            if (l > lMax) {
              lMax = l;
            }
            brokenFor = true;
            break;
          }
        }
        if (brokenFor == false) {
          //Se continuando a scorrere non trovi nessuno dei due casi sopra, aggiungi a disponibilitaNoArea un nuovo oggetto al livello corrente
          disponibilitaNoArea.push({
            livello: l,
            professionisti: [eventsInstances[i].eventDef.title], //title contiene il cognome del professionista
            idProfessionisti: [eventsInstances[i].eventDef.extendedProps.idProfessionista],
            oraInizio: oraInizio,
            oraFine: oraFine
          });
        }
      }
    }
  }

  return (
    <>
      <div className="scroll-table-div" id={uniqueId + "-events"} onScroll={saveTableScrollPosition}>
        {/* Metti qui la tabella visibile sotto gli eventi*/}
        <table className="scroll-table">
          <thead>
            <tr>
              <th className="first-aree-row border-bottom-darker headcol" style={{width: hoursColumnWidth + "px", height: roomsRowHeightPx, zIndex: tableTopLeftZIndex}}>&nbsp;</th>
              {rooms.map((room, index) => (
                <th key={index} className="first-aree-row dynamicWidthCell border-bottom-darker text-truncate" style={{ height: roomsRowHeightPx, zIndex: tableTopLeftZIndex }}>{room}</th>
              ))}
              {/*<th className="first-aree-row border-bottom-darker text-truncate" style={{ width: disponibilitaNoAreaWidth * (Math.max(2, lMax + 1)) + "px", height: roomsRowHeightPx, zIndex: tableTopLeftZIndex }}>Disponibilità senza area specificata</th>*/}
            </tr>
          </thead>
          <tbody>
            {tableCells.map((tableRow, indtr) => (
              <tr key={indtr}>
                <td style={{ width: hoursColumnWidth + "px", zIndex: tableTopLeftZIndex }} className={"bg-white dynamicHeightCell headcol " + (indtr % 2 == 0 ? "border-top-darker" : "border-bottom-darker")}>{indtr % 2 == 0 ? orariStrings[indtr / 2] : " "}</td>
                {tableRow.map((tableD, indtd) => {
                  return <td key={"clickableCell-" + indtr + "-" + indtd} className={"ov-hidden dynamicWidthCell dynamicHeightCell clickableCell hoverableCell " + (indtr % 2 == 0 ? "border-top-darker" : "border-bottom-darker")} id={"clickableCell-" + indtr + "-" + indtd + "-" + props.dateProfile.currentRange.start.getUTCFullYear() + "-" + (props.dateProfile.currentRange.start.getUTCMonth() + 1) + "-" + props.dateProfile.currentRange.start.getUTCDate()} style={{ height: roomsRowHeightPx, zIndex: internalCellsZIndex }}>&nbsp;</td>;
                })}
                {/*<td className={"ov-hidden dynamicHeightCell clickableCell hoverableCell " + (indtr % 2 == 0 ? "border-top-darker" : "border-bottom-darker")} id={"clickableCell-" + indtr + "-x-" + props.dateProfile.currentRange.start.getUTCFullYear() + "-" + (props.dateProfile.currentRange.start.getUTCMonth() + 1) + "-" + props.dateProfile.currentRange.start.getUTCDate()} style={{ height: roomsRowHeightPx, width: disponibilitaNoAreaWidth * (Math.max(2, lMax + 1)) + "px", zIndex: internalCellsZIndex }}>&nbsp;</td>*/}
              </tr>
            ))}
          </tbody>
        </table>
        {/* Metti qui tutti i div degli eventi, anche gli eventi background*/}
        {eventsInstances.map((eventInstance, index) => {
          //prendi gli eventi dentro un'area e corrispondenti al giorno selezionato
          if (eventInstance != undefined && eventInstance.eventDef.extendedProps.roomName != undefined && dataFormatoAmericanoCompleta(eventInstance.range.end).localeCompare(dataFormatoAmericano(props.dateProfile.currentRange.start) + " " + businessHoursStartString) == 1 && dataFormatoAmericanoCompleta(eventInstance.range.start).localeCompare(dataFormatoAmericano(props.dateProfile.currentRange.start) + " " + businessHoursEndString) == -1) {
            let eventBordersClass = "aree-event-normal-borders";
            let mostraDataOraInizioFine = true;
            let cutTopBorder = false;
            let cutBottomBorder = false;
            if (eventInstance.range.start.getUTCHours() < businessHoursStart || confrontaDate(eventInstance.range.start, props.dateProfile.currentRange.start) == -1) {
              cutTopBorder = true;
            }
            if (eventInstance.range.end.getUTCHours() >= businessHoursEnd || confrontaDate(eventInstance.range.end, props.dateProfile.currentRange.start) == 1) {
              cutBottomBorder = true;
            }
            if (cutTopBorder && cutBottomBorder) {
              eventBordersClass = "aree-event-both-borders-cut";
            } else if (cutTopBorder && !cutBottomBorder) {
              eventBordersClass = "aree-event-top-border-cut";
            } else if (!cutTopBorder && cutBottomBorder) {
              eventBordersClass = "aree-event-bottom-border-cut";
            } else {
              mostraDataOraInizioFine = false;
            }
            let eventTextBold = mostraDataOraInizioFine ?
              dataFormatoItaliano(eventInstance.range.start) + " " + (eventInstance.range.start.getUTCHours().toString().padStart(2, '0')) + ":" + (eventInstance.range.start.getUTCMinutes().toString().padStart(2, '0')) + " - " + dataFormatoItaliano(eventInstance.range.end) + " " + (eventInstance.range.end.getUTCHours().toString().padStart(2, '0')) + ":" + (eventInstance.range.end.getUTCMinutes().toString().padStart(2, '0'))
              :
              (
                eventInstance.eventDef.extendedProps.disponibilita == true ?
                  (eventInstance.range.start.getUTCHours().toString().padStart(2, '0')) + ":" + (eventInstance.range.start.getUTCMinutes().toString().padStart(2, '0')) + " - " + (eventInstance.range.end.getUTCHours().toString().padStart(2, '0')) + ":" + (eventInstance.range.end.getUTCMinutes().toString().padStart(2, '0'))
                  :
                  (eventInstance.range.start.getUTCHours().toString().padStart(2, '0')) + ":" + (eventInstance.range.start.getUTCMinutes().toString().padStart(2, '0'))
              );
            return <>
              <div key={uniqueId + "-event-" + index} id={uniqueId + "-event-" + index} className={eventBordersClass + " eventBlock ov-hidden dynamicWidthCell " + (eventInstance.eventDef.ui.display == "none" && eventInstance.eventDef.extendedProps.disponibilita != true ? "unclickableCell" : "clickableCell")} style={{ backgroundColor: (eventInstance.eventDef.extendedProps.disponibilita == true ? process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR : eventInstance.eventDef.ui.backgroundColor), color: (eventInstance.eventDef.extendedProps.disponibilita == true || eventInstance.eventDef.ui.display == "none" ? "#555555" : "white"), zIndex: (eventInstance.eventDef.extendedProps.disponibilita == true ? dispEventZIndex : normalEventZIndex), pointerEvents: (eventInstance.eventDef.extendedProps.disponibilita == true ? "none" : "auto")}}>
                <p className="mb-0 aree-event-text">
                  <b>{eventTextBold}</b> {eventInstance.eventDef.title}
                </p>
                <div className={"customViewOverlay " + (eventInstance.eventDef.ui.display == "none" && eventInstance.eventDef.extendedProps.disponibilita != true ? "unclickableCell" : "clickableCell")} id={"eventCell-" + eventInstance.eventDef.publicId}></div>
              </div>
            </>;
          }
        })}
        {disponibilitaNoArea.map((disp, index) => {
          let testoProfessionisti = "";
          for (let i = 0; i < disp.professionisti.length; i++) {
            testoProfessionisti += disp.professionisti[i] + (i == disp.professionisti.length - 1 ? "" : (i == disp.professionisti.length - 2 ? " e " : ", "));
          }
          return <>
            <div key={uniqueId + "-event-noarea-" + index} id={uniqueId + "-event-noarea-" + index} className={"ov-hidden aree-event-normal-borders eventBlockNoArea clickableCell"} style={{ width: disponibilitaNoAreaWidth + "px", backgroundColor: process.env.REACT_APP_DISPONIBILITA_PROFESSIONISTA_BGCOLOR, color: "#555555", zIndex: dispEventZIndex}}>
              <p className="mb-0 aree-event-text">
                <b>{disp.oraInizio} - {disp.oraFine}</b> {testoProfessionisti}
              </p>
              <div className={"customViewOverlay clickableCell"}></div>
            </div>
          </>;
        })}
        <div id={uniqueId + "-mouseTooltip"} className="aree-tooltip" style={{ zIndex: tableTopLeftZIndex}}>
        </div>
      </div>
    </>
  );
}
export default createPlugin({
  views: {
    Aree: CustomView
  }
});
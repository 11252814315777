import React from "react";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import { useEffect } from "react";
import { useState } from "react";
import Heading from "../Heading";
import ModalPrompt from "../modals/ModalPrompt";
function Servizi(props) {
    const tipoServizio = ["Con tariffa oraria", "Con tariffa a seduta", "Prepagabile (solo per certificazioni)"];
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    useEffect(() => {
        if (pageViewed == pages.Servizi) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: pagina lista servizi aperta");
            }
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/servizi?ancheNonAttivi=true&ordineAlfabetico=true")
                .then(response => {
                    if (response.status == 200) {
                        setListaServizi(response.data.dbdata);
                    } else {
                        genericAlert("Impossibile recuperare la lista dei servizi. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "recuperare la lista dei servizi");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [pageViewed]);
    const [soloServiziAttivi, setSoloServiziAttivi] = useState(true);
    const [isShowDisattivaServizioModal, setShowDisattivaServizioModal] = useState(false);
    const [idServizioSelezionato, setIdServizioSelezionato] = useState(undefined);
    const [nomeServizioSelezionato, setNomeServizioSelezionato] = useState("");
    const [disattivaServizioSelezionato, setDisattivaServizioSelezionato] = useState(false);
    function modificaServizio(id) {
        for (let i = 0; i < listaServizi.length; i++) {
            if (listaServizi[i].idServizio == id) {
                let servizioToUpdateFormValues = {
                    idServizioToUpdate: listaServizi[i].idServizio,
                    nomeServizio: listaServizi[i].nomeServizio,
                    descrizioneServizio: listaServizi[i].descrizioneServizio,
                    tipoServizio: listaServizi[i].tipoServizio,
                    tariffaDefault: listaServizi[i].tariffaDefault,
                    costoProfessionista: listaServizi[i].costoProfessionista
                }
                props.setServizioFormValues(servizioToUpdateFormValues);
                props.setServizioFormValuesBeforeEdits(JSON.parse(JSON.stringify(servizioToUpdateFormValues)));
                setPageViewed(pages.Servizio);
                break;
            }
        }
    }

    function showDisattivaServizioModal(idServizio, nomeServizio, disattivaServizio) {
        setIdServizioSelezionato(idServizio);
        setNomeServizioSelezionato(nomeServizio);
        setDisattivaServizioSelezionato(disattivaServizio);
        setShowDisattivaServizioModal(true);
    }

    function closeDisattivaServizioModal() {
        setShowDisattivaServizioModal(false);
    }

    //
    function attivaDisattivaServizio(id) {
        for (let i = 0; i < listaServizi.length; i++) {
            if (listaServizi[i].idServizio == id) {
                let reqBody = { attiva: listaServizi[i].attivo == false, idServizio: listaServizi[i].idServizio };
                setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/attivazioneservizio", reqBody)
                    .then(response => {
                        if (response.status == 200) {
                            genericAlert("Il servizio '" + listaServizi[i].nomeServizio + "' è stato " + (listaServizi[i].attivo ? "dis" : "") + "attivato");
                            if (listaServizi[i].attivo) {
                                listaServizi[i].attivo = false;
                            } else {
                                listaServizi[i].attivo = true;
                            }
                        } else {
                            genericAlert("Impossibile disattivare o attivare il servizio. Riprova più tardi.");
                        }
                        closeDisattivaServizioModal();
                    })
                    .catch(error => {
                        handleAPIError(error, "disattivare o attivare il servizio");
                        closeDisattivaServizioModal();
                    })
                    .finally(() => {
                        setLoading(false);
                    });
                break;
            }
        }
    }
    return (
        <>
            <div style={{ display: pageViewed === pages.Servizi ? 'block' : 'none' }}>
                <Heading title="Lista servizi" iconName="grid" />
                <div className="card">
                    <div className="card-header">
                        <h5 className="card-title">Servizi attualmente inseriti</h5>
                    </div>
                    <div className="card-body">
                        <label htmlFor="serviziSoloAttiviCheck">Mostra solo servizi attivi</label>
                        <input type="checkbox" id="serviziSoloAttiviCheck" className="form-check-input m-1" checked={soloServiziAttivi} onChange={(event) => { setSoloServiziAttivi(event.target.checked); }} />
                        {listaServizi.map((servizio, index) => {
                            if (!soloServiziAttivi || (soloServiziAttivi && servizio.attivo == true)) {
                                return <div key={index} className="servizioContainer">
                                    <h3 className="d-inline m-2 card-title">{servizio.nomeServizio} {servizio.descrizioneServizio.length > 0 ? "(" + servizio.descrizioneServizio + ")" : ""}</h3>
                                    <p className="d-inline m-2"><b>Tariffa default:</b> {importoConPuntoEVirgola(servizio.tariffaDefault)}</p>
                                    <p className="d-inline m-2"><b>Costo professionista:</b> {importoConPuntoEVirgola(servizio.costoProfessionista)}</p>
                                    <p className="d-inline m-2"><b>Tipo servizio:</b> {tipoServizio[servizio.tipoServizio]}</p>
                                    <div className="d-block mt-2">
                                        {servizio.attivo && <button className="btn btn-secondary m-1" onClick={() => { modificaServizio(servizio.idServizio); }}>Dettagli</button>}
                                        <button className={"btn " + (servizio.attivo ? "btn-danger" : "btn-primary") + " m-1"} onClick={() => { showDisattivaServizioModal(servizio.idServizio, servizio.nomeServizio, servizio.attivo); }}>{servizio.attivo ? "Disattiva" : "Riattiva"}</button>
                                    </div>
                                </div>;
                            } else {
                                return <></>;
                            }
                        })}
                    </div>
                </div>
            </div>
            <ModalPrompt showmodal={isShowDisattivaServizioModal} closemodal={closeDisattivaServizioModal} title={(disattivaServizioSelezionato ? "Disattivazione" : "Riattivazione") + " servizio"} okButtonColor={disattivaServizioSelezionato ? "danger" : "primary"} okButtonFunction={() => { attivaDisattivaServizio(idServizioSelezionato); }} okButtonText={disattivaServizioSelezionato ? "Disattiva" : "Riattiva"} nomeProcedura={disattivaServizioSelezionato ? "la disattivazione del servizio" : "la riattivazione del servizio"}>
                <p>Vuoi {disattivaServizioSelezionato ? "davvero disattivare" : "riattivare"} il servizio '{nomeServizioSelezionato}'?</p>
            </ModalPrompt>
        </>
    );
}

export default Servizi;
import React, { useEffect, useState } from 'react';
import './Login.css';
import axios from 'axios';
import { useContext } from 'react';
import { ContextGestionale } from '../App';
import APICallButton from '../components/APICallButton';
function Login(props) {
  const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState("");
  const [loadingLogin, setLoadingLogin] = useState(true);
  const [loginSuccesso, setLoginSuccesso] = useState(false);
  const paginaDesiderata = window.location.pathname !== "/login" ? window.location.pathname : "/dashboard";
  const [isShowPassword, setShowPassword] = useState(false);
  useEffect(() => {
    //TODO: bloccare questo useEffect se la modalità manutenzione è attiva
    if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
      console.log("useEffect: apertura gestionale (Login)");
    }
    //console.log("La pagina è stata già inserita nella history senza fare niente");
    setLoadingLogin(true);
    setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + '/isUserLoggedIn')
      .then(response => {
        if (response.status === 200) {
          const data = response.data;
          if (paginaDesiderata.substring(0, 8) !== "/signup-") {
            if (data.isUserLoggedIn === true) {
              //l'utente è loggato. vado all'ultima pagina che l'utente ha visitato
              setLoggedUser(data.loggedUser);
              setLoginSuccesso(true);
            }
          } else {
            setPageViewed(pages.ImpostazionePassword);
            if (data.isUserLoggedIn === true) {
              setLoggedUser(data.loggedUser);
            }
          }
        } else {
          setLoginError("Impossibile effettuare il login. Riprova più tardi.");
        }
        setLoadingLogin(false);
      })
      .catch(error => {
        setLoginError("ERRORE: Impossibile effettuare il login. Riprova più tardi.");
        setLoadingLogin(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //cancella l'errore di login all'apertura della pagina
  useEffect(() => {
    if (pageViewed === pages.Login) {
      if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
        console.log("useEffect: pagina login aperta");
      }
      setLoginError("");
      setLoginSuccesso(false);
    }
  }, [pageViewed]);

  //funzione chiamata quando l'utente chiede di entrare nel gestionale (ES: quando clicca su Entra)
  const handleLogin = (event) => {
    event.preventDefault();
    let loginEmail = email;
    let loginPassword = password;
    let reqbody = {
      email: loginEmail,
      password: loginPassword
    };
    setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + '/login', reqbody)
      .then(response => {
        let data = response.data;
        if (response.status === 200) {
          setLoggedUser(data.loggedUser);
          setLoginSuccesso(true);
        }
      })
      .catch(error => {
        if (error.response === undefined) {
          setLoginError("Impossibile effettuare il login. Riprova più tardi. Errore: " + error);
        } else {
          let data = error.response.data;
          if (data === "userOrPasswordIncorrect") {
            setLoginError("Nome utente o password non corretti.");
            setPassword("");
          } else if (data === "unableToCheckPassword") {
            setLoginError("Impossibile verificare la password. Riprova più tardi.");
          } else {
            setLoginError("Impossibile effettuare il login. Riprova più tardi. Errore: " + error);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (<div id="fullScreenLogin" style={{ display: pageViewed === pages.Login ? 'block' : 'none' }}>
    <main id="loginContainer" className="d-flex w-100" >
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            {!loadingLogin &&
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Gestionale Esagramma</h1>

                  <p className="lead">
                    Inserisci qui le tue credenziali
                  </p>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <img style={{ width: "100%", height: "auto" }} src="LOGO.jpg" />
                    </div>
                    <div className="m-sm-3">
                      <form onSubmit={handleLogin}>
                        <div className="mb-3">
                          <label className="form-label">Email</label>
                          <input className="form-control form-control-lg" type="email" name="email" value={email} onChange={(event) => { setEmail(event.target.value); setLoginError(""); }} />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">Password</label>
                          <input className="form-control form-control-lg" type={isShowPassword ? "text" : "password"} name="password" value={password} onChange={(event) => { setPassword(event.target.value); setLoginError(""); }} />
                        </div>
                        <div>
                          <label htmlFor="showPasswordLogin" className="card-subtitle text-muted">Mostra password</label>
                          <input id="showPasswordLogin" type="checkbox" className="form-check-input m-1" checked={isShowPassword} onChange={(event) => { setShowPassword(event.target.checked); }} />
                        </div>
                        <p className="text-danger">{loginError}</p>
                        <p className="text-success">{loginSuccesso == true ? "Il login è stato eseguito con successo. Se non visualizzi la pagina che hai richiesto, prova a ricaricare la scheda del tuo browser." : ""}</p>
                        <div className="d-grid gap-2 mt-3">
                          <APICallButton buttonClassName="btn btn-lg btn-primary m-1 w-full" buttonText="Entra" isSubmit={true} buttonOnClick={() => { }} nomeProcedura={"il login"}/>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            }
            {loadingLogin &&
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Gestionale Esagramma</h1>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div>
                      <img style={{ width: "100%", height: "auto" }} src="LOGO.jpg" />
                    </div>
                    <div className="m-sm-3">
                      <h4 className="card-title">Caricamento...</h4>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </main>
  </div>);
}

export default Login;
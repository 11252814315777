import React from 'react'
import { Modal, Button } from 'react-bootstrap';

import DettagliSeduta from '../DettagliSeduta';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';
import DettagliPrenotazione from '../DettagliPrenotazione';

/*
Dialog che ti chiede se vuoi davvero eliminare un utente
*/
function ModalEventoSelezionato(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
    //props richieste: showmodal, closemodal, selectedSedutaId, selectedPrenotazioneId, sedutaText, loadSeduteFormValues, showDeleteSeduta, showSegnaSeduta, prenotazioneText
    return (
        <div>
            <Modal show={props.showmodal}>
                <Modal.Header closeButton onClick={props.closemodal}>
                    <Modal.Title>
                        {props.selectedSedutaId.current != undefined && <h3 className="card-title">Seduta selezionata</h3>}
                        {props.selectedPrenotazioneId.current != undefined && <h3 className="card-title">Prenotazione selezionata</h3>}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.selectedSedutaId.current != undefined &&
                        <>
                            <div className="overflow-y-scroll">
                                <DettagliSeduta sedutaText={props.sedutaText} />
                            </div>
                            <button className="btn btn-secondary m-1" onClick={() => {props.loadSeduteFormValues(); props.closemodal();}} disabled={props.selectedSedutaId == undefined}>Modifica</button>
                            <button className="btn btn-danger m-1" onClick={() => {props.showDeleteSeduta(); props.closemodal();}} disabled={props.selectedSedutaId == undefined}>Elimina</button>
                            <button className="btn btn-primary m-1" onClick={() => {props.showSegnaSeduta(); props.closemodal();}} disabled={props.selectedSedutaId == undefined}>Segna</button>
                        </>
                    }
                    {props.selectedPrenotazioneId.current != undefined &&
                        <>
                            <div className="overflow-y-scroll">
                                <DettagliPrenotazione prenotazioneText={props.prenotazioneText} />
                            </div>
                            <button className="btn btn-secondary m-1" onClick={() => { props.apriModificaPrenotazione(props.selectedPrenotazioneId.current); props.closemodal(); }} disabled={props.selectedPrenotazioneId == undefined}>Modifica</button>
                            <button className="btn btn-danger m-1" onClick={() => {props.showDeletePrenotazione(); props.closemodal();}} disabled={props.selectedPrenotazioneId == undefined}>Rimuovi</button>
                        </>
                    }
                </Modal.Body>
            </Modal>

        </div>
    )
}
export default ModalEventoSelezionato;
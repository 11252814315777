import React from 'react'
import { Modal, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { ContextGestionale } from '../../App';
import { useContext } from 'react';


function ModalSedutaProgrammata(props) {
  const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
  const [validForm, setValidForm] = useState(false);
  const [giornoSeduta, setGiornoSeduta] = useState("2");
  const [oraInizio, setOraInizio] = useState("--:--");
  const [oraFine, setOraFine] = useState("--:--");
  const [userRowIndex, setUserRowIndex] = useState(undefined);
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");

  useEffect(() => {
    if (props.showmodal) {
      if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
        console.log("useEffect: modal della seduta programmata aperto");
      }
      //carica i dati nella modal. da props.users, prendi l'oggetto dell'id utente props.selectedId e inserisci giornoSeduta, orainizio e orafine
      for(let i = 0;i < props.users.length;i++){
        if(props.users[i].idUtente == props.selectedId[0]){
          setNome(props.users[i].nome);
          setCognome(props.users[i].cognome);
          setGiornoSeduta(props.users[i].giorno !== null ? props.users[i].giorno : "2");
          setOraInizio(props.users[i].oraInizio !== null ? props.users[i].oraInizio : "--:--");
          setOraFine(props.users[i].oraFine !== null ? props.users[i].oraFine : "--:--");
          setUserRowIndex(i);
          break;
        }
      }
    }
  }, [props.showmodal]);

  //controlla che il form sia valido. Controlla che ora di inizio sia prima di ora di fine
  useEffect(()=> {
    let durataOre = parseInt(oraFine.split(":")[0]) - parseInt(oraInizio.split(":")[0]);
        let durataMinuti = parseInt(oraFine.split(":")[1]) - parseInt(oraInizio.split(":")[1]);
        if (durataMinuti < 0) {
            durataOre -= 1;
            durataMinuti += 60;
        }
        if (isNaN(durataOre) || isNaN(durataMinuti) || durataOre < 0 || (durataOre === 0 && durataMinuti === 0)) {
            setValidForm(false);
        } else {
            setValidForm(true);
        }
  }, [oraInizio, oraFine]);

  //funzioni di handle dell'aggiornamento dei campi nel form
  const handleOraInizioChange = (event) => {
    setOraInizio(event.target.value);
  }
  const handleOraFineChange = (event) => {
    setOraFine(event.target.value);
  }
  const handleGiornoChange = (event) => {
    setGiornoSeduta(event.target.value);
  }


  function saveSedutaProg() {
    //in props.users[userRowIndex], imposta giorno, oraInizio e oraFine dagli useState giornoSeduta, oraInizio e oraFine
    if(validForm){
      let usersTemp = props.users.slice();
      usersTemp[userRowIndex].giorno = giornoSeduta;
      usersTemp[userRowIndex].oraInizio = oraInizio;
      usersTemp[userRowIndex].oraFine = oraFine;
      props.setUsers(usersTemp);
    }
  }

  function noSedutaProg(){
    let usersTemp = props.users.slice();
      usersTemp[userRowIndex].giorno = null;
      usersTemp[userRowIndex].oraInizio = null;
      usersTemp[userRowIndex].oraFine = null;
      props.setUsers(usersTemp);
  }
  return (
    <div>
      <Modal show={props.showmodal}>
        <Modal.Header closeButton onClick={props.closemodal}>
          <Modal.Title><h3 className="card-title">Programma seduta settimanale</h3></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section>
            <label className="form-label fw-bold">Paziente: {props.nomeCognomePaziente !== undefined ? props.nomeCognomePaziente : nome + " " + cognome}</label>
          </section>
          <section>
            <label className="form-label fw-bold">Professionista: {props.nomeCognomeProfessionista !== undefined ? props.nomeCognomeProfessionista : nome + " " + cognome}</label>
          </section>
          <section>
            <label className="form-label fw-bold">Giorno della settimana</label>
            <select className="form-control" value={giornoSeduta} onChange={handleGiornoChange}>
              <option value="2">Lunedì</option>
              <option value="3">Martedì</option>
              <option value="4">Mercoledì</option>
              <option value="5">Giovedì</option>
              <option value="6">Venerdì</option>
              <option value="7">Sabato</option>
            </select>
          </section>
          <section>
            <label className="form-label fw-bold">Dalle ore</label>
            <input type="time" value={oraInizio} onChange={handleOraInizioChange}></input>
            <label className="form-label fw-bold">Alle ore</label>
            <input type="time" value={oraFine} onChange={handleOraFineChange}></input>
            {!validForm && <p className="text-danger">Inserire orari validi</p>}
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => { saveSedutaProg(); props.closemodal(); }} disabled={!validForm}>
            Ok
          </Button>
          <Button variant="primary" onClick={props.closemodal}>
            Annulla
          </Button>
          <Button variant="secondary" onClick={() => { noSedutaProg(); props.closemodal(); }}>
            Nessuna seduta programmata
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
export default ModalSedutaProgrammata;
import React from "react";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import SearchAndSelect from "../SearchAndSelect";
import { useEffect } from "react";
import { useState } from "react";
import SelettoreProfessionistaPaz from "../SelettoreProfessionistaPaz";
import APICallButton from "../APICallButton";
import Heading from "../Heading";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";

function CertificazioneCreateUpdate(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    //è false quando si richiede che i pazienti nella select vengano aggiornati
    const [pazientiUpdated, setPazientiUpdated] = useState(true);
    //è false quando si richiede che i professionisti nella select vengano aggiornati
    const [professionistiUpdated, setProfessionistiUpdated] = useState(true);
    const [listaAssegnazioni, setListaAssegnazioni] = useState([]);
    const [listaSpecializzazioni, setListaSpecializzazioni] = useState([]);

    function addFormControlClassNameToFlatpickrInput() {
        if (document.getElementById("certificazioneCreateUpdateDate") != null && document.getElementById("certificazioneCreateUpdateDate").getElementsByClassName("flatpickr-input").length == 2) {
            document.getElementById("certificazioneCreateUpdateDate").getElementsByClassName("flatpickr-input")[0].classList.add("form-control");
            document.getElementById("certificazioneCreateUpdateDate").getElementsByClassName("flatpickr-input")[1].classList.add("form-control");
            document.getElementById("certificazioneCreateUpdateDate").getElementsByClassName("flatpickr-input")[0].disabled = (pageViewed === pages.Certificazione);
            document.getElementById("certificazioneCreateUpdateDate").getElementsByClassName("flatpickr-input")[1].disabled = (pageViewed === pages.NuovaCertificazione);
        } else {
            setTimeout(addFormControlClassNameToFlatpickrInput, 10);
        }
    }
    addFormControlClassNameToFlatpickrInput();

    function setSelectedPaziente(paziente) {
        props.setCertificazioneFormValues(prevState => ({
            ...prevState,
            selectedPaziente: paziente
        }));
    }

    function setSelectedProfessionista(professionista) {
        props.setCertificazioneFormValues(prevState => ({
            ...prevState,
            selectedProfessionista: professionista
        }));
    }

    function setSelectedServizio(servizi) {
        props.setCertificazioneFormValues(prevState => ({
            ...prevState,
            selectedServizio: servizi
        }));
    }

    function getServizioText(element) {
        return element.nomeServizio + (element.descrizioneServizio.length > 0 ? " (" + element.descrizioneServizio + ")" : "");
    }

    function handleChange(event) {
        const { name, value } = event.target;
        props.setCertificazioneFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function saveCertificazione() {
        //Se i dati inseriti sono validi...
        if (props.certificazioneFormValues.selectedServizio != [] &&
            props.certificazioneFormValues.selectedPaziente != [] &&
            props.certificazioneFormValues.selectedProfessionista != [] &&
            parseInt(props.certificazioneFormValues.prezzo) >= 0 &&
            parseInt(props.certificazioneFormValues.costoProfessionista) >= 0 &&
            props.certificazioneFormValues.nota.length <= process.env.REACT_APP_NOTA_CERTIFICAZIONE_MAX_CARATTERI &&
            props.certificazioneFormValues.nota.length >= 0 &&
            props.certificazioneFormValues.dataAcquisto != "" &&
            !(pageViewed === pages.Certificazione && props.certificazioneFormValues.dataConclusione == "")
        ) {
            if (pageViewed === pages.NuovaCertificazione) {
                //salvataggio nuova certificazione
                let reqBody = { certificazioneFormValues: props.certificazioneFormValues };
                setLoading(true);
                nodeReq.post(process.env.REACT_APP_API_URL + "/nuovaCertificazione", reqBody)
                    .then(response => {
                        if (response.status == 200) {
                            genericAlert("Certificazione creata correttamente");
                            props.setCertificazioneFormValues(props.nuovaCertificazioneFormValues);
                            props.setCertificazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.nuovaCertificazioneFormValues)));
                        } else {
                            genericAlert("Impossibile salvare la nuova certificazione al momento. Riprova più tardi.");
                        }
                    })
                    .catch(error => {
                        handleAPIError(error, "salvare la nuova certificazione");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                //impostazione della data di conclusione della certificazione
                let reqBody = { dataConclusione: props.certificazioneFormValues.dataConclusione, idCertificazione: props.certificazioneFormValues.idCertificazioneToUpdate };
                setLoading(true);
                nodeReq.post(process.env.REACT_APP_API_URL + "/concludiCertificazione", reqBody)
                    .then(response => {
                        if (response.status == 200) {
                            genericAlert("La certificazione ora è stata segnata come conclusa");
                            props.setCertificazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.certificazioneFormValues)));
                        } else {
                            genericAlert("Impossibile segnare la certificazione come conclusa al momento. Riprova più tardi.");
                        }
                    })
                    .catch(error => {
                        if (error.response != undefined && error.response.status == 400) {
                            genericAlert("La certificazione risulta già segnata come conclusa, pertanto non è possibile assegnare una nuova data di conclusione.");
                        } else {
                            handleAPIError(error, "segnare la certificazione come conclusa");
                        }
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        } else {
            //altrimenti...
            genericAlert("Per aggiornare/creare la certificazione, seleziona un paziente, un professionista e un servizio. Inoltre, specifica prezzo, costo professionista, data di acquisto non superare i " + process.env.REACT_APP_NOTA_CERTIFICAZIONE_MAX_CARATTERI + " caratteri per la nota. Se stai segnando una certificazione come conclusa, devi specificare la data di conclusione.");
        }
    }
    useEffect(() => {
        if (pageViewed === pages.Certificazione || pageViewed === pages.NuovaCertificazione) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: pagina crea/modifica certificazione aperta");
            }
            //chiedi ai due selettori (professionisti e pazienti) di ricaricare le liste
            setPazientiUpdated(false);
            setProfessionistiUpdated(false);
            //inserisci i servizi nella searchAndSelect
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/servizi?soloServiziPerCertificazioni=true&ordineAlfabetico=true")
                .then(response => {
                    if (response.status == 200) {
                        let data = response.data.dbdata;
                        for (let i = 0; i < data.length; i++) {
                            data[i].id = data[i].idServizio;
                            data[i].viewed = true;
                        }
                        setListaServizi(data);
                    } else {
                        genericAlert("Impossibile recuperare i servizi al momento. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "recuperare i servizi");
                })
                .finally(() => {
                    setLoading(false);
                });
            //carica tutte le assegnazioni
            setLoading(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/assegnazioni")
                .then(response => {
                    if (response.status == 200) {
                        setListaAssegnazioni(response.data.dbdata);
                    } else {
                        genericAlert("Impossibile caricare le assegnazioni dei pazienti al momento. Riprova più tardi");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "caricare le assegnazioni dei pazienti");
                })
                .finally(() => {
                    setLoading(false);
                });
            //carica tutte le specializzazioni
            setLoading(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/specializzazioni?soloTariffeAttuali=true")
                .then(response => {
                    if (response.status == 200) {
                        setListaSpecializzazioni(response.data.dbdata);
                    } else {
                        genericAlert("Impossibile caricare le specializzazioni dei professionisti al momento. Riprova più tardi");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "caricare le specializzazioni dei professionisti");
                })
                .finally(() => {
                    setLoading(false);
                });
            addFormControlClassNameToFlatpickrInput();
        }
    }, [pageViewed]);

    useEffect(() => {
        if (pageViewed === pages.NuovaCertificazione) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: servizio cambiato in crea certificazione");
            }
            //Cerca il servizio selezionato
            for (let i = 0; i < listaSpecializzazioni.length; i++) {
                if (listaSpecializzazioni[i].idServizio == props.certificazioneFormValues.selectedServizio[0] && listaSpecializzazioni[i].idProfessionista.toString() == props.certificazioneFormValues.selectedProfessionista) {
                    props.setCertificazioneFormValues(prevState => ({
                        ...prevState,
                        prezzo: listaSpecializzazioni[i].tariffa,
                        costoProfessionista: listaSpecializzazioni[i].costoProfessionista
                    }));
                    break;
                }
            }
        }
    }, [props.certificazioneFormValues.selectedServizio, props.certificazioneFormValues.selectedProfessionista]);

    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: professionista cambiato in crea/modifica certificazione");
        }
        props.setCertificazioneFormValues(prevState => ({
            ...prevState,
            selectedServizio: [],
            selectedPaziente: []
        }));
    }, [props.certificazioneFormValues.selectedProfessionista]);

    function filtroPazienti(paziente) {
        if (props.certificazioneFormValues.selectedProfessionista == undefined) {
            return false;
        }
        for (let i = 0; i < listaAssegnazioni.length; i++) {
            if (listaAssegnazioni[i].idPaziente == paziente.idUtente && listaAssegnazioni[i].idProfessionista == props.certificazioneFormValues.selectedProfessionista) {
                return true;
            }
        }
        return false;
    }

    function filtroServizi(servizio) {
        if (props.certificazioneFormValues.selectedProfessionista == undefined) {
            return false;
        }
        for (let i = 0; i < listaSpecializzazioni.length; i++) {
            if (listaSpecializzazioni[i].idServizio == servizio.idServizio && listaSpecializzazioni[i].idProfessionista == props.certificazioneFormValues.selectedProfessionista) {
                return true;
            }
        }
        return false;
    }

    function dataFormatoAmericano(data) {
        return data.getFullYear() + "-" + (data.getMonth() + 1).toString().padStart(2, '0') + "-" + data.getDate().toString().padStart(2, '0');
    }

    function handleDataAcquistoChange(event) {
        console.log(dataFormatoAmericano(event[0]));
        if (Array.isArray(event) && event.length == 1) {
            props.setCertificazioneFormValues(prevState => ({
                ...prevState,
                dataAcquisto: dataFormatoItaliano(dataFormatoAmericano(event[0]))
            }));
        }
    }

    function handleDataConclusioneChange(event) {
        if (Array.isArray(event) && event.length == 1) {
            props.setCertificazioneFormValues(prevState => ({
                ...prevState,
                dataConclusione: dataFormatoItaliano(dataFormatoAmericano(event[0]))
            }));
        }
    }
    return (
        <div style={{ display: pageViewed === pages.Certificazione || pageViewed === pages.NuovaCertificazione ? 'block' : 'none' }}>
            <Heading title={pageViewed === pages.NuovaCertificazione ? "Nuova certificazione" : "Segna certificazione come conclusa"} iconName="clipboard_plus" />
            <div className="row">
                {pageViewed === pages.NuovaCertificazione &&
                    <>
                        <div className="col-lg">
                            <div className="card">
                                <div className="card-body">
                                    <SelettoreProfessionistaPaz paziente={false} show={true} allowAll={false} selected={props.certificazioneFormValues.selectedProfessionista} setSelected={setSelectedProfessionista} updated={professionistiUpdated} setUpdated={setProfessionistiUpdated} disabled={pageViewed === pages.Certificazione} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="card">
                                <div className="card-body">
                                    <SelettoreProfessionistaPaz paziente={true} show={true} allowAll={false} selected={props.certificazioneFormValues.selectedPaziente} setSelected={setSelectedPaziente} updated={pazientiUpdated} setUpdated={setPazientiUpdated} disabled={pageViewed === pages.Certificazione} filterFunction={filtroPazienti} notSelectableFunction={(paziente) => { return paziente.stato == "Bloccato" || paziente.stato == "Insolvente" }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg">
                            <div className="card">
                                <div className="card-body">
                                    <SearchAndSelect title={"Servizio"} elements={listaServizi} oneElementName={"un servizio"} allowMultiple={false} showDeselectButton={false} validSelection={true} setValidSelection={(val) => { }} required={true} selectedOptions={props.certificazioneFormValues.selectedServizio} setSelectedOptions={setSelectedServizio} getElementText={getServizioText} disabled={pageViewed === pages.Certificazione} filterFunction={filtroServizi} />
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Dati della certificazione</h5>
                        </div>
                        <div className="card-body">
                            {pageViewed === pages.Certificazione &&
                                <>
                                    <p><b>Professionista: </b>{props.certificazioneFormValues.professionista}</p>
                                    <p><b>Paziente: </b>{props.certificazioneFormValues.paziente}</p>
                                    <p><b>Servizio: </b>{props.certificazioneFormValues.servizio}</p>
                                </>
                            }
                            <label htmlFor="inputPrezzo">Prezzo</label>
                            <div className="input-group" id="inputPrezzo">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">€</div>
                                </div>
                                <input disabled={pageViewed === pages.Certificazione} type="number" min="0.00" step="0.01" name="prezzo" className="form-control mb-1" value={props.certificazioneFormValues.prezzo} onChange={handleChange} />
                            </div>
                            <label htmlFor="inputCostoProfessionista">Costo professionista</label>
                            <div className="input-group" id="inputCostoProfessionista">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">€</div>
                                </div>
                                <input disabled={pageViewed === pages.Certificazione} type="number" min="0.00" step="0.01" name="costoProfessionista" className="form-control mb-1" value={props.certificazioneFormValues.costoProfessionista} onChange={handleChange} />
                            </div>
                            <label htmlFor="notaCertificazione">Nota (max {process.env.REACT_APP_NOTA_CERTIFICAZIONE_MAX_CARATTERI} caratteri)</label>
                            <input disabled={pageViewed === pages.Certificazione} type="text" id="notaCertificazione" name="nota" className="form-control mb-1" value={props.certificazioneFormValues.nota} onChange={handleChange} />
                            <div id="certificazioneCreateUpdateDate" className="form-group d-flex flex-row w-full flex-wrap">
                                <div className="flex-grow-1">
                                    <label className="text-truncate">Data di acquisto</label>
                                    <Flatpickr options={{ dateFormat: "d/m/Y", clickOpens: pageViewed === pages.NuovaCertificazione, mode: "single", locale: Italian, defaultDate: [props.certificazioneFormValues.dataAcquisto] }} value={[props.certificazioneFormValues.dataAcquisto]} onChange={handleDataAcquistoChange} />
                                </div>
                                <div className="flex-grow-1">
                                    <label className="text-truncate">Data di conclusione</label>
                                    <Flatpickr options={{ dateFormat: "d/m/Y", clickOpens: pageViewed === pages.Certificazione, mode: "single", locale: Italian, defaultDate: [props.certificazioneFormValues.dataConclusione] }} value={[props.certificazioneFormValues.dataConclusione]} onChange={handleDataConclusioneChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Pulsante Salva*/}
            <div className="row">
                <div className="card">
                    <div className="card-body">
                        <APICallButton buttonClassName="btn btn-primary m-1" buttonOnClick={saveCertificazione} buttonText={pageViewed === pages.NuovaCertificazione ? "Salva" : "Segna come conclusa"} nomeProcedura={"il salvataggio della certificazione"}/>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CertificazioneCreateUpdate;
import FeatherIcon from "./FeatherIcon";
import axios from "axios";
import { useRef } from "react";
import { ContextGestionale } from '../App';
import { useContext, useState, useEffect } from 'react';
//quando la window.innerWidth < 768px, allora devi fare questo: #sidebar.classList.toggle("collapsed");
function Sidebar(props) {

    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);

    const sidebarButtonsDivStyle = {
        overflowY: "scroll",
        scrollbarWidth: '10px',
        scrollbarTrackColor: '#222e3c', //lo stesso colore di .sidebar
        scrollbarThumbColor: '#888',
        '&:hover': {
            scrollbarThumbColor: '#555'
        }
    }

    const sidebarRef = useRef(null);
    function handleChangePage(page) {
        checkUnsavedBeforeDoing(() => { checkUserLoggedBeforeDoing(() => { setPageViewed(page); console.log("Pagina cambiata: " + page); cambiaUrl(page); closeSidebarOnSmallScreen(); }) });
    }
    function goToNuovaSeduta() {
        checkUnsavedBeforeDoing(() => { checkUserLoggedBeforeDoing(() => { setSeduteFormValues(nuovaSedutaFormValues); setSeduteFormValuesBeforeEdits(JSON.parse(JSON.stringify(nuovaSedutaFormValues))); closeSidebarOnSmallScreen(); }) });
    }
    function goToNuovaCertificazione() {
        checkUnsavedBeforeDoing(() => {
            checkUserLoggedBeforeDoing(() => {
                props.setCertificazioneFormValues(props.nuovaCertificazioneFormValues);
                props.setCertificazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.nuovaCertificazioneFormValues)));
                setPageViewed(pages.NuovaCertificazione);
                cambiaUrl(pages.NuovaCertificazione);
                closeSidebarOnSmallScreen();
            });
        });
    }

    function goToNuovoServizio() {
        checkUnsavedBeforeDoing(() => {
            checkUserLoggedBeforeDoing(() => {
                props.setServizioFormValues(props.initialServizioFormValues);
                props.setServizioFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.initialServizioFormValues)));
                setPageViewed(pages.NuovoServizio);
                cambiaUrl(pages.NuovoServizio);
                closeSidebarOnSmallScreen();
            });
        });
    }
    function goToNuovaPrenotazione() {
        checkUnsavedBeforeDoing(() => {
            checkUserLoggedBeforeDoing(() => {
                setPrenotazioneFormValues(props.nuovaPrenotazioneFormValues);
                setPrenotazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.nuovaPrenotazioneFormValues)));
                setPageViewed(pages.NuovaPrenotazione);
                cambiaUrl(pages.NuovaPrenotazione);
                closeSidebarOnSmallScreen();
            });
        });
    }
    function closeSidebarOnSmallScreen() {
        if (window.innerWidth < 768) {
            sidebarRef.current.classList.toggle("collapsed");
        }
    }

    //chiudi la sidebar se l'utente è visualizzatore o visualizzatore2
    useEffect(() => {
        if(loggedUser.tipoUtente == "visualizzatore" || loggedUser.tipoUtente == "visualizzatore2"){
            sidebarRef.current.classList.add("collapsed");
        }
    },[loggedUser]);

    return (
        <nav id="sidebar" ref={sidebarRef} className="sidebar js-sidebar" style={{ display: pageViewed === pages.Login || pageViewed === pages.ImpostazionePassword ? 'none' : 'block' }}>
            <div className="sidebar-content js-simplebar">
                <div>
                    <a className="sidebar-brand">
                        <span className="align-middle">Gestionale Esagramma</span>
                    </a>
                </div>
                <div className="sidebarButtonsContainer">
                    <ul className="sidebar-nav">
                        {process.env.REACT_APP_MOSTRA_GRAFICI === true &&
                            <li className={`sidebar-item ${pageViewed === pages.Grafici ? 'active' : ''}`} onClick={() => handleChangePage(pages.Grafici)}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="grid" /><span className="align-middle">Grafici</span>
                                </a>
                            </li>
                        }
                        <li className={`sidebar-item ${(pageViewed === pages.Dashboard || (pageViewed === pages.Seduta && seduteFormValues.openedFrom === "dashboard")) || pageViewed === pages.Prenotazione ? 'active' : ''}`} onClick={() => handleChangePage(pages.Dashboard)}>
                            <a className="sidebar-link">
                                <FeatherIcon addedClassName="align-middle" iconName="calendar" /><span className="align-middle">Dashboard</span>
                            </a>
                        </li>
                        {loggedUser.tipoUtente == "segretario" &&
                            <li className={`sidebar-item ${pageViewed === pages.Statistiche ? 'active' : ''}`} onClick={() => handleChangePage(pages.Statistiche)}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="trending-up"/><span className="align-middle">Statistiche</span>
                                </a>
                            </li>
                        }
                        {(loggedUser.tipoUtente === "segretario" || loggedUser.tipoUtente === "professionista") &&
                            <li className="sidebar-header">
                                Sedute
                            </li>
                        }
                        {(loggedUser.tipoUtente === "segretario" || loggedUser.tipoUtente === "professionista") &&
                            <>
                                <li className={`sidebar-item ${pageViewed === pages.NuovaSeduta ? 'active' : ''}`} onClick={goToNuovaSeduta}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="clipboard_plus" /><span className="align-middle">Prenota seduta</span>
                                    </a>
                                </li>
                                <li className={`sidebar-item ${pageViewed === pages.Sedute || (pageViewed === pages.Seduta && seduteFormValues.openedFrom === "tabellaSedute") ? 'active' : ''}`} onClick={() => handleChangePage(pages.Sedute)}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="clipboard" /><span className="align-middle">Tabella sedute</span>
                                    </a>
                                </li>
                            </>
                        }
                        {loggedUser.tipoUtente === "segretario" &&
                            <>
                                <li className="sidebar-header">
                                    Prenotazioni generiche
                                </li>
                                <li className={`sidebar-item ${pageViewed === pages.NuovaPrenotazione ? 'active' : ''}`} onClick={goToNuovaPrenotazione}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="calendar" /><span className="align-middle">Nuova prenotazione generica</span>
                                    </a>
                                </li>
                            </>
                        }
                        {loggedUser.tipoUtente === "segretario" &&
                            <>
                                <li className="sidebar-header">
                                    Professionisti
                                </li>
                                <li className={`sidebar-item ${pageViewed === pages.NuovoProfessionista ? 'active' : ''}`} onClick={() => { setProfessionistaPazFormValues(initialProfessionistaPazFormValues); handleChangePage(pages.NuovoProfessionista); }}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="user-plus" /><span className="align-middle">Nuovo professionista</span>
                                    </a>
                                </li>
                                <li className={`sidebar-item ${(pageViewed === pages.Professionisti || pageViewed === pages.Professionista) ? 'active' : ''}`} onClick={() => handleChangePage(pages.Professionisti)}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="users" /> <span className="align-middle">Lista professionisti</span>
                                    </a>
                                </li>
                            </>
                        }
                        {loggedUser.tipoUtente === "segretario" &&
                            <li className={`sidebar-item ${pageViewed === pages.DisponibilitaProfessionista ? 'active' : ''}`} onClick={() => handleChangePage(pages.DisponibilitaProfessionista)}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="user-check" /> <span className="align-middle">Disponibilità professionista</span>
                                </a>
                            </li>
                        }
                        {(loggedUser.tipoUtente === "segretario" || loggedUser.tipoUtente === "professionista") &&
                            <li className="sidebar-header">
                                Pazienti
                            </li>
                        }
                        {loggedUser.tipoUtente === "segretario" &&
                            <li className={`sidebar-item ${pageViewed === pages.NuovoPaziente ? 'active' : ''}`} onClick={() => { setProfessionistaPazFormValues(initialProfessionistaPazFormValues); handleChangePage(pages.NuovoPaziente); }}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="user-plus" /> <span className="align-middle">Nuovo paziente</span>
                                </a>
                            </li>
                        }
                        {(loggedUser.tipoUtente === "segretario" || loggedUser.tipoUtente === "professionista") &&
                            <li className={`sidebar-item ${(pageViewed === pages.Pazienti || pageViewed === pages.Paziente) ? 'active' : ''}`} onClick={() => handleChangePage(pages.Pazienti)}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="users" /><span className="align-middle">{loggedUser.tipoUtente == "professionista" ? "I miei pazienti" : "Lista pazienti"}</span>
                                </a>
                            </li>
                        }
                        {loggedUser.tipoUtente === "segretario" &&
                            <>
                                <li className="sidebar-header">
                                    Servizi
                                </li>
                                <li className={`sidebar-item ${pageViewed === pages.NuovoServizio ? 'active' : ''}`} onClick={goToNuovoServizio}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="grid" /> <span className="align-middle">Nuovo servizio</span>
                                    </a>
                                </li>
                                <li className={`sidebar-item ${pageViewed === pages.Servizi || pageViewed === pages.Servizio ? 'active' : ''}`} onClick={() => { handleChangePage(pages.Servizi) }}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="grid" /> <span className="align-middle">Lista servizi</span>
                                    </a>
                                </li>
                            </>
                        }
                        {props.abilitaCertificazioni == true &&
                            <li className="sidebar-header">
                                Certificazioni
                            </li>
                        }
                        {loggedUser.tipoUtente == "segretario" &&
                            <li className={`sidebar-item ${pageViewed === pages.NuovaCertificazione ? 'active' : ''}`} onClick={goToNuovaCertificazione}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="clipboard_plus" /><span className="align-middle">Nuova certificazione</span>
                                </a>
                            </li>
                        }
                        {props.abilitaCertificazioni == true &&
                            <li className={`sidebar-item ${pageViewed === pages.Certificazioni ? 'active' : ''}`} onClick={() => handleChangePage(pages.Certificazioni)}>
                                <a className="sidebar-link">
                                    <FeatherIcon addedClassName="align-middle" iconName="clipboard" /><span className="align-middle">Lista certificazioni</span>
                                </a>
                            </li>
                        }
                        {(loggedUser.tipoUtente === "segretario" || loggedUser.tipoUtente === "professionista") &&
                            <>
                                <li className="sidebar-header">
                                    Contabilita
                                </li>
                                <li className={`sidebar-item ${pageViewed === pages.Contabilita ? 'active' : ''}`} onClick={() => handleChangePage(pages.Contabilita)}>
                                    <a className="sidebar-link">
                                        <FeatherIcon addedClassName="align-middle" iconName="euro-sign" /><span className="align-middle">Contabilità</span>
                                    </a>
                                </li>
                            </>
                        }
                    </ul>
                </div>
                <div className="p-3">
                    <a className="card-title" href="https://digitales.srl" target="_blank"><strong>Digitales srl &copy;</strong></a>
                </div>
            </div>
        </nav >
    );
}

export default Sidebar;
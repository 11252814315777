import React, { useState } from "react";
import { useContext } from "react";
import { ContextGestionale } from "../App";
import FeatherIcon from "./FeatherIcon";
import { useEffect } from "react";
import APICallButton from "./APICallButton";

function AltriNumeriTelefono(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
    function deleteNumeroTel(index) {
        let professionistaPazFormValuesTemp = JSON.parse(JSON.stringify(professionistaPazFormValues));
        professionistaPazFormValuesTemp.altriNumeriTelefono.splice(index, 1);
        setProfessionistaPazFormValues(professionistaPazFormValuesTemp);
    }
    function addNumeroTel() {
        let professionistaPazFormValuesTemp = JSON.parse(JSON.stringify(professionistaPazFormValues));
        professionistaPazFormValuesTemp.altriNumeriTelefono.push({ telefono: "", descrizione: "" });
        setProfessionistaPazFormValues(professionistaPazFormValuesTemp);
    }
    function updateNumero(index, telefono) {
        let professionistaPazFormValuesTemp = JSON.parse(JSON.stringify(professionistaPazFormValues));
        professionistaPazFormValuesTemp.altriNumeriTelefono[index].telefono = telefono;
        setProfessionistaPazFormValues(professionistaPazFormValuesTemp);
    }
    function updateContatto(index, descrizione) {
        let professionistaPazFormValuesTemp = JSON.parse(JSON.stringify(professionistaPazFormValues));
        professionistaPazFormValuesTemp.altriNumeriTelefono[index].descrizione = descrizione;
        setProfessionistaPazFormValues(professionistaPazFormValuesTemp);
    }

    function salvaNumeriTel() {
        if (professionistaPazFormValues.altriNumeriTelefono.length > 0) {
            let reqBody = { numeriTelefono: professionistaPazFormValues.altriNumeriTelefono };
            setProfessionistaPazFormValuesBeforeEdits(JSON.parse(JSON.stringify(professionistaPazFormValues)));
            setLoading(true);
            nodeReq.post(process.env.REACT_APP_API_URL + "/numeriTelefono", reqBody)
                .then(response => {
                    if (response.status === 200) {
                        genericAlert("I numeri di telefono sono stati aggiornati.");
                    } else {
                        genericAlert("Impossibile aggiornare i numeri di telefono. Riprova più tardi");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "aggiornare i numeri di telefono");
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            genericAlert("Inserisci almeno un numero di telefono prima di salvare");
        }
    }

    useEffect(() => {
        if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
            console.log("useEffect: controllo validità numeri telefono");
          }
        props.setAltriNumeriValidi(true);
        for (let i = 0; i < professionistaPazFormValues.altriNumeriTelefono.length; i++) {
            if (professionistaPazFormValues.altriNumeriTelefono[i].telefono.length < 1 ||
                professionistaPazFormValues.altriNumeriTelefono[i].telefono.length > process.env.REACT_APP_TELEFONO_UTENTE_MAX_CARATTERI ||
                professionistaPazFormValues.altriNumeriTelefono[i].descrizione.length > process.env.REACT_APP_CONTATTO_UTENTE_MAX_CARATTERI
            ) {
                props.setAltriNumeriValidi(false);
                break;
            }
        }
    }, [professionistaPazFormValues.altriNumeriTelefono]);

    return (
        <>
            <div className="col-md">
                <div className="card">
                    <div className="card-header" onClick={() => { props.setCardExpanded(!props.cardExpanded); }}>
                        <div className="d-flex flex-row">
                            <div className="flex-grow-1">
                                <h5 className="card-title">Numeri di telefono</h5>
                            </div>
                            <div>
                                <FeatherIcon iconName={props.cardExpanded ? "chevron-up" : "chevron-down"} addedClassName="" />
                            </div>
                        </div>
                    </div>
                    {props.cardExpanded &&
                        <div className="card-body">
                            <button className="btn btn-primary" onClick={addNumeroTel}>Aggiungi</button>
                        
                            <div>
                                {professionistaPazFormValues.altriNumeriTelefono.length > 0 &&
                                    < table >
                                        <tr><th>Numero</th><th>Nota</th><th>&nbsp;</th></tr>
                                        {professionistaPazFormValues.altriNumeriTelefono.map((telefono, index) => {
                                            return <tr key={index}><td><input type="text" className={"form-control m-1 " + (telefono.telefono.length < 1 || telefono.telefono.length > process.env.REACT_APP_TELEFONO_UTENTE_MAX_CARATTERI ? "is-invalid" : "")} value={telefono.telefono} onChange={(event) => { updateNumero(index, event.target.value); }} /></td><td><input type="text" className={"form-control m-1 " + (telefono.descrizione.length > process.env.REACT_APP_CONTATTO_UTENTE_MAX_CARATTERI ? "is-invalid" : "")} value={telefono.descrizione} onChange={(event) => { updateContatto(index, event.target.value); }} /></td><td><button className="btn btn-danger m-1" onClick={() => { deleteNumeroTel(index); }}><FeatherIcon addedClassName="align-middle" iconName="trash" /></button></td></tr>
                                        })}
                                    </table>
                                }
                                {professionistaPazFormValues.altriNumeriTelefono.length === 0 &&
                                    <p className="text-warning">Nessun numero di telefono inserito</p>
                                }
                            </div>
                            {props.paginaMieInfo === true &&
                                <APICallButton buttonClassName="btn btn-primary" buttonOnClick={salvaNumeriTel} buttonText="Salva" buttonDisabled={false} nomeProcedura={"il salvataggio dei numeri di telefono"}/>
                            }
                        </div>}

                </div >
            </div>
        </>
    );

}

export default AltriNumeriTelefono;
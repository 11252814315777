import DisponibilitaCalendar from "./DisponibilitaCalendar";
import SearchAndSelect from "./SearchAndSelect";
import axios from "axios";
import { useState, useEffect } from "react";
import { ContextGestionale } from "../App";
import { useContext } from "react";


function VediDisponibilitaProfessionista(props) {
	const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);

	const [disponibilitaEvents, setDisponibilitaEvents] = useState([]);
	const [seduteEvents, setSeduteEvents] = useState([]);
	const [seduteProgEvents, setSeduteProgEvents] = useState([]);
	const [allEvents, setAllEvents] = useState([]);
	const [isShowSedutePrenotate, setShowSedutePrenotate] = useState(true);
	const [calendarStartDate, setCalendarStartDate] = useState(undefined);
	const [calendarEndDate, setCalendarEndDate] = useState(undefined);
	const [queryDateRange, setQueryDateRange] = useState([((new Date()).getUTCFullYear() - 1) + "-12-21", ((new Date()).getUTCFullYear()) + "-02-08"]);

	const handleShowSedutePrenotateChange = (event) => {
		setShowSedutePrenotate(event.target.checked);
		console.log("La checkbox è checkata? " + event.target.checked);
	}
	//quando viene selezionato un professionista, carica tutte le sue disponibilità
	useEffect(() => {
		if (props.selectedProfessionisti.length === 1) {
			if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: professionista selezionato. carico le sue disponibilità");
              }
			checkUserLoggedBeforeDoing(() => {
				//carica le disponibilita del professionista
				let idProfessionistaCondition = props.selectedProfessionisti[0] != 0 && props.selectedProfessionisti[0] != "0" ? "?idProfessionista=" + props.selectedProfessionisti[0] : "";
				setLoading(true);nodeReq.get(process.env.REACT_APP_API_URL + "/disponibilitaprofessionista" + idProfessionistaCondition)
					.then(response => {
						let disponibilita = response.data.dbdata;
						let disponibilitaEventsTemp = [];
						for (let i = 0; i < disponibilita.length; i++) {
							disponibilitaEventsTemp.push({
								title: disponibilita[i].cognomeProfessionista.toUpperCase(),
								id: disponibilita[i].idDisponibilita + "-disp",
								daysOfWeek: [disponibilita[i].giorno - 1], //conversione da dayofweek del db a dayofweek di fullcalendar
								startTime: disponibilita[i].oraInizio,
								endTime: disponibilita[i].oraFine,
								display: props.openedFromDisponibilitaProfessionista == true ? "none" : "background",
								extendedProps: {
									room: disponibilita[i].idArea,
									idProfessionista: disponibilita[i].idProfessionista,
									roomName: disponibilita[i].nomeArea,
									disponibilita: true
								},
							});
						}
						setDisponibilitaEvents(disponibilitaEventsTemp);
						props.setListaDisponibilita(disponibilita);
					})
					.catch(error => {
						handleAPIError(error, "visualizzare le disponibilità del professionista");
					})
					.finally(() => {
						setLoading(false);
					  });
				//carica le sedute dello professionista
				loadSedute();
				//carica le assegnazioni dello professionista e prendi ovviamente gli orari
				setLoading(true);nodeReq.get(process.env.REACT_APP_API_URL + "/assegnazioni?idProfessionista=" + props.selectedProfessionisti[0])
					.then(response => {
						let assegnazioni = response.data.dbdata;
						let seduteProgEventsTemp = [];
						for (let i = 0; i < assegnazioni.length; i++) {
							if (assegnazioni[i].giorno !== null && assegnazioni[i].oraInizio !== null && assegnazioni[i].oraFine !== null) {
								seduteProgEventsTemp.push({
									id: assegnazioni[i].idPaziente + "-" + assegnazioni[i].idProfessionista + "-prog",
									daysOfWeek: [assegnazioni[i].giorno - 1],
									startTime: assegnazioni[i].oraInizio,
									endTime: assegnazioni[i].oraFine,
									title: (props.selectedProfessionisti[0] == 0 ? assegnazioni[i].cognomeProfessionista.toUpperCase() + " con " : "") + soloInizialeMaiuscola(assegnazioni[i].cognomePaziente) + " " + assegnazioni[i].nomePaziente[0].toUpperCase() + ".",
									backgroundColor: process.env.REACT_APP_SEDUTE_PROGRAMMATE_BCGOLOR
								});
							}
						}
						setSeduteProgEvents(seduteProgEventsTemp);
						//props.setListaSeduteProg(assegnazioni);
					})
					.catch(error => {
						handleAPIError(error, "visualizzare le sedute programmate");
					})
					.finally(() => {
						setLoading(false);
					  });
			});
		}
	}, [props.selectedProfessionisti]);

	function soloInizialeMaiuscola(str) {
		let strs = str.split(" ");
		let toReturn = "";
		for (let i = 0; i < strs.length; i++) {
			toReturn += strs[i][0].toUpperCase() + strs[i].substring(1) + " ";
		}
		return toReturn;
	}

	//esegui la chiamata API per ottenere le sedute richieste
	function loadSedute() {
		setLoading(true);nodeReq.get(process.env.REACT_APP_API_URL + "/sedute?dataInizio=" + queryDateRange[0] + "&dataFine=" + queryDateRange[1] + (props.selectedProfessionisti[0] !== "0" ? "&idProfessionista=" + props.selectedProfessionisti[0] : ""))
			.then(response => {
				let sedute = response.data.dbdata;
				let seduteEventsTemp = [];
				for (let i = 0; i < sedute.length; i++) {
					seduteEventsTemp.push({
						id: sedute[i].idSeduta + "-pre",
						start: sedute[i].data + "T" + sedute[i].oraInizio,
						end: sedute[i].data + "T" + sedute[i].oraFine,
						title: props.selectedProfessionisti[0] != 0 ? (soloInizialeMaiuscola(sedute[i].cognomePaziente) + " " + sedute[i].nomePaziente[0].toUpperCase() + ".") : ("Seduta di " + sedute[i].cognomeProfessionista.toUpperCase() + " con " + soloInizialeMaiuscola(sedute[i].cognomePaziente) + " " + sedute[i].nomePaziente[0].toUpperCase() + "."),
						backgroundColor: props.selectedSedutaId === sedute[i].idSeduta ? process.env.REACT_APP_SEDUTA_PRENOTATA_IN_MODIFICA_BGCOLOR : process.env.REACT_APP_SEDUTA_PRENOTATA_PAGINA_DISPONIBILITA_BGCOLOR,
						extendedProps: {
							room: (sedute[i].modalita !== 2 ? sedute[i].idArea : null),
							roomName: (sedute[i].modalita !== 2 ? sedute[i].nomeArea : null)
						},
					});
				}
				setSeduteEvents(seduteEventsTemp);
				setListaSedute(sedute);
			})
			.catch(error => {
				handleAPIError(error, "visualizzare le sedute");
			})
			.finally(() => {
				setLoading(false);
			  });
	}

	//quando cambiano le date selezionate nel FullCalendar, decidi quali sono le date di inizio e di fine da passare allla query
	useEffect(() => {
		if (calendarStartDate !== undefined && calendarEndDate !== undefined) {
			if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: aggiorno il range delle date passate alla query se necessario");
              }
			//Se l'intervallo scelto attualmente, non ricopre più le nuove date selezionate, devi spostarlo avanti o indietro di un mese finche non le ricopre entrambe
			let startMonth = calendarStartDate.getUTCMonth() + 1;
			let calendarStartDateString = calendarStartDate.getUTCFullYear() + "-" + startMonth.toString().padStart(2, "0") + "-" + calendarStartDate.getUTCDate().toString().padStart(2, "0");
			let endMonth = calendarEndDate.getUTCMonth() + 1;
			let calendarEndDateString = calendarEndDate.getUTCFullYear() + "-" + endMonth.toString().padStart(2, "0") + "-" + calendarEndDate.getUTCDate().toString().padStart(2, "0");
			let queryStartDateTemp = queryDateRange[0];
			let queryEndDateTemp = queryDateRange[1];
			while (calendarStartDateString.localeCompare(queryStartDateTemp) === -1) {
				queryStartDateTemp = oneMonthBackward(queryStartDateTemp);
				queryEndDateTemp = oneMonthBackward(queryEndDateTemp);
			}
			while (calendarEndDateString.localeCompare(queryEndDateTemp) === 1) {
				queryStartDateTemp = oneMonthForward(queryStartDateTemp);
				queryEndDateTemp = oneMonthForward(queryEndDateTemp);
			}
			if (queryStartDateTemp !== queryDateRange[0] || queryEndDateTemp !== queryDateRange[1]) {
				setQueryDateRange([queryStartDateTemp, queryEndDateTemp]);
			}
		}
	}, [calendarStartDate, calendarEndDate]);

	//quando cambia l'intervallo di date in cui caricare le sedute, riesegui la chiamata API
	useEffect(() => {
		checkUserLoggedBeforeDoing(() => {
			if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
                console.log("useEffect: range delle date della query cambiato. Ricarico le sedute");
              }
			loadSedute();
		});
	}, [queryDateRange]);

	function oneMonthBackward(date) {
		if (parseInt(date.substring(5, 7)) > 1) {
			//scende solo il mese
			date = date.substring(0, 5) + (parseInt(date.substring(5, 7)) - 1).toString().padStart(2, "0") + date.substring(7, 10);
		} else {
			//il mese diventa 12 e l'anno scende
			date = (parseInt(date.substring(0, 4)) - 1).toString() + "-12" + date.substring(7, 10);
		}
		return date;
	}

	function oneMonthForward(date) {
		if (parseInt(date.substring(5, 7)) < 12) {
			//scende solo il mese
			date = date.substring(0, 5) + (parseInt(date.substring(5, 7)) + 1).toString().padStart(2, "0") + date.substring(7, 10);
		} else {
			//il mese diventa 1 e l'anno sale
			date = (parseInt(date.substring(0, 4)) + 1).toString() + "-01" + date.substring(7, 10);
		}
		return date;
	}

	//inserisci in AllEvents tutti gli eventi da mostrare nel FullCalendar
	useEffect(() => {
		if(process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1){
			console.log("useEffect: aggiorno gli eventi passati a FullCalendar 1");
		  }
		let seduteEventsNumber = (isShowSedutePrenotate ? seduteEvents.length : 0);
		let selectedPeriodEventsNumber = (props.selectedPeriodEvent !== undefined ? 1 : 0);
		let allEventsLength = disponibilitaEvents.length + seduteProgEvents.length + seduteEventsNumber + selectedPeriodEventsNumber;
		let allEventsTemp = Array.from({ length: allEventsLength }, () => null);
		let allEventsIndex = 0;
		for (let i = 0; i < disponibilitaEvents.length; i++) {
			allEventsTemp[allEventsIndex] = disponibilitaEvents[i];
			allEventsIndex++;
		}
		console.log("La lista delle sedute programmate è questa:");
		console.log(seduteProgEvents);
		for (let i = 0; i < seduteProgEvents.length; i++) {
			allEventsTemp[allEventsIndex] = seduteProgEvents[i];

			allEventsIndex++;
		}
		if (isShowSedutePrenotate) {
			for (let i = 0; i < seduteEvents.length; i++) {
				allEventsTemp[allEventsIndex] = seduteEvents[i];
				allEventsIndex++;
			}
		}
		if (props.selectedPeriodEvent !== undefined) {
			allEventsTemp[allEventsIndex] = props.selectedPeriodEvent;
		}
		setAllEvents(allEventsTemp);

	}, [seduteEvents, disponibilitaEvents, seduteProgEvents, props.selectedPeriodEvent, isShowSedutePrenotate]);
	return (
		<>
			<div>
				<label htmlFor="mostraSedutePrenotateCheck">Mostra le sedute prenotate</label>
				<input id="mostraSedutePrenotateCheck" type="checkbox" className="form-check-input m-1" checked={isShowSedutePrenotate} onChange={handleShowSedutePrenotateChange} />
			</div>
			<DisponibilitaCalendar initialDate={props.initialDate} calendarEvents={allEvents} calendarStartDate={calendarStartDate} setCalendarStartDate={setCalendarStartDate} calendarEndDate={calendarEndDate} setCalendarEndDate={setCalendarEndDate} openedFromDisponibilitaProfessionista={props.openedFromDisponibilitaProfessionista} />
		</>
	);
}

export default VediDisponibilitaProfessionista;
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import TableArrows from "./TableArrows";
import { ContextGestionale } from "../App";
import { useContext } from "react";
import FeatherIcon from "./FeatherIcon";
import React from "react";

function BackendDataTable(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    //data contiene tutti i dati presi dal db. Le righe di data possono essere scambiate tra loro per l'ordinamento
    const [data, setData] = useState([]);
    //Per ogni riga in data, dice se deve essere mostrata in quel momento oppure no
    const [viewedData, setViewedData] = useState([]);
    //Pagina visualizzata al momento
    const [pageNum, setPageNum] = useState(1);
    //Lunghezza corrente delle pagine
    const [pageLength, setPageLength] = useState(10);
    //numero totale di righe che contengono la stringa cercata dall'utente
    const [rowsSearched, setRowsSearched] = useState(0);
    //la stringa cercata dall'utente
    const [searchString, setSearchString] = useState("");
    //contiene i nomi delle colonne e il loro stato attuale di ordinamento
    const [columns, setColumns] = useState([]);
    //numero di pagine attuale
    const [numPages, setNumPages] = useState(0);
    //timer che, quando arriva a zero, fa partire loadData
    const [loadDataTimer, setLoadDataTimer] = useState(0);
    //dice quale è stata l'ultima riga sulla quale è stato fatto un singolo click (onClick)
    const [rowClickedIndex, setRowClickedIndex] = useState(null);
    const [viewSubDataTable, setViewSubDataTable] = useState(false);
    const initialTimerValue = 50;
    const loadDataTimeout = useRef(null);
    const [refreshButtonDisabled, setRefreshButtonDisabled] = useState(false);
    const [exportButtonDisabled, setExportButtonDisabled] = useState(false);

    const [columnsNumber, setColumnsNumber] = useState(1);
    const numbers = "0123456789";
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: imposto numero di pagine BackendDataTable.js");
        }
        setNumPages(parseInt(rowsSearched / parseInt(pageLength)) + 1);
    }, [rowsSearched, pageLength]);

    const handlePageNumChange = (event) => {
        let value = parseInt(event.target.value);
        if (value < 1) {
            value = 1;
        }
        setPageNum(value);
        //viewHideRows();
    };

    const handlePageLengthChange = (event) => {
        setPageLength(event.target.value);
        firstPage();
        //viewHideRows();
    };

    const handleSearchStringChange = (event) => {
        let value = event.target.value;
        setSearchString(value);
        setPageNum(1);
        //viewHideRows();
    }

    function nextPage() {
        if (isNaN(parseInt(pageNum))) {
            setPageNum(1);
            //viewHideRows();
            return;
        }
        if (parseInt(pageNum) + 1 <= numPages) {
            setPageNum(parseInt(pageNum) + 1);
            //viewHideRows();
        }
    }

    function prevPage() {
        if (isNaN(parseInt(pageNum))) {
            setPageNum(1);
            //viewHideRows();
            return;
        }
        if (parseInt(pageNum) > 1) {
            setPageNum(parseInt(pageNum) - 1);
            //viewHideRows();
        }
    }

    function firstPage() {
        setPageNum(1);
        //viewHideRows();
    }

    function lastPage() {
        let lastPage = parseInt(rowsSearched / parseInt(pageLength)) + 1;
        setPageNum(lastPage);
        //viewHideRows();
    }

    function getColumnDataType(columnIndex) {
        if (props.columnsDataTypes[columnIndex] !== undefined) {
            return props.columnsDataTypes[columnIndex];
        }
        return "string";
    }

    //ordina la tabella scegliendo una colonna, numerica o lettarale, in ordine crescente o decrescente. L'ordinamento avviene scambiando tra loro le righe su data
    function orderTable(columnIndex, type) {
        let dataType = getColumnDataType(columnIndex);
        //type può essere "asc", "desc" o undefined
        //numeric è boolean
        //bubble sort
        //columnIndex è il nome dell'attributo nel json
        let newData = data.slice();
        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data.length - i - 1; j++) {
                if (type !== undefined) {
                    //ordina per colonna
                    let a = newData[j][columnIndex];
                    let b = newData[j + 1][columnIndex];
                    if (bComesFirst(a, b, type, dataType)) {
                        let temp = JSON.parse(JSON.stringify(newData[j]));
                        newData[j] = JSON.parse(JSON.stringify(newData[j + 1]));
                        newData[j + 1] = JSON.parse(JSON.stringify(temp));
                    }
                } else {
                    //non ordinare per nessuna colonna. Ripristina l'ordine iniziale
                    let a = newData[j].rowId;
                    let b = newData[j + 1].rowId;
                    ////console.log("a = " + a + ", b = " + b);
                    if (bComesFirst(a, b, "asc", "numeric")) {
                        let temp = JSON.parse(JSON.stringify(newData[j]));
                        newData[j] = JSON.parse(JSON.stringify(newData[j + 1]));
                        newData[j + 1] = JSON.parse(JSON.stringify(temp));
                    }
                }
            }
        }
        setData(newData);
    }
    //confronta due valori nella STESSA colonna
    function bComesFirst(a, b, type, dataType) {
        //type è asc o desc
        //numeric è boolean
        if (dataType === "numeric") {
            a = parseFloat(a);
            b = parseFloat(b);
            if (isNaN(a)) {
                if (type == "asc") {
                    return false;
                } else {
                    return true;
                }
            }
            if (isNaN(b)) {
                if (type == "asc") {
                    return true;
                } else {
                    return false;
                }
            }
            if (type === "asc") {
                return b < a;
            } else {
                return b > a;
            }
        } else if (dataType === "price") {
            //ricordati che i prezzi sono in formato "€ 1.555.555,46" e devi saperli ordinare
            a = parseFloat(a.replace("€", "").replace(".", "").replace(",", "."));
            b = parseFloat(b.replace("€", "").replace(".", "").replace(",", "."));
            if (isNaN(a)) {
                if (type == "asc") {
                    return false;
                } else {
                    return true;
                }
            }
            if (isNaN(b)) {
                if (type == "asc") {
                    return true;
                } else {
                    return false;
                }
            }
            if (type === "asc") {
                return b < a;
            } else {
                return b > a;
            }
        } else if (dataType === "string") {
            if (a == null) {
                if (type == "asc") {
                    return false;
                } else {
                    return true;
                }
            }
            if (b == null) {
                if (type == "asc") {
                    return true;
                } else {
                    return false;
                }
            }
            const comparison = a.localeCompare(b);
            if (type === "asc") {
                return comparison > 0;
            } else {
                return comparison < 0;
            }
        } else if (dataType === "date") {
            //console.log("Formati date: " + a.match(/^\d{4}-\d{2}-\d{2}$/) + " " + b.match(/^\d{4}-\d{2}-\d{2}$/));
            if (!isDataFormatoItaliano(a)) {
                return type !== "asc";
            } else if (!isDataFormatoItaliano(b)) {
                return type === "asc";
            } else {
                console.log("a e b vanno entrambe bene");
                let yearA = parseInt(a.substring(6, 10));
                let yearB = parseInt(b.substring(6, 10));
                let monthA = parseInt(a.substring(3, 5));
                let monthB = parseInt(b.substring(3, 5));
                let dayA = parseInt(a.substring(0, 2));
                let dayB = parseInt(b.substring(0, 2));
                if (yearB < yearA) {
                    return type === "asc";
                } else if (yearB === yearA) {
                    if (monthB < monthA) {
                        return type === "asc";
                    } else if (monthB === monthA) {
                        if (dayB < dayA) {
                            return type === "asc";
                        } else if (dayB === dayA) {
                            return false;
                        } else if (dayB > dayA) {
                            return type !== "asc";
                        }
                    } else if (monthB > monthA) {
                        return type !== "asc";
                    }
                } else if (yearB > yearA) {
                    return type !== "asc";
                }
            }
        }
    }

    function isDataFormatoItaliano(data) {
        return data.length == 10 &&
            isNumber(data[0]) &&
            isNumber(data[1]) &&
            data[2] == "/" &&
            isNumber(data[3]) &&
            isNumber(data[4]) &&
            data[5] == "/" &&
            isNumber(data[6]) &&
            isNumber(data[7]) &&
            isNumber(data[8]) &&
            isNumber(data[9]);
    }

    function isNumber(char) {
        return char == "0" || char == "1" || char == "2" || char == "3" || char == "4" || char == "5" || char == "6" || char == "7" || char == "8" || char == "9";
    }

    //aggiorna lo stato di ordinamento delle colonne. L'ordinamento avviene sempre per una sola colonna
    function handleColumnClick(colName) {
        //colName è il nome della colonna
        let columnsTemp = columns.slice();
        //cambia l'ordinamento sulla colonna selezionata e azzera tutti gli altri
        //columnsTemp[colName].orderType = (columnsTemp[colName].orderType + 1) % 3;
        let index = -1;
        for (let i = 0; i < columnsTemp.length; i++) {
            if (columnsTemp[i].name === colName) {
                index = i;
                columnsTemp[i].orderType = (columnsTemp[i].orderType + 1) % 3;
            } else {
                columnsTemp[i].orderType = 0;
            }
        }
        //effettua l'ordinamento giusto
        if (columnsTemp[index].orderType !== 0) {
            //ordinamento per colonna
            if (columnsTemp[index].orderType === 1) {
                orderTable(colName, "asc");
            } else {
                ////console.log("ordine discendente");
                orderTable(colName, "desc");
            }
        } else {
            //nessun ordinamento. ripristina l'ordinamento iniziale
            orderTable(0, undefined);
        }
        setColumns(columnsTemp);
    }

    //sceglie quali righe mostrare e quali nascondere in base alla pagina scelta, alle righe per pagina, ai dati e alla stringa cercata dall'utente.
    function viewHideRows() {
        let rowPosition = 0;
        let rowsSearchedTemp = 0;
        let newViewedData = [];
        for (let i = 0; i < viewedData.length; i++) {
            if (isSearched(i)) {
                rowsSearchedTemp++;
                if (insideCurrentPage(rowPosition)) {
                    newViewedData[i] = true;
                } else {
                    newViewedData[i] = false;
                }
                rowPosition++;
            } else {
                newViewedData[i] = false;
            }
        }
        setViewedData(newViewedData);
        setRowsSearched(rowsSearchedTemp);
    }

    //chiama viewHideRows() quando i dati, il numero di pagina, la lunghezza delle pagine o la stringa di ricerca cambiano
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: mostro e nascondo le righe BackendDataTable");
        }
        viewHideRows();
    }, [data, pageNum, searchString, pageLength]);

    //restituisce true se una determinata riga deve essere mostrata nella pagina corrente
    function insideCurrentPage(rowPosition) {
        if (props.disablePages == true) {
            return true;
        }
        let min = (pageNum - 1) * pageLength;
        let max = ((pageNum - 1 + 1) * pageLength) - 1;
        return rowPosition >= min && rowPosition <= max;
    }

    //restituisce true se una determinata riga contiene la stringa cercata dall'utente
    function isSearched(rowIndex) {
        for (let attr in data[rowIndex]) {
            if (attr !== "rowId" && !props.columnsToExclude.includes(attr) && String(data[rowIndex][attr]).toLowerCase().includes(searchString.toLowerCase())) {
                return true;
            }
        }
        return false;
    }

    //quando l'url della chiamata API cambia, viene caricato tutto il risultato della query in data e vengono resettati gli altri useState
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: timer caricamento dati partito BackendDataTable");
        }
        //Imposta il timer a 100. Un timer che, ogni 10 ms, scende di 10.
        //Quando il timer arriva a 0, fai partire la funzione che c'è sotto
        console.log("Pagina cambiata a " + pageViewed + " dove mi aspetto " + props.loadInsidePage);
        //clearTimeout(loadDataTimeout.current);
        console.log("Ora resetto il timer...");
        //setLoadDataTimer(initialTimerValue);
        loadData();
        console.log("Timer resettato");
    }, [props.reqUrl, pageViewed]);

    //puoi imporre alla backendDataTable di ricaricare i dati mettendo a false la props "dataIsUpdated"
    useEffect(() => {
        if (props.dataIsUpdated != undefined && props.dataIsUpdated == false) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: timer caricamento dati partito BackendDataTable");
            }
            //clearTimeout(loadDataTimeout.current);
            //setLoadDataTimer(initialTimerValue);
            loadData();
            props.setDataIsUpdated(true);
        }
    }, [props.dataIsUpdated]);

    function reload() {
        console.log("Ricarico");
        //clearTimeout(loadDataTimeout.current);
        //setLoadDataTimer(initialTimerValue);
        loadData();
        console.log("Data timer impostato");
        setRefreshButtonDisabled(true);
    }

    function exportCsv() {
        let reqBody = {
            data: data,
            columnsToExclude: props.columnsToExclude,
            fileName: props.fileName + ".csv"
        }
        setLoading(true);
        nodeReq.post(process.env.REACT_APP_API_URL + "/getCsv", reqBody)
            .then((response) => {
                //devi far vedere un toast di download avviato
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", props.fileName + ".csv");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                handleAPIError(error, "esportare il file csv dalla tabella");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    /*
    useEffect(() => {
        console.log("Data timer è cambiato!!");
        if (loadDataTimer <= 0) {
            console.log("pagina visualizzata: " + pageViewed + ", pagina attesa: " + props.loadInsidePage + ", loadDataTimer: " + loadDataTimer);
            loadData();
        } else {
            console.log("pagina visualizzata: " + pageViewed + ", pagina attesa: " + props.loadInsidePage + ", loadDataTimer: " + loadDataTimer);
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: timer non ancora scaduto BackendDataTable");
            }
            //Se loadDataTimer è > 0, tra 10 ms fallo scendere di 10
            loadDataTimeout.current = setTimeout(() => { setLoadDataTimer(loadDataTimer - 10); }, 10);
        }
    }, [loadDataTimer]);
    */

    function loadData() {
        console.log("Chiamata API? 1");
        console.log("Pagina che mi aspetto: " + props.loadInsidePage + ", pagina attuale: " + pageViewed);
        if (pageViewed === props.loadInsidePage) {
            console.log("Chiamata API? 2");
            if (props.reqUrl != "") {
                setRowClickedIndex(null);
                console.log("Chiamata API backendDataTable: " + props.reqUrl);
                setLoading(true);
                nodeReq.get(props.reqUrl)
                    .then(response => {
                        if (response.status === 200) {
                            const beData = response.data.dbdata;
                            let newViewedData = [];
                            if (beData.length > 0) {
                                let keys = Object.keys(beData[0]);
                                for (let i = 0; i < beData.length; i++) {
                                    newViewedData[i] = true;
                                    beData[i].rowId = i;
                                    for (let j = 0; j < keys.length; j++) {
                                        if (props.functionsOnColumns[keys[j]] !== undefined) {
                                            beData[i][keys[j]] = props.functionsOnColumns[keys[j]](beData[i][keys[j]]);
                                        }
                                    }
                                }
                            }
                            setData(beData);
                            if (props.openThisIdSuddenly != undefined && props.openThisIdSuddenly.current != undefined) {
                                console.log("Ora devo aprire questo id: " + props.openThisIdSuddenly.current);
                                for (let i = 0; i < beData.length; i++) {
                                    if (String(beData[i][props.idColumn]) == String(props.openThisIdSuddenly.current)) {
                                        console.log("Ho trovato l'insertid. è il " + props.openThisIdSuddenly.current);
                                        if (props.rowClickFunction != undefined) {
                                            props.rowClickFunction(beData[i]);
                                        }
                                        break;
                                    }
                                }
                                props.openThisIdSuddenly.current = undefined;
                            }
                            setViewedData(newViewedData);
                            setPageNum(1);
                            setSearchString("");
                            //metti anche i nomi delle colonne
                            let columnsTemp = [];
                            let columnNames = [];
                            if (beData.length > 0) {
                                Object.keys(beData[0]).map((key) => {
                                    if (key !== "rowId" && !props.columnsToExclude.includes(key)) {
                                        columnNames.push(key);
                                    }
                                });
                            }
                            for (let i = 0; i < columnNames.length; i++) {
                                columnsTemp[i] = {
                                    name: columnNames[i],
                                    orderType: 0
                                }
                            }
                            setColumns(columnsTemp);
                        } else {
                            genericAlert("Impossibile caricare i dati in questa tabella. Riprova più tardi");
                        }
                    })
                    .catch(error => {
                        console.log("ERRORE DATATABLE");
                        console.log(error);
                        handleAPIError(error, "caricare i dati in questa tabella dalla reqUrl: " + props.reqUrl);
                    })
                    .finally(() => {
                        setLoading(false);
                        setRefreshButtonDisabled(false);
                    });
            }
        }
    }

    useEffect(getColumnsNumber, [data]);

    //gestisci il doppio click sulla riga della tabella
    function rowClick(index) {
        if (props.rowClickFunction != undefined) {
            props.rowClickFunction(data[index]);
        }
    }

    //gestisci il singolo click sulla riga della tabella
    function rowSingleClick(index) {
        if (props.rowSingleClickFunction != undefined) {
            props.rowSingleClickFunction(data[index]);
        }
        if (index != rowClickedIndex) {
            setViewSubDataTable(true);
        } else {
            setViewSubDataTable(!viewSubDataTable);
        }
        setRowClickedIndex(index);
    }

    function formattedColumnName(colName) {
        let toReturn = "";
        for (let i = 0; i < colName.length; i++) {
            if (colName[i].toUpperCase() !== colName[i]) {
                //è minuscola
                toReturn += colName[i];
            } else {
                //è maiuscola
                toReturn += " " + colName[i];
            }
        }
        toReturn = toReturn[0].toUpperCase() + toReturn.substring(1);
        return toReturn;
    }

    function getColumnsNumber() {
        if (data != undefined && Array.isArray(data) && data.length >= 1) {
            let keys = Object.keys(data[0]);
            let count = 0;
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] !== "rowId" && !props.columnsToExclude.includes(keys[i])) {
                    count++;
                }
            }
            if (props.actionButtonFunction != undefined) {
                count++;
            }
            setColumnsNumber(count);
        }
    }

    return <div style={{ display: props.show ? "flex" : "none", flexDirection: "column", maxHeight: "100%" }}>
        {/*Casella di ricerca */}
        {props.disableSearch != true &&
            <div className="d-flex flex-row">
                <div className="p-1">
                    <button className="btn btn-light" onClick={reload} disabled={refreshButtonDisabled}><FeatherIcon iconName="refresh-ccw" /></button>
                    <button className="btn btn-light ms-2" onClick={exportCsv} disabled={exportButtonDisabled || data.length == 0}><FeatherIcon iconName="download" />&nbsp;csv</button>
                </div>
                <div className="form-group flex-grow-1 p-1">
                    <input type="text" onChange={handleSearchStringChange} id={props.tableId + "-searchInTable"} value={searchString} placeholder="Cerca" className="form-control" />
                </div>
            </div>
        }
        {props.disablePages != true &&
            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: 'wrap' }}>
                {/*Selezione della pagina */}
                <div>
                    <div>
                        <button className="btn btn-light form-control d-inline m-1" onClick={firstPage} style={{ width: "48px" }}><FeatherIcon iconName="chevrons-left" /></button>
                        <button className="btn btn-light form-control d-inline m-1" onClick={prevPage} style={{ width: "48px" }}><FeatherIcon iconName="chevron-left" /></button>
                        <input className="form-control d-inline m-1" type="number" id={props.tableId + "-pageNumDiv"} onChange={handlePageNumChange} style={{ width: "64px" }} min="1" placeholder="Pagina" value={pageNum} />
                        <button className="btn btn-light form-control d-inline m-1" onClick={nextPage} style={{ width: "48px" }}><FeatherIcon iconName="chevron-right" /></button>
                        <button className="btn btn-light form-control d-inline m-1" onClick={lastPage} style={{ width: "48px" }}><FeatherIcon iconName="chevrons-right" /></button>
                    </div>
                </div>
                {/* Righe per pagina*/}
                <div>
                    <div id={props.tableId + "-pageLengthDiv"}>
                        <select onChange={handlePageLengthChange} className="form-control d-inline m-1" style={{ width: "56px" }} value={pageLength}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <p className="d-inline">righe/pag</p>
                    </div>
                </div>
            </div>
        }
        {/* Sto mostrando le righe x-y su un totale di z righe*/}
        {props.disablePages != true &&
            <div>
                <p>Stai visualizzando {rowsSearched} righe su {numPages} {numPages === 1 ? "pagina" : "pagine"}.</p>
            </div>
        }
        {/*Div contenente la tabella */}
        <div className="w-full overflow-x-auto flex-grow-1">
            {data.length > 0 &&
                <table className="table table-hover" style={{ height: "100%", overflow: "hidden" }}>
                    <thead>
                        <tr>
                            {columns.map((item, index) => (
                                <th key={index} style={{ position: "sticky", top: "0", opacity: "1", backgroundColor: "white" }} onClick={props.disableSorting != true ? () => { handleColumnClick(item.name); } : () => { }}>
                                    <div className="d-flex flex-row">
                                        <div className="d-flex align-items-end">
                                            {props.disableSorting != true && <TableArrows type={item.orderType} />}
                                        </div>
                                        <div>
                                            {formattedColumnName(item.name)}
                                        </div>
                                    </div>
                                </th>
                            ))}
                            {props.actionButtonFunction != undefined &&
                                <th style={{ whiteSpace: "nowrap", position: "sticky", top: "0", opacity: "1", backgroundColor: "white" }}>&nbsp;</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <>
                                {viewedData[index] &&
                                    <React.Fragment key={index} >
                                        <tr style={{ backgroundColor: (index == rowClickedIndex && props.colorizeSelectedRow == true ? process.env.REACT_APP_DATA_TABLE_SELECTED_ROW_COLOR : "rgba(0,0,0,0)") }} onDoubleClick={() => { rowClick(index); }} onClick={() => { rowSingleClick(index); }}>
                                            {Object.keys(item).map((key) => {
                                                if (key !== "rowId" && !props.columnsToExclude.includes(key)) {
                                                    return <td style={{ whiteSpace: "nowrap", color: (props.columnsColorsFunctions == undefined || props.columnsColorsFunctions[key] == undefined ? undefined : props.columnsColorsFunctions[key](item[key])) }} key={key}>{item[key]}</td>;
                                                }
                                                return null;
                                            })}
                                            {props.actionButtonFunction != undefined &&
                                                <td><button onClick={() => { props.actionButtonFunction(item); }} className="btn btn-light"><div className="d-flex flex-row"><div>{props.actionButtonIcon != undefined && <><FeatherIcon iconName={props.actionButtonIcon} /></>}</div><div>&nbsp;{props.actionButtonName}</div></div></button></td>
                                            }
                                        </tr>
                                        {viewSubDataTable && rowClickedIndex == index && props.subDataTableReqUrl != undefined &&
                                            <tr>
                                                <td style={{ backgroundColor: "#f5f5f5" }} colSpan={columnsNumber}>
                                                    <div>
                                                        <h5 className="card-title">{props.subDataTableTitle}</h5>
                                                        <BackendDataTable rowClickFunction={() => { }} loadInsidePage={pages.Contabilita} tableId={"seduteFatturaSubTable"} reqUrl={props.subDataTableReqUrl(item)} show={true} columnsToExclude={[]} functionsOnColumns={[]} columnsDataTypes={[]} disablePages={true} disableSearch={true} disableSorting={true} />
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </React.Fragment>
                                }
                            </>
                        ))}
                    </tbody>
                </table>
            }
            {data.length === 0 && <p>Niente da mostrare</p>}
        </div>

    </div >;
}

export default BackendDataTable;
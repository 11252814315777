import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ContextGestionale } from "../../App";
import SearchAndSelect from "../SearchAndSelect";
import PrezzoServizioModalDialog from '../modals/PrezzoServizioModalDialog';
import APICallButton from "../APICallButton";
import Heading from "../Heading";

function ServizioCreateUpdate(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
    const servizioCreateUpdateViewed = (pageViewed === pages.NuovoServizio || pageViewed === pages.Servizio);
    const [assegnaProfessionistaDisabled, setAssegnaProfessionistaDisabled] = useState(true);
    const [rimuoviProfessionistaDisabled, setRimuoviProfessionistaDisabled] = useState(true);
    const [impostaTariffaDisabled, setImpostaTariffaDisabled] = useState(true);
    const [isShowPrezzoServizioModal, setShowPrezzoServizioModal] = useState(false);
    const [selectedProfessionisti, setSelectedProfessionisti] = useState([]);
    const [datiValidi, setDatiValidi] = useState(false);
    const tipoServizio = ["Con tariffa oraria", "Con tariffa a seduta", "Prepagabile (solo per certificazioni)"];
    const [ultimaTariffaValida, setUltimaTariffaValida] = useState(0);
    const [ultimoCostoProfessionistaValido, setUltimoCostoProfessionistaValido] = useState(0);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        props.setServizioFormValues(prevState => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value
        }));
    };

    function showPrezzoServizioModal() {
        setShowPrezzoServizioModal(true);
    }
    function handleClosePrezzoServizioModal() {
        setShowPrezzoServizioModal(false);
    }

    function getProfessionistaText(element) {
        return element.cognomeProfessionista + " " + element.nomeProfessionista + (element.assigned ? (" (Tariffa: " + importoConPuntoEVirgola(element.tariffa) + (element.tipoServizio != 0 ? "" : "/ora") + ", Costo professionista: " + importoConPuntoEVirgola(element.costoProfessionistaAssegnato) + (element.tipoServizio != 0 ? "" : "/ora")) : "");
    }

    function assegnazioneProfessionista(assign) {
        if (datiValidi) {
            let listaProfessionistiTemp = listaProfessionisti.slice();
            for (let i = 0; i < listaProfessionistiTemp.length; i++) {
                //Se stai assegnando, il servizio è non assegnato ed è selezionato...
                if (assign && listaProfessionistiTemp[i].assigned == false && selectedProfessionisti.includes(listaProfessionistiTemp[i].id.toString())) {
                    listaProfessionistiTemp[i].assigned = true;
                    listaProfessionistiTemp[i].notAssigned = false;
                    listaProfessionistiTemp[i].tariffa = props.servizioFormValues.tariffaDefault;
                    listaProfessionistiTemp[i].costoProfessionistaAssegnato = props.servizioFormValues.costoProfessionista;
                }
                //Se stai disassegnando, il servizio è assegnato ed è selezionato...
                if (!assign && listaProfessionistiTemp[i].assigned == true && selectedProfessionisti.includes(listaProfessionistiTemp[i].id.toString())) {
                    listaProfessionistiTemp[i].assigned = false;
                    listaProfessionistiTemp[i].notAssigned = true;
                    listaProfessionistiTemp[i].tariffa = props.servizioFormValues.tariffaDefault;
                    listaProfessionistiTemp[i].costoProfessionistaAssegnato = props.servizioFormValues.costoProfessionista;
                }
            }
            setListaProfessionisti(listaProfessionistiTemp);
            setSelectedProfessionisti([]);
        } else {
            genericAlert("Inserisci due prezzi validi e un nome al servizio");
        }
    }

    function disassegnaProfessionisti() {
        let listaProfessionistiTemp = listaProfessionisti.slice();
        for (let i = 0; i < listaProfessionistiTemp.length; i++) {
            listaProfessionistiTemp[i].assigned = false;
            listaProfessionistiTemp[i].notAssigned = true;
            listaProfessionistiTemp[i].tariffa = props.servizioFormValues.tariffaDefault;
            listaProfessionistiTemp[i].costoProfessionistaAssegnato = props.servizioFormValues.costoProfessionista;
        }
        setListaProfessionisti(listaProfessionistiTemp);
        setSelectedProfessionisti([]);
    }

    function impostaTariffa() {
        if (datiValidi) {
            showPrezzoServizioModal();
        } else {
            genericAlert("Inserisci due prezzi validi e un nome al servizio valido (con al massimo " + process.env.REACT_APP_NOME_SERVIZIO_MAX_CARATTERI + " caratteri) per poter aggiungere o rimuovere professionisti");
        }
    }

    function saveServizio() {
        if (datiValidi) {
            //Crea la listaProfessionistiAssegnati, che contiene solo i professionisti assegnati contenuti in listaProfessionisti
            let contaAssegnati = 0;
            for (let i = 0; i < listaProfessionisti.length; i++) {
                if (listaProfessionisti[i].assigned) {
                    contaAssegnati++;
                }
            }
            let listaProfessionistiAssegnati = new Array(contaAssegnati).fill({});
            let listaProfessionistiAssegnatiIndex = 0;
            for (let i = 0; i < listaProfessionisti.length; i++) {
                if (listaProfessionisti[i].assigned) {
                    listaProfessionistiAssegnati[listaProfessionistiAssegnatiIndex] = JSON.parse(JSON.stringify(listaProfessionisti[i]));
                    listaProfessionistiAssegnatiIndex++;
                }
            }
            for (let i = 0; i < listaProfessionistiAssegnati.length; i++) {
                delete listaProfessionistiAssegnati[i].assigned;
                delete listaProfessionistiAssegnati[i].nomeProfessionista;
                delete listaProfessionistiAssegnati[i].cognomeProfessionista;
                delete listaProfessionistiAssegnati[i].id;
                delete listaProfessionistiAssegnati[i].tipoServizio;
                delete listaProfessionistiAssegnati[i].tariffaDefault;
                delete listaProfessionistiAssegnati[i].costoProfessionista;
            }
            let reqBody = { servizioFormValues: props.servizioFormValues, specializzazioni: listaProfessionistiAssegnati };
            setLoading(true); nodeReq.post(process.env.REACT_APP_API_URL + "/servizio", reqBody)
                .then(response => {
                    if (response.status == 200) {
                        genericAlert("Servizio salvato correttamente.");
                        //se sei in modalità nuovo servizio, ripulisci tutto
                        if (pageViewed === pages.NuovoServizio) {
                            props.setServizioFormValues(props.initialServizioFormValues);
                            props.setServizioFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.initialServizioFormValues)));
                            disassegnaProfessionisti();
                        } else {
                            props.setServizioFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.servizioFormValues)));
                            setListaProfessionistiBeforeEdits(JSON.parse(JSON.stringify(listaProfessionisti)));
                        }
                    } else {
                        genericAlert("Impossibile salvare questo servizio. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    if (error.response != undefined && error.response.status == 400 && error.response.data == "serviceNameAlreadyExists") {
                        genericAlert("Il nome specificato è già in uso per un altro servizio");
                    } else {
                        handleAPIError(error, "salvare questo servizio");
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    //in base ai servizi selezionati, abilita e disabilita i pulsanti "Assegna", "Disassegna" e "Imposta tariffa"
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: abilito e disabilito i pulsanti assegna, disassegna e imposta tariffa (crea/modifica servizio)");
        }
        let atLeastOneAssignedSelected = false;
        let onlyOneAssignedSelected = false;
        let atLeastOneNotAssignedSelected = false;
        for (let i = 0; i < listaProfessionisti.length; i++) {
            //se il professionista è selezionato e assegnato...
            if (listaProfessionisti[i].assigned && selectedProfessionisti.includes(listaProfessionisti[i].id.toString())) {
                if (!atLeastOneAssignedSelected) {
                    atLeastOneAssignedSelected = true;
                    onlyOneAssignedSelected = true;
                } else {
                    onlyOneAssignedSelected = false;
                }
            }
            //se il professionista è selezionato e non assegnato...
            if (!listaProfessionisti[i].assigned && selectedProfessionisti.includes(listaProfessionisti[i].id.toString())) {
                atLeastOneNotAssignedSelected = true;
            }
            if (atLeastOneAssignedSelected && !onlyOneAssignedSelected && atLeastOneNotAssignedSelected) {
                break;
            }
        }
        setAssegnaProfessionistaDisabled(!atLeastOneNotAssignedSelected);
        setRimuoviProfessionistaDisabled(!atLeastOneAssignedSelected);
        setImpostaTariffaDisabled(!onlyOneAssignedSelected);
    }, [selectedProfessionisti]);


    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: controllo validità form crea/aggiorna/servizio");
        }
        setDatiValidi(props.servizioFormValues.nomeServizio != "" && !isNaN(props.servizioFormValues.tariffaDefault) && parseFloat(props.servizioFormValues.tariffaDefault) >= 0 && !isNaN(props.servizioFormValues.costoProfessionista) && parseFloat(props.servizioFormValues.costoProfessionista) >= 0 && props.servizioFormValues.nomeServizio.length <= process.env.REACT_APP_NOME_SERVIZIO_MAX_CARATTERI && props.servizioFormValues.descrizioneServizio.length <= process.env.REACT_APP_DESCRIZIONE_SERVIZIO_MAX_CARATTERI);
    }, [props.servizioFormValues.tariffaDefault, props.servizioFormValues.costoProfessionista, props.servizioFormValues.nomeServizio]);

    useEffect(() => {
        if (!isNaN(props.servizioFormValues.tariffaDefault) && parseFloat(props.servizioFormValues.tariffaDefault) > 0 && !isNaN(props.servizioFormValues.costoProfessionista) && parseFloat(props.servizioFormValues.costoProfessionista) > 0) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: tariffa o costoProfessionista del servizio aggiornati anche sui professionisti");
            }
            let listaProfessionistiTemp = listaProfessionisti.slice();
            for (let i = 0; i < listaProfessionistiTemp.length; i++) {
                if (listaProfessionistiTemp[i].tariffa == ultimaTariffaValida && listaProfessionistiTemp[i].costoProfessionistaAssegnato == ultimoCostoProfessionistaValido) {
                    listaProfessionistiTemp[i].tariffa = props.servizioFormValues.tariffaDefault;
                    listaProfessionistiTemp[i].costoProfessionistaAssegnato = props.servizioFormValues.costoProfessionista;
                }
            }
            setListaProfessionisti(listaProfessionistiTemp);
            setUltimoCostoProfessionistaValido(props.servizioFormValues.costoProfessionista);
            setUltimaTariffaValida(props.servizioFormValues.tariffaDefault);
        }
    }, [props.servizioFormValues.tariffaDefault, props.servizioFormValues.costoProfessionista]);

    useEffect(() => {
        if (servizioCreateUpdateViewed) {
            if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
                console.log("useEffect: pagina crea/modifica servizio aperta");
            }
            //TODO: carica la lista dei professionisti. Devi chiamare un endpoint che tiri fuori tutti i professionisti e la loro (eventuale) specializzazione in quel servizio. sarà un left join con a sinistra gli utenti professionisti e a destra le specializzazioni
            let params = props.servizioFormValues.idServizioToUpdate != undefined ? "?idServizio=" + props.servizioFormValues.idServizioToUpdate : "";
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/professionistiespecializzazioni" + params)
                .then(response => {
                    if (response.status == 200) {
                        let data = response.data.dbdata;
                        let data2 = new Array(data.length).fill({});
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].tipoServizio != null) { //il servizio è assegnato
                                data[i].assigned = true;
                                data[i].notAssigned = false;
                            } else {
                                data[i].assigned = false;
                                data[i].notAssigned = true;
                            }
                            data[i].id = data[i].idUtente;
                            data2[i] = JSON.parse(JSON.stringify(data[i]));
                        }
                        setListaProfessionisti(data);
                        setListaProfessionistiBeforeEdits(data2);
                    } else {
                        genericAlert("Impossibile recuperare la lista dei professionisti. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "recuperare la lista dei professionisti");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [pageViewed]);
    return (
        <>
            <div style={{ display: servizioCreateUpdateViewed ? 'block' : 'none' }}>
                {pageViewed === pages.Servizio &&
                    <>
                        <Heading title="Lista servizi" iconName="grid" />
                        <h1 className="h3 mb-3"><b>Dettagli servizio</b></h1>
                    </>
                }
                {/*Heading*/}
                {pageViewed === pages.NuovoServizio &&
                    <Heading title="Nuovo servizio" iconName="grid" />
                }
                {pageViewed === pages.Servizio &&
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <button className="btn btn-primary m-1" onClick={() => { checkUnsavedBeforeDoing(() => { setPageViewed(pages.Servizi); }); }}>
                                    Indietro
                                </button>
                            </div>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Informazioni di base</h5>
                            </div>
                            <div className="card-body">
                                <label htmlFor="nomeServizio">Nome servizio</label>
                                <input type="text" className="form-control" id="nomeServizio" name="nomeServizio" value={props.servizioFormValues.nomeServizio} onChange={handleChange} />
                                <label htmlFor="descrizioneServizio">Descrizione</label>
                                <input type="text" className="form-control" id="descrizioneServizio" name="descrizioneServizio" value={props.servizioFormValues.descrizioneServizio} onChange={handleChange} />

                                <div className="mt-2 mb-2">
                                    <label htmlFor="tipoServizio">Tipo servizio</label>
                                    <select className="form-control" id="tipoServizio" name="tipoServizio" value={props.servizioFormValues.tipoServizio} disabled={pageViewed === pages.Servizio} onChange={pageViewed === pages.NuovoServizio ? handleChange : () => { }}>
                                        <option value="0">{tipoServizio[0]}</option>
                                        <option value="1">{tipoServizio[1]}</option>
                                        <option value="2">{tipoServizio[2]}</option>
                                    </select>
                                </div>
                                <label htmlFor="inputTariffa">Tariffa</label>
                                <div className="input-group" id="inputTariffa">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">{props.servizioFormValues.tipoServizio != 0 ? "€" : "€/ora"}</div>
                                    </div>
                                    <input type="number" min="0.00" step="0.01" name="tariffaDefault" className="form-control mb-1" value={props.servizioFormValues.tariffaDefault} onChange={handleChange} />
                                </div>
                                <label htmlFor="inputCostoProfessionista">Costo professionista</label>
                                <div className="input-group" id="inputCostoProfessionista">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">{props.servizioFormValues.tipoServizio != 0 ? "€" : "€/ora"}</div>
                                    </div>
                                    <input type="number" min="0.00" step="0.01" name="costoProfessionista" className="form-control mb-1" value={props.servizioFormValues.costoProfessionista} onChange={handleChange} onScroll={(event) => { event.preventDefault(); }} />
                                </div>
                                {!datiValidi && <p className="text-danger">Inserire un nome al servizio non più lungo di {process.env.REACT_APP_NOME_SERVIZIO_MAX_CARATTERI} caratteri e due prezzi (tariffa e costo professionista) positivi</p>}
                            </div>
                        </div>
                    </div>
                    {/* Assegnazione del servizio*/}
                    <div className="col-lg">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="card-title">Professionisti specializzati {props.servizioFormValues.nomeServizio != "" ? "in " + props.servizioFormValues.nomeServizio : ""}</h5>
                            </div>
                            <div className="card-body">
                                {/* Professionisti specializzati*/}
                                {servizioCreateUpdateViewed && <SearchAndSelect title={"Specializzati"} viewedVariable={"assigned"} elements={listaProfessionisti} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} hideBottomText={true} getElementText={getProfessionistaText} />}
                                {/*Pulsanti Assegna/Rimuovi e imposta tariffa*/}
                                {pageViewed != pages.MieInfo &&
                                    <section>
                                        <button className="btn btn-secondary m-1" disabled={assegnaProfessionistaDisabled} onClick={() => { assegnazioneProfessionista(true); }} >Aggiungi</button>
                                        <button className="btn btn-secondary m-1" disabled={rimuoviProfessionistaDisabled} onClick={() => { assegnazioneProfessionista(false); }} >Rimuovi</button>
                                        <button className="btn btn-secondary m-1" disabled={impostaTariffaDisabled} onClick={impostaTariffa} style={{ display: "inline" }}>Imposta tariffa</button>
                                    </section>
                                }
                                {/* Servizi non assegnati*/}
                                {servizioCreateUpdateViewed && <SearchAndSelect title={"Non specializzati"} viewedVariable={"notAssigned"} elements={listaProfessionisti} allowMultiple={true} showDeselectButton={true} validSelection={true} setValidSelection={(val) => { }} required={false} selectedOptions={selectedProfessionisti} setSelectedOptions={setSelectedProfessionisti} hideBottomText={true} getElementText={getProfessionistaText} />}
                            </div>
                        </div>
                    </div>
                </div>
                {/*Pulsante Salva*/}
                <div className="row">
                    <div className="card">
                        <div className="card-body">
                            <APICallButton buttonClassName="btn btn-primary m-1" buttonOnClick={saveServizio} buttonDisabled={!datiValidi} buttonText="Salva" nomeProcedura={"il salvataggio del servizio"}/>
                        </div>
                    </div>
                </div>
                <PrezzoServizioModalDialog showmodal={isShowPrezzoServizioModal} closemodal={handleClosePrezzoServizioModal} listaServizi={listaProfessionisti} setListaServizi={setListaProfessionisti} selectedServizi={selectedProfessionisti} tipoServizio={props.servizioFormValues.tipoServizio} tariffaDefault={props.servizioFormValues.tariffaDefault} costoProfessionista={props.servizioFormValues.costoProfessionista} nomeServizio={props.servizioFormValues.nomeServizio} />
            </div>
        </>
    );
}

export default ServizioCreateUpdate;
import React from "react";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import Heading from "../Heading";
import FeatherIcon from "../FeatherIcon";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

function Statistiche(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    const mesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
    const [mese, setMese] = useState(new Date().getUTCMonth() + 1);
    const [anno, setAnno] = useState(new Date().getUTCFullYear());
    const monthsContainer = useRef(null);
    const minMonthWidth = 72; // in pixel
    const resizeTimeout = useRef(undefined);
    const [monthWidth, setMonthWidth] = useState(minMonthWidth);
    const [statistiche, setStatistiche] = useState(undefined);
    const [seduteMax, setSeduteMax] = useState(1);

    function updateMonthWidth() {
        if (monthsContainer.current != null && monthsContainer.current != undefined && monthsContainer.current.offsetWidth > 0) {
            let totWidth = monthsContainer.current.offsetWidth;
            let buttonsThatCanFit = Math.min(parseInt(totWidth / minMonthWidth), 12);
            setMonthWidth((totWidth - 1.0) / (buttonsThatCanFit + 0.0));
        }
    }

    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: pagina contabilita aperta 2");
        }
        //fai una prima impostazione dell'altezza delle fullcalendar
        firstMonthWidthSet();
        // Aggiungi il listener per l'evento di ridimensionamento della finestra
        window.addEventListener('resize', () => { clearTimeout(resizeTimeout.current); resizeTimeout.current = setTimeout(updateMonthWidth, 500); });
    }, []);

    //all'apertura della pagina o al cambio del mese, carica le statistiche
    useEffect(() => {
        if (pageViewed === pages.Statistiche) {
            setStatistiche(undefined);
            setLoading(true);
            nodeReq.get(process.env.REACT_APP_API_URL + "/statistiche?mese=" + mese + "&anno=" + anno)
                .then(response => {
                    if (response.status == 200) {
                        setStatistiche(response.data);
                        if (response.data.sedutePerProfessionista != undefined && response.data.sedutePerProfessionista.length > 0) {
                            let seduteMaxTemp = 1;
                            for (let i = 0; i < response.data.sedutePerProfessionista.length; i++) {
                                if (response.data.sedutePerProfessionista[i].numeroSedute > seduteMaxTemp) {
                                    seduteMaxTemp = response.data.sedutePerProfessionista[i].numeroSedute;
                                }
                            }
                            setSeduteMax(seduteMaxTemp);
                        }
                    } else {
                        genericAlert("Impossibile caricare le statistiche al momento a causa di un errore. Riprova più tardi.");
                    }
                })
                .catch(error => {
                    handleAPIError(error, "caricare le statistiche");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [pageViewed, mese, anno]);

    function firstMonthWidthSet() {
        if (monthsContainer.current != null && monthsContainer.current != undefined && monthsContainer.current.offsetWidth > 0) {
            updateMonthWidth();
            return;
        }
        setTimeout(firstMonthWidthSet, 50);
    }
    return (
        <div style={{ display: pageViewed === pages.Statistiche ? 'block' : 'none' }}>
            <Heading title="Statistiche" iconName="trending-up" />
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex flex-row">
                            <div className="flex-grow-1">
                                <h5 className="card-title">Mese</h5>
                            </div>
                            <div>
                                <input type="number" className="form-control" style={{ width: "96px" }} value={anno} onChange={(event) => { setAnno(isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)); }} />
                            </div>
                        </div>
                        <div className="card-body">
                            <div ref={monthsContainer} style={{ width: "100%" }}>
                                {mesi.map((nomeMese, index) => {
                                    return <div key={index} style={{ width: monthWidth + "px", padding: "4px", display: "inline-block" }}><button style={{ width: "100%", overflow: "hidden" }} className={"btn " + (mese == index + 1 ? "btn-primary" : "btn-light")} onClick={() => { setMese(index + 1); }}><p className="mb-1 text-nowrap">{nomeMese.substring(0, 3)}</p></button></div>;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Sedute effettuate</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <FeatherIcon addedClassName="align-middle" iconName="clipboard" />
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3 text-primary">{statistiche != undefined ? (statistiche.seduteTotali != undefined ? statistiche.seduteTotali : "--") : "caricamento..."}</h1>
                            <div className="mb-0" >
                                <span className="text-primary"> <i className="mdi mdi-arrow-bottom-right"></i>{statistiche != undefined ? (statistiche.seduteDaFatturare != undefined ? statistiche.seduteDaFatturare : "--") : "caricamento..."}</span>
                                <span className="text-muted"> da fatturare</span>
                            </div>
                            <div className="mb-0" >
                                <span className="text-primary"> <i className="mdi mdi-arrow-bottom-right"></i>{statistiche != undefined ? (statistiche.seduteFatturate != undefined ? statistiche.seduteFatturate : "--") : "caricamento..."}</span>
                                <span className="text-muted"> già fatturate</span>
                            </div>
                            <div className="mb-0" >
                                <span className="text-primary"> <i className="mdi mdi-arrow-bottom-right"></i>{statistiche != undefined ? (statistiche.seduteCertificazioni != undefined ? statistiche.seduteCertificazioni : "--") : "caricamento..."}</span>
                                <span className="text-muted"> legate a certificazioni</span>
                            </div>
                            <div className="mb-0" >
                                <span className="text-danger"> <i className="mdi mdi-arrow-bottom-right"></i>{statistiche != undefined ? (statistiche.seduteNonSegnate != undefined ? statistiche.seduteNonSegnate : "--") : "caricamento..."}</span>
                                <span className="text-muted"> non segnate</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Certificazioni concluse</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <FeatherIcon addedClassName="align-middle" iconName="clipboard" />
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3 text-primary">{statistiche != undefined ? (statistiche.certificazioniTotali != undefined ? statistiche.certificazioniTotali : "--") : "caricamento..."}</h1>
                            <div className="mb-0" >
                                <span className="text-primary"> <i className="mdi mdi-arrow-bottom-right"></i>{statistiche != undefined ? (statistiche.certificazioniDaFatturare != undefined ? statistiche.certificazioniDaFatturare : "--") : "caricamento..."}</span>
                                <span className="text-muted"> da fatturare</span>
                            </div>
                            <div className="mb-0" >
                                <span className="text-primary"> <i className="mdi mdi-arrow-bottom-right"></i>{statistiche != undefined ? (statistiche.certificazioniFatturate != undefined ? statistiche.certificazioniFatturate : "--") : "caricamento..."}</span>
                                <span className="text-muted"> già fatturate</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Professionisti che hanno effettuato sedute</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <FeatherIcon addedClassName="align-middle" iconName="users" />
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3 text-primary">{statistiche != undefined ? (statistiche.professionistiCheHannoEffettuatoSedute != undefined ? statistiche.professionistiCheHannoEffettuatoSedute : "--") : "caricamento..."}</h1>
                        </div>
                    </div>
                </div>
                <div className="col-sm">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col mt-0">
                                    <h5 className="card-title">Pazienti visitati</h5>
                                </div>
                                <div className="col-auto">
                                    <div className="stat text-primary">
                                        <FeatherIcon addedClassName="align-middle" iconName="users" />
                                    </div>
                                </div>
                            </div>
                            <h1 className="mt-1 mb-3 text-primary">{statistiche != undefined ? (statistiche.pazientiVisitati != undefined ? statistiche.pazientiVisitati : "--") : "caricamento..."}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Sedute effettuate per professionista</h5>
                        </div>
                        <div className="card-body">
                            <div className="d-flex flex-row">
                                <div style={{ paddingRight: "8px" }}>
                                    {statistiche != undefined &&
                                        <>
                                            {
                                                (statistiche.sedutePerProfessionista == undefined || statistiche.sedutePerProfessionista == null) &&
                                                <p>---</p>
                                            }
                                            {statistiche.sedutePerProfessionista != undefined && statistiche.sedutePerProfessionista != null && statistiche.sedutePerProfessionista.length == 0 &&
                                                <p>Nessuna seduta è stata segnata come effettuata in questo mese</p>
                                            }
                                            {statistiche.sedutePerProfessionista != undefined && statistiche.sedutePerProfessionista != null && statistiche.sedutePerProfessionista.length > 0 &&
                                                <>
                                                    {statistiche.sedutePerProfessionista.map((item, index) => {
                                                        return <p className="text-truncate" key={index}><b>{item.professionista}</b>:</p>
                                                    })}
                                                </>
                                            }
                                        </>
                                    }
                                    {statistiche == undefined &&
                                        <p>---</p>
                                    }
                                </div>
                                <div className="flex-grow-1">
                                        {statistiche != undefined &&
                                            <>
                                                {statistiche.sedutePerProfessionista != undefined && statistiche.sedutePerProfessionista != null && statistiche.sedutePerProfessionista.length > 0 &&
                                                    <>
                                                        {statistiche.sedutePerProfessionista.map((item, index) => {
                                                            return <p style={{paddingLeft: "4px", borderRadius: "3px", width: (item.numeroSedute / seduteMax) * 100 + "%" }} className="text-truncate bg-primary text-white" key={index}>{item.numeroSedute}</p>
                                                        })}
                                                    </>
                                                }
                                            </>
                                        }
                                        {statistiche == undefined &&
                                            <p>---</p>
                                        }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Statistiche;
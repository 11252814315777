import React from "react";
import { useContext } from "react";
import { ContextGestionale } from "../../App";
import Heading from "../Heading";
import { useState } from "react";
import { useEffect } from "react";
import ModalSovrapposizioni from "../modals/ModalSovrapposizioni";
import ModalPrompt from "../modals/ModalPrompt";
import DettagliPrenotazione from "../DettagliPrenotazione";
import Flatpickr from "react-flatpickr";
import { Italian } from "flatpickr/dist/l10n/it.js";
function PrenotazioneCreateUpdate(props) {
    const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista } = useContext(ContextGestionale);
    //vale [] se non ci sono sovrapposizioni, undefined se c'è un errore del backend, null se il form deve ancora essere compilato correttamente
    const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    const [sovrapposizioni, setSovrapposizioni] = useState(null);
    const [isShowSovrapposizioni, setShowSovrapposizioni] = useState(false);
    const [formValido, setFormValido] = useState(false);
    const [isShowConfermaPrenotazione, setShowConfermaPrenotazione] = useState(false);
    const avviso = "Per salvare la prenotazione, inserisci delle date e degli orari validi e specifica il motivo della prenotazione senza superare i " + process.env.REACT_APP_MOTIVO_PRENOTAZIONE_MAX_CARATTERI + " caratteri.";
    function testoPeriodoPrenotazione(dataOraInizio, dataOraFine) {
        if (dataOraInizio.substring(0, 10) === dataOraFine.substring(0, 10)) {
            return "Il " + dataFormatoItaliano(dataOraInizio.substring(0, 10)) + ", dalle " + dataOraInizio.substring(11, dataOraInizio.length) + " alle " + dataOraFine.substring(11, dataOraFine.length);
        } else {
            return "Dalle ore " + dataOraInizio.substring(11, dataOraInizio.length) + " del " + dataFormatoItaliano(dataOraInizio.substring(0, 10)) + " alle ore " + dataOraFine.substring(11, dataOraFine.length) + " del " + dataFormatoItaliano(dataOraFine.substring(0, 10));
        }
    }



    function savePrenotazione() {
        if (prenotazioneFormValues.motivo.length > 0 && prenotazioneFormValues.motivo.length <= process.env.REACT_APP_MOTIVO_PRENOTAZIONE_MAX_CARATTERI) {
            checkUserLoggedBeforeDoing(() => {
                let reqBody = JSON.parse(JSON.stringify(prenotazioneFormValues));
                reqBody.dataOraInizio = reqBody.dataOraInizio.substring(0, 10) + " " + reqBody.dataOraInizio.substring(11, 16);
                reqBody.dataOraFine = reqBody.dataOraFine.substring(0, 10) + " " + reqBody.dataOraFine.substring(11, 16);
                setLoading(true);
                nodeReq.post(process.env.REACT_APP_API_URL + "/prenotazione", { prenotazioneFormValues: reqBody })
                    .then(response => {
                        if (response.status == 200) {
                            genericAlert("La prenotazione generica è stata salvata correttamente.");
                            if (pageViewed === pages.NuovaPrenotazione) {
                                setPrenotazioneFormValues(props.nuovaPrenotazioneFormValues);
                                setPrenotazioneFormValuesBeforeEdits(JSON.parse(JSON.stringify(props.nuovaPrenotazioneFormValues)));
                            }
                        } else {
                            genericAlert("Impossibile salvare la prenotazione generica a causa di un errore. Riprova più tardi");
                        }
                    })
                    .catch(error => {
                        if (error.response != undefined && error.response.status == 400) {
                            genericAlert("Impossibile trovare la prenotazione generica che si sta aggiornando. Potrebbe essere stata eliminata");
                        } else {
                            handleAPIError(error, "salvare la prenotazione generica");
                        }
                    })
                    .finally(() => {
                        setShowConfermaPrenotazione(false);
                        setLoading(false);
                    });
            });
        } else {
            genericAlert("Specificare il motivo della prenotazione");
        }
    }

    function caricaSovrapposizioni() {
        if (prenotazioneFormValues.idArea != "Altro") {
            let idPrenotazioneToUpdateString = "";
            if (prenotazioneFormValues.idPrenotazioneToUpdate != undefined) {
                idPrenotazioneToUpdateString = "&idPrenotazioneToUpdate=" + prenotazioneFormValues.idPrenotazioneToUpdate;
            }
            setLoading(true); nodeReq.get(process.env.REACT_APP_API_URL + "/sovrapposizioni?idArea=" + prenotazioneFormValues.idArea + "&giornoInizio=" + prenotazioneFormValues.dataOraInizio.substring(0, 10) + "&oraInizio=" + prenotazioneFormValues.dataOraInizio.substring(11, 16) + "&giornoFine=" + prenotazioneFormValues.dataOraFine.substring(0, 10) + "&oraFine=" + prenotazioneFormValues.dataOraFine.substring(11, 16) + idPrenotazioneToUpdateString)
                .then(response => {
                    if (response.status == 200) {
                        setSovrapposizioni(response.data.dbdata);
                    } else {
                        setSovrapposizioni(undefined);
                        genericAlert("Impossibile visualizzare le sovrapposizioni con altre prenotazioni a causa di un errore.");
                    }
                })
                .catch(error => {
                    setSovrapposizioni(undefined);
                    handleAPIError(error, "visualizzare le sovrapposizioni con altre prenotazioni");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    function orarioValido(orario) {
        return orario.length == 5 && orario[2] == ":" && numbers.includes(orario[0]) && numbers.includes(orario[1]) && numbers.includes(orario[3]) && numbers.includes(orario[4]);
    }

    //se il form è valido (compilato correttamente), segna formvalido a true
    useEffect(() => {
        if (process.env.REACT_APP_ABILITA_LOG_USE_EFFECT == 1) {
            console.log("useEffect: controllo validita form prenotazione");
        }
        let dataInizio = prenotazioneFormValues.dataOraInizio.substring(0, 10);
        let oraInizio = prenotazioneFormValues.dataOraInizio.substring(11, 16);
        let dataFine = prenotazioneFormValues.dataOraFine.substring(0, 10);
        let oraFine = prenotazioneFormValues.dataOraFine.substring(11, 16);
        let formValidoTemp = orarioValido(oraInizio) && orarioValido(oraFine) && dataInizio.localeCompare(dataFine) != 1 && !(dataInizio == dataFine && (oraInizio == oraFine || oraInizio.localeCompare(oraFine) == 1));
        setFormValido(formValidoTemp);
        if (formValidoTemp == true) {
            caricaSovrapposizioni();
        } else {
            setSovrapposizioni(null);
        }
        if (prenotazioneFormValues.idArea == "Altro") {
            setSovrapposizioni([]);
        }
    }, [prenotazioneFormValues.dataOraInizio, prenotazioneFormValues.dataOraFine, prenotazioneFormValues.idArea]);

    function nomeAreaDaId(id) {
        if (id == "Altro") {
            return "In un'altra area"
        } else {
            for (let i = 0; i < aree.length; i++) {
                if (aree[i].idArea == id) {
                    return aree[i].nome;
                }
            }
            return "";
        }
    }

    function dataFormatoAmericano(data) {
        return data.getFullYear() + "-" + (data.getMonth() + 1).toString().padStart(2, '0') + "-" + data.getDate().toString().padStart(2, '0');
    }


    function handleDatesChange(event) {
        if (Array.isArray(event) && event.length == 2) {
            setPrenotazioneFormValues(prevstate => ({
                ...prevstate,
                dataOraInizio: dataFormatoAmericano(event[0]) + prenotazioneFormValues.dataOraInizio.substring(10, 16),
                dataOraFine: dataFormatoAmericano(event[1]) + prenotazioneFormValues.dataOraFine.substring(10, 16)
            }));
        }
    }
    return (
        <div style={{ display: pageViewed === pages.Prenotazione || pageViewed === pages.NuovaPrenotazione ? 'block' : 'none' }}>
            {pageViewed === pages.NuovaPrenotazione &&
                <Heading title={"Nuova prenotazione generica"} iconName="calendar" />
            }
            {pageViewed === pages.Prenotazione &&
                <>
                    <Heading title={"Dashboard"} iconName="calendar" />
                    <h1 className="h3 mb-3"><b>Modifica prenotazione generica</b></h1>
                </>
            }
            <div className="row">
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Dati della prenotazione generica</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-group">
                                <label htmlFor="areaPrenotazione">Area *</label>
                                <select id="areaPrenotazione" className="form-control" name="idArea" value={prenotazioneFormValues.idArea} onChange={(event) => { setPrenotazioneFormValues(prevstate => ({ ...prevstate, idArea: event.target.value })); }}>
                                    {aree.map((area, index) => {
                                        return <option key={index} value={area.idArea}>{area.nome}</option>;
                                    })}
                                    <option value={"Altro"}>Altro</option>
                                </select>
                            </div>
                            <div className="form-group mb-2">
                                <label htmlFor="motivoPrenotazione">Motivo della prenotazione *</label>
                                <input type="text" id="motivoPrenotazione" className="form-control" value={prenotazioneFormValues.motivo} onChange={(event) => { setPrenotazioneFormValues(prevState => ({ ...prevState, motivo: event.target.value })); }} />
                            </div>
                            {(pageViewed === pages.NuovaPrenotazione || pageViewed === pages.Prenotazione) &&
                                <Flatpickr options={{ mode: "range", inline: true, locale: Italian, defaultDate: [prenotazioneFormValues.dataOraInizio.substring(0, 10), prenotazioneFormValues.dataOraFine.substring(0, 10)] }} value={[prenotazioneFormValues.dataOraInizio.substring(0, 10), prenotazioneFormValues.dataOraFine.substring(0, 10)]} onChange={handleDatesChange} />
                            }
                            <div className="form-group d-flex flex-row flex-wrap">
                                <div className="flex-grow-1">
                                    <label>Dalle ore {prenotazioneFormValues.dataOraInizio.substring(11, 16)} del {dataFormatoItaliano(prenotazioneFormValues.dataOraInizio.substring(0, 10))}*</label>
                                    <input className="form-control" id="oraInizioPrenotazione" value={prenotazioneFormValues.dataOraInizio.substring(11, 16)} onChange={(event) => { setPrenotazioneFormValues(prevState => ({ ...prevState, dataOraInizio: prenotazioneFormValues.dataOraInizio.substring(0, 11) + event.target.value })); }} type="time" />
                                </div>
                                <div className="m-2"></div>
                                <div className="flex-grow-1">
                                    <label htmlFor="oraFinePrenotazione">Alle ore {prenotazioneFormValues.dataOraFine.substring(11, 16)} del {dataFormatoItaliano(prenotazioneFormValues.dataOraFine.substring(0, 10))}*</label>
                                    <input className="form-control" id="oraFinePrenotazione" value={prenotazioneFormValues.dataOraFine.substring(11, 16)} onChange={(event) => { setPrenotazioneFormValues(prevState => ({ ...prevState, dataOraFine: prenotazioneFormValues.dataOraFine.substring(0, 11) + event.target.value })); }} type="time" />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap" }}>
                                <div>
                                    <p className="text-danger mb-0"></p>
                                </div>
                                <div>
                                    <p className="mb-0">* Campo obbligatorio</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Sovrapposizioni con altre prenotazioni</h5>
                        </div>
                        <div className="card-body">
                            <ModalSovrapposizioni sovrapposizioni={sovrapposizioni} showmodal={isShowSovrapposizioni} closemodal={() => { setShowSovrapposizioni(false); }} />
                            {(sovrapposizioni != undefined && sovrapposizioni != null && sovrapposizioni.length > 0) &&
                                <>
                                    <p className="text-danger">Sono state individuate delle sovrapposizioni</p>
                                    <button className="btn btn-secondary m-1" onClick={() => { setShowSovrapposizioni(true); }}>Vedi sovrapposizioni</button>
                                </>
                            }
                            {(sovrapposizioni != undefined && sovrapposizioni != null && sovrapposizioni.length == 0) && <p className="text-success">Non è stata individuata nessuna sovrapposizione.</p>}
                            {sovrapposizioni == undefined && sovrapposizioni != null && <p className="text-danger">Impossibile controllare le sovrapposizioni nelle prenotazioni.</p>}
                            {sovrapposizioni == null && <p className="text-danger">Compila correttamente i campi richiesti per controllare le sovrapposizioni.</p>}
                        </div>
                    </div>
                </div>
            </div>
            {/*Pulsante Salva*/}
            <div className="row">
                <div className="card">
                    <div className="card-body">
                        <button className="btn btn-primary m-1" onClick={() => { if (formValido == true) { setShowConfermaPrenotazione(true); } else { genericAlert(avviso); } }} disabled={formValido == false || sovrapposizioni == undefined || sovrapposizioni == null || sovrapposizioni.length > 0}>Salva</button>
                    </div>
                </div>
            </div>
            {/* props richieste: showmodal, closemodal, savePrenotazione, prenotazioneText*/}
            <ModalPrompt showmodal={isShowConfermaPrenotazione} closemodal={() => { setShowConfermaPrenotazione(false); }} okButtonFunction={savePrenotazione} okButtonText={"Salva"} okButtonColor={"primary"} title={"Conferma prenotazione generica"} nomeProcedura={"il salvataggio della prenotazione generica"}>
                <p>Vuoi salvare la seguente prenotazione generica?</p>
                <DettagliPrenotazione prenotazioneText={{ motivo: prenotazioneFormValues.motivo, dove: nomeAreaDaId(prenotazioneFormValues.idArea), periodo: testoPeriodoPrenotazione(prenotazioneFormValues.dataOraInizio, prenotazioneFormValues.dataOraFine) }} />
            </ModalPrompt>
        </div>
    );

}

export default PrenotazioneCreateUpdate;
import React, { useEffect, useState } from 'react';
import './ImpostazionePassword.css';
import { useContext } from 'react';
import { ContextGestionale } from '../App';
import APICallButton from '../components/APICallButton';
function ImpostazionePassword(props) {
  const { nodeReq, initialProfessionistaPazFormValues, nuovaSedutaFormValues, pages, pageViewed, setPageViewed, professionistaPazFormValues, setProfessionistaPazFormValues, loggedUser, setLoggedUser, seduteFormValues, setSeduteFormValues, handleAPIError, checkUnsavedBeforeDoing, genericAlert, cambiaUrl, goToMieInfo, dataFormatoItaliano, listaPazienti, setListaPazienti, listaProfessionisti, setListaProfessionisti, listaServizi, setListaServizi, checkUserLoggedBeforeDoing, seduteFormValuesBeforeEdits, setSeduteFormValuesBeforeEdits, listaSedute, setListaSedute, isProcessing, setLoading, setPasswordUnsaved, listaPazientiBeforeEdits, setListaPazientiBeforeEdits, listaProfessionistiBeforeEdits, setListaProfessionistiBeforeEdits, listaServiziBeforeEdits, setListaServiziBeforeEdits, professionistaPazFormValuesBeforeEdits, setProfessionistaPazFormValuesBeforeEdits, aree, prenotazioneFormValues, setPrenotazioneFormValues, prenotazioneFormValuesBeforeEdits, setPrenotazioneFormValuesBeforeEdits, importoConPuntoEVirgola, nomeProceduraInCorso, setNomeProceduraInCorso, openModifiedPaziente, openModifiedProfessionista  } = useContext(ContextGestionale);
  const [password, setPassword] = useState('');
  const [confermaPassword, setConfermaPassword] = useState('');
  const [error, setError] = useState("");
  const [isShowPasswords, setShowPasswords] = useState(false);

  function sendPasswordRequest() {
    if (password === confermaPassword && props.passwordFormatIsOk(password)) {
      let reqBody = { password: password, token: window.location.pathname.substring(8) };
      setLoading(true);nodeReq.post(process.env.REACT_APP_API_URL + "/setpasswordwithtoken", reqBody)
        .then(response => {
          if (response.status === 200) {
            genericAlert("Password impostata con successo");
            cambiaUrl("/dashboard");
            setPageViewed(pages.Login);
          } else {
            setError("Impossibile impostare la password. Riprova più tardi.");
          }
        })
        .catch(error => {
          if (error.response !== null && error.response.status === 401) {
            setError("Il token fornito non è valido oppure è scaduto.");
          } else {
            setError("Impossibile impostare la password. Riprova più tardi.");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (password !== confermaPassword) {
        setError("Inserire due password uguali");
      } else {
        setError("La password deve essere lunga almeno 12 caratteri e contenere almeno un numero, almeno una lettera maiuscola, almeno una lettera minuscola e almeno un carattere speciale tra questi:" + props.passwordSpecialChars);
      }
    }
  }

  //cancella l'errore di login all'apertura della pagina

  return (<div id="fullScreenLogin" style={{ display: pageViewed === pages.ImpostazionePassword ? 'block' : 'none' }}>
    <main id="loginContainer" className="d-flex w-100" >
      <div className="container d-flex flex-column">
        <div className="row vh-100">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
            <div className="d-table-cell align-middle">
              <div className="text-center mt-4">
                <h1 className="h2">Gestionale Esagramma</h1>
                <p className="lead">
                  Imposta la tua password
                </p>
              </div>
              <div className="card">
                <div className="card-body">
                  {Object.keys(loggedUser).length === 0 && <>
                    <div>
                      <img style={{ width: "100%", height: "auto" }} src="LOGO.jpg" />
                    </div>
                    <div className="m-sm-3">

                      <div className="mb-3">
                        <label className="form-label">Password</label>
                        <input className="form-control form-control-lg" type={isShowPasswords ? "text" : "password"} name="password" value={password} onChange={(event) => { setPassword(event.target.value); setError(""); }} />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Conferma password</label>
                        <input className="form-control form-control-lg" type={isShowPasswords ? "text" : "password"} name="confermaPassword" value={confermaPassword} onChange={(event) => { setConfermaPassword(event.target.value); setError(""); }} />
                      </div>
                      <div>
                        <label htmlFor="showPasswordsImpostazionePassword" className="card-subtitle text-muted">Mostra le password</label>
                        <input id="showPasswordsImpostazionePassword" type="checkbox" className="form-check-input m-1" checked={isShowPasswords} onChange={(event) => { setShowPasswords(event.target.checked); }} />
                      </div>
                      <p className="text-danger">{error}</p>
                      <div className="d-grid gap-2 mt-3">
                        <APICallButton buttonClassName="btn btn-lg btn-primary m-1" isSubmit={true} buttonOnClick={sendPasswordRequest} buttonText={"Invia"} nomeProcedura={"l'invio dell'email per impostare una nuova password"}/>
                      </div>

                    </div>
                  </>
                  }
                  {Object.keys(loggedUser).length > 0 && <>
                    <div>
                      <img style={{ width: "100%", height: "auto" }} src="LOGO.jpg" />
                    </div>
                    <h4>Esegui il logout prima di impostare la password per il tuo nuovo utente</h4>
                    <div className="d-grid gap-2 mt-3">
                      <APICallButton buttonClassName="btn btn-lg btn-primary m-1" buttonOnClick={() => { props.handleLogout(false, true); }} buttonText="Logout" nomeProcedura={"il logout"}/>
                    </div>
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>);
}

export default ImpostazionePassword;